import { ReactNode, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStatisticType } from "../../../../redux/indicatorsOrTopicsSlice";
import { selectIsStatisticsDataLoading } from "../../../../redux/statisticsDataSlice";
import { chooseSingleYear } from "../../../../redux/yearsSlice";
import { StatisticType } from "../../../../ts/interfaces";
import { getPreferredYearSpeed } from "../../../../utils/helpers";
import ns from "../../../../utils/namespace";
import PlayPauseButton from "../../../molecules/PlayPauseButton";
import YearPicker from "../../../molecules/YearPicker";
import Chart, { CHART_COMPONENT_NAME } from "./Chart";
import { useAllYears } from "./useAllYears";
import { useAvailableYears } from "./useAvailableYears";
import { useChosenSingleYear } from "./useChosenSingleYear";
import { useComparisonYear } from "./useComparisonYear";
import { useCompareGenerations } from "./useCompareGenerations";
import { useYearsPlayback } from "./useYearsPlayback";
import { useTopLowRegions } from "./useTopLowRegions";

interface Props {
  shallRenderAside?: boolean;
  shallRenderPicker?: boolean;
  title?: string;
  renderFooterContent?: (footerContent: ReactNode) => ReactNode;
}

export default function ChartWithYearPicker({
  shallRenderAside,
  shallRenderPicker = true,
  title,
  renderFooterContent = (footerContent) => footerContent
}: Props) {
  const dispatch = useDispatch();

  const availableYears = useAvailableYears();
  const allYears = useAllYears(availableYears);
  const { chosenSingleYear, setChosenSingleYear } = useChosenSingleYear();

  const isStatisticsDataLoading = useSelector(selectIsStatisticsDataLoading);
  const statisticType = useSelector(selectStatisticType)?.type;

  const { areYearsPlaying, wereYearsPlaying, handlePlayPauseClick } = useYearsPlayback(
    availableYears,
    chosenSingleYear,
    setChosenSingleYear,
    isStatisticsDataLoading,
    getPreferredYearSpeed(statisticType)
  );

  const { chosenYears, comparisonYearCheckbox } = useComparisonYear(chosenSingleYear);

  const compareGenerationsCheckbox = useCompareGenerations();

  const { topLowRegionsRadioGroup } = useTopLowRegions();

  const fetchAllYears = !wereYearsPlaying && areYearsPlaying ? true : false;
  const yearsToFetch = useMemo(() => (fetchAllYears ? allYears : chosenYears), [allYears, chosenYears, fetchAllYears]);
  const yearsToDisplay = useMemo(() => (chosenSingleYear ? [chosenSingleYear] : []), [chosenSingleYear]);

  return (
    <Chart
      yearsToDisplay={yearsToDisplay}
      yearsToFetch={yearsToFetch}
      allYears={allYears}
      availableYears={availableYears}
      title={title}
      shallRenderAside={shallRenderAside}
      footerContent={renderFooterContent(
        shallRenderPicker && chosenSingleYear ? (
          <>
            <div className={ns(`${CHART_COMPONENT_NAME}__checkboxes`)}>
              {comparisonYearCheckbox}
              {compareGenerationsCheckbox}
              {topLowRegionsRadioGroup}
            </div>
            <div className={ns(`${CHART_COMPONENT_NAME}__year-chooser`)}>
              <YearPicker
                availableYears={availableYears}
                currentYear={chosenSingleYear}
                setChosenSingleYear={(year) => dispatch(chooseSingleYear(year))}
              />
              {statisticType !== StatisticType.GeographicMigration && (
                <>
                  <span className={ns(`${CHART_COMPONENT_NAME}__footer-separator`)} />
                  <PlayPauseButton isPlaying={areYearsPlaying} handleClick={handlePlayPauseClick} />
                </>
              )}
            </div>
          </>
        ) : null
      )}
    />
  );
}
