import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectActiveRenderer, selectComparisonYear } from "../../../../redux/indicatorsOrTopicsSlice";
import { IFetchStatisticsDataPreArgs } from "../../../../redux/statisticsDataSlice";
import {
  selectIncludeComparisonYear,
  selectCompareGenerations,
  selectHighlightedElements
} from "../../../../redux/uiSlice";
import { IDiagramData, IStatisticsData } from "../../../../ts/interfaces";

export const useDiagramData = (
  statisticsData: IStatisticsData | null,
  statisticsDataArgs: IFetchStatisticsDataPreArgs | null,
  allYears: number[],
  availableYears: number[],
  years: number[]
): IDiagramData | undefined => {
  const renderer = useSelector(selectActiveRenderer);
  const comparisonYear = useSelector(selectComparisonYear);
  const includeComparisonYear = useSelector(selectIncludeComparisonYear);
  const compareGenerations = useSelector(selectCompareGenerations);
  const highlightedElements = useSelector(selectHighlightedElements);

  return useMemo(() => {
    if (
      (isEmpty(statisticsDataArgs?.indicators) && isEmpty(statisticsDataArgs?.topics)) ||
      isEmpty(statisticsDataArgs?.regions)
    ) {
      // If no indicators and no topics are chosen or if no region/region-filter is chosen
      // diagramData will be undefined resulting in placeholder image
      return undefined;
    }

    let statisticsDataForDiagramData = statisticsData;

    if (
      statisticsData &&
      statisticsDataArgs?.years &&
      isEqual(statisticsDataArgs.years, allYears) &&
      !isEqual(years, availableYears)
    ) {
      // statisticData fetched from server contains data for all years but only a subset of years are requested for diagramData => adjustment needed
      statisticsDataForDiagramData = {
        ...statisticsData,
        indicators: statisticsData.indicators.map((indicator) => {
          const relevantYearsInData = comparisonYear && includeComparisonYear ? years.concat(comparisonYear) : years;
          const yearDataIndices = relevantYearsInData.map((year) => statisticsDataArgs.years.indexOf(year));
          const regionYearValues = indicator.regionYearValues as any[];
          const adjustedRegionYearValues = regionYearValues.map((regionYearVaue) =>
            yearDataIndices.map((yearDataIndex) => regionYearVaue[yearDataIndex])
          );

          return {
            ...indicator,
            regionYearValues: adjustedRegionYearValues
          };
        })
      };
    }

    return {
      regions: statisticsDataArgs?.regions || [],
      renderer,
      years,
      indicator: first(statisticsDataArgs?.indicators) || null,
      topic: first(statisticsDataArgs?.topics) || null,
      compareGenerations,
      highlightedElements,
      data: statisticsDataForDiagramData
    };
  }, [
    allYears,
    availableYears,
    comparisonYear,
    includeComparisonYear,
    compareGenerations,
    renderer,
    statisticsData,
    highlightedElements,
    statisticsDataArgs?.indicators,
    statisticsDataArgs?.regions,
    statisticsDataArgs?.topics,
    statisticsDataArgs?.years,
    years
  ]);
};
