import React from "react";
import classNames from "classnames";
import ns from "../../utils/namespace";

interface Props {
  inputId: string;
  label: string;
  srOnly?: boolean;
  className?: string;
}
export default function FormLabel({ inputId, label, srOnly = false, className }: Props) {
  return (
    <label
      className={classNames(ns("form-label"), className)}
      htmlFor={inputId}
      {...(srOnly ? { "data-type": "sr-only" } : {})}
    >
      {label}
    </label>
  );
}
