import React, { ReactElement, ReactNode } from "react";
import ns from "../../utils/namespace";
import Icon from "../atoms/Icon";
import { useGetSvgPath } from "../../utils/svgPath";
import Dropdown from "./Dropdown";
import OrderedList from "../atoms/OrderedList";

interface Props {
  title: string;
  listClassName?: string;
  children: ReactNode;
}

export default function TagDropdown({ title, listClassName, children }: Props): ReactElement {
  const dropdownIcon = (
    <Icon className={ns("btn__icon")} width={10} height={8} fill="none">
      <use xlinkHref={useGetSvgPath("#angle-down")}></use>
    </Icon>
  );

  const expandedDropdownIcon = (
    <Icon className={ns("btn__icon")} width={10} height={8} fill="none">
      <use xlinkHref={useGetSvgPath("#close")}></use>
    </Icon>
  );

  return (
    <Dropdown
      toggleButton={{
        className: ns("btn--pill"),
        label: "…",
        title: title,
        icon: dropdownIcon,
        expandedIcon: expandedDropdownIcon
      }}
      className={ns("tagdropdown")}
    >
      {() => <OrderedList className={listClassName}>{children}</OrderedList>}
    </Dropdown>
  );
}
