import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectComparisonYear } from "../../../../redux/indicatorsOrTopicsSlice";

export const useAllYears = (availableYears: number[]) => {
  const comparisonYear = useSelector(selectComparisonYear);

  return useMemo(
    () =>
      comparisonYear && !availableYears.includes(comparisonYear)
        ? availableYears.concat(comparisonYear)
        : availableYears,
    [availableYears, comparisonYear]
  );
};
