import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import React, { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../redux/appConfigSlice";
import { chooseIndicatorOrTopic, unchooseIndicatorOrTopic } from "../../redux/indicatorsOrTopicsSlice";
import { ITopic } from "../../ts/interfaces";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import Button from "./button/Button";
import TopicInfoModal from "./TopicInfoModal";
interface Props {
  topic: ITopic;
  active?: boolean;
  combinable?: boolean;
}

export default function TopicTile({ topic, active = false, combinable = true }: Props): ReactElement {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const infoIIconPath = useGetSvgPath("#info-i");

  const [isInfoOverlayOpen, setIsInfoOverlayOpen] = useState(false);

  const handleClick = () => {
    if (active) {
      dispatch(unchooseIndicatorOrTopic(topic, appConfig.types));
    } else {
      dispatch(chooseIndicatorOrTopic(topic, appConfig.types));
    }
  };

  return (
    <div className={ns("topic-tile")}>
      <button
        className={classNames(ns("topic-tile__btn"), {
          [ns("topic-tile__btn--active")]: active
        })}
        {...(combinable ? { onClick: handleClick } : { disabled: true })}
      >
        <span className={ns("topic-tile__label")}>{topic.name}</span>
        <div className={ns("topic-tile__info")}>
          <Icon className={ns("topic-tile__state-icon")} width={19} height={19} fill="none">
            <use xlinkHref={useGetSvgPath("#disabled")}></use>
          </Icon>
          {!combinable && <span>Nicht kombinierbar mit aktueller Auswahl</span>}
        </div>
      </button>
      {!isEmpty(topic.indicators) && (
        <Button
          className={classNames(ns("topic-tile__info-btn"), {
            [ns("topic-tile__info-btn--active")]: isInfoOverlayOpen
          })}
          title="Enthaltene Indikatoren anzeigen"
          label={`${topic.indicators.length}`}
          labelSrExtension={` im Thema ${topic.name} enthaltene Indikatoren anzeigen`}
          iconBefore={
            <span className={ns("topic-tile__info-icon")}>
              <Icon width={10} height={10}>
                <use xlinkHref={infoIIconPath}></use>
              </Icon>
            </span>
          }
          handleClick={() => setIsInfoOverlayOpen(true)}
        />
      )}
      {isInfoOverlayOpen && (
        <TopicInfoModal closeModal={() => setIsInfoOverlayOpen(false)} isOpen={isInfoOverlayOpen} topic={topic} />
      )}
    </div>
  );
}
