import React, { ReactNode } from "react";
import classNames from "classnames";
import ns from "../../utils/namespace";

interface Props {
  className?: string;
  children: ReactNode;
  active: boolean;
}
export default function FormControl({ className, children, active }: Props) {
  return (
    <div
      className={classNames(ns("form-control"), className, {
        [ns("form-control--active")]: active
      })}
    >
      {children}
    </div>
  );
}
