import React, { ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import TagContainer from "./TagContainer";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import { IRegionOrIRegionFilter, ITagBase } from "../../../ts/interfaces";
import {
  chooseRegionOrRegionFilter,
  unchooseRegionOrRegionFilter,
  selectChosenRegionsOrRegionFilters,
  resetAllRegionsOrRegionFilters
} from "../../../redux/regionsOrRegionFiltersSlice";
import SelectAutocompletion from "../../molecules/SelectAutocompletion";
import isEmpty from "lodash/isEmpty";
import ns from "../../../utils/namespace";
import TagContainerInOverlay from "./TagContainerInOverlay";
import FiltersContainer from "../FiltersContainer";
import { buildExcludeQueryParamString, isRegionFilter } from "../../../utils/helpers";
import ModalButton from "../../molecules/button/ModalButton";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import { useOpenModalItem } from "./useOpenModalItem";
import { editRegionFilter, stopEditRegionFilter } from "../../../redux/filtersSlice";
import { selectActiveRegionOrRegionFilter, setActiveRegionOrRegionFilter } from "../../../redux/uiSlice";

interface Props {
  isInOverlay?: boolean;
}

export default function RegionContainer({ isInOverlay = false }: Props): ReactElement {
  const tags = useSelector(selectChosenRegionsOrRegionFilters);

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;
  const regions = useSelector(selectChosenRegions);
  const activeRegionOrRegionFilter = useSelector(selectActiveRegionOrRegionFilter);

  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const openModalItem = useOpenModalItem({
    which: "regions",
    label: "Weitere Kommunen auswählen ..."
  });
  const noOptionsOpenModalItem = useOpenModalItem({
    which: "regions",
    label: "Kommunen auswählen ..."
  });

  const handleChooseRegionOrRegionFilter = (region: ITagBase) => {
    dispatch(chooseRegionOrRegionFilter(region as IRegionOrIRegionFilter));
  };

  const handleUnchooseRegionOrRegionFilter = (region: ITagBase) => {
    dispatch(unchooseRegionOrRegionFilter(region as IRegionOrIRegionFilter));
  };

  const handleRegionOrRegionFilterClick = (regionOrRegionFilter: ITagBase) => {
    const isActive = isTagActive(regionOrRegionFilter);

    if (isRegionFilter(regionOrRegionFilter)) {
      if (isActive) {
        dispatch(stopEditRegionFilter());
      } else {
        dispatch(editRegionFilter(regionOrRegionFilter));
      }
    } else {
      if (isActive) {
        dispatch(setActiveRegionOrRegionFilter(null));
      } else {
        dispatch(setActiveRegionOrRegionFilter(regionOrRegionFilter as IRegionOrIRegionFilter));
      }
    }
  };

  const selectAutocompletion = (
    <SelectAutocompletion
      className={ns("tag-container__select")}
      optionsAPIUrl={appConfig.urls.apiRegionAutocompletion}
      optionsAPIUrlQueryParams={buildExcludeQueryParamString(regions)}
      placeholder={"Kommune hinzufügen"}
      noOptionsInfo="Keine Kommune gefunden, siehe diese <a href='/hilfe'>Hilfe</a>"
      inputInfo="Bitte geben Sie eine Kommune ein."
      shouldLoadDefaultOptions
      shouldShowInputInfo={!isInOverlay && isEmpty(tags)}
      shouldFocus={isEmpty(tags) && !isInOverlay}
      onChoose={handleChooseRegionOrRegionFilter}
      // Required props that will be added later in TagContainer/TagContainerInOverlay
      // Added dummy placeholders here to make TypeScript happy
      onFocus={() => {}}
      onBlur={() => {}}
    />
  );

  const isTagActive = (tag: ITagBase) => tag.id === activeRegionOrRegionFilter?.id;

  return isInOverlay ? (
    <>
      <TagContainerInOverlay
        tags={tags}
        resetLabel="Alle Kommunen löschen"
        handleUnchoose={handleUnchooseRegionOrRegionFilter}
        selectAutocompletion={selectAutocompletion}
        label="Kommune"
        handleUnchooseAll={() => dispatch(resetAllRegionsOrRegionFilters())}
        isTagActive={isTagActive}
        handleTagClick={handleRegionOrRegionFilterClick}
        getTagActionLabel={(tag) =>
          isRegionFilter(tag)
            ? isTagActive(tag)
              ? `${tag.title ?? tag.name} – Bearbeitung abbrechen`
              : `${tag.title ?? tag.name} bearbeiten`
            : `${tag.title ?? tag.name}`
        }
      />
      <FiltersContainer />
    </>
  ) : (
    <TagContainer
      label="Kommune"
      plural="Kommunen"
      tags={tags}
      selectAutocompletion={selectAutocompletion}
      moreItem={openModalItem}
      noOptionsMoreItem={noOptionsOpenModalItem}
      onUnchoose={handleUnchooseRegionOrRegionFilter}
      MoreComponent={(props) => <ModalButton {...props} which="regions" srOnly={false} label="Zur Kommunenauswahl" />}
    />
  );
}
