import React, { ReactElement, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import ns from "../../utils/namespace";
import Icon from "../atoms/Icon";
import { useGetSvgPath } from "../../utils/svgPath";
import Dropdown from "./Dropdown";
import { selectStatisticsRemark } from "../../redux/statisticsDataSlice";
import { selectMapRemark } from "../../redux/mapDataSlice";
import { selectActiveRenderer } from "../../redux/indicatorsOrTopicsSlice";
import { selectRemarkIsOpen, selectRemarkText, setRemarkIsOpen, setRemarkText } from "../../redux/uiSlice";

interface Props {
  className?: string;
}

const useGetRemark = (): string | undefined => {
  const mapRemark = useSelector(selectMapRemark);
  const statisticsRemark = useSelector(selectStatisticsRemark);
  const activeRenderer = useSelector(selectActiveRenderer);

  if (activeRenderer === "MAP") {
    return mapRemark;
  }
  return statisticsRemark;
};

export default function RemarkDropdown({ className }: Props): ReactElement | null {
  const dispatch = useDispatch();

  const remarkText = useGetRemark();
  const previousRemarkText = useSelector(selectRemarkText);
  const remarkIsOpen = useSelector(selectRemarkIsOpen);

  const dropdownIcon = (
    <Icon className={classNames(ns("btn__icon"), ns("remark-dropdown__icon"))} width={4} height={16}>
      <use xlinkHref={useGetSvgPath("#info-large")}></use>
    </Icon>
  );

  useEffect(() => {
    if (remarkText && remarkText !== previousRemarkText) {
      dispatch(setRemarkText(remarkText));
      dispatch(setRemarkIsOpen(true));
    }
  }, [dispatch, remarkText, previousRemarkText]);

  const handleOpen = useCallback(() => {
    dispatch(setRemarkIsOpen(true));
  }, [dispatch]);
  const handleClose = useCallback(() => {
    dispatch(setRemarkIsOpen(false));
  }, [dispatch]);

  if (remarkText) {
    return (
      <Dropdown
        toggleButton={{
          label: "",
          title: "Wichtiger Hinweis",
          icon: dropdownIcon,
          className: ns("btn--mira", "btn--icon", "remark-dropdown__button")
        }}
        panel={{
          className: ns("remark-dropdown__panel")
        }}
        className={classNames(ns("remark-dropdown"), className)}
        closeOnClickOutside={false}
        closeOnEsc={false}
        hasInnerClose={true}
        shouldExpand={remarkIsOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {() => <p className={ns("remark-dropdown__note")}>{remarkText}</p>}
      </Dropdown>
    );
  } else {
    return null;
  }
}
