import classNames from "classnames";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { selectStatisticType } from "../../redux/indicatorsOrTopicsSlice";
import { StatisticType } from "../../ts/interfaces";
import ns from "../../utils/namespace";

interface Props {
  className?: string;
}

export default function StatisticsDataLicense({ className }: Props): ReactElement | null {
  const statisticType = useSelector(selectStatisticType);

  if (
    statisticType &&
    [
      StatisticType.CommunalData,
      StatisticType.DemographicTypes,
      StatisticType.PopulationForecast,
      StatisticType.CareForecast,
      StatisticType.AgeStructure,
      StatisticType.AgeStructureForecast
    ].includes(statisticType.type)
  ) {
    return (
      <div className={classNames(ns("statistic-data-license"), className)}>
        <a href="https://www.govdata.de/dl-de/by-2-0">Lizenz: Datenlizenz Deutschland - Namensnennung - Version 2.0</a>
      </div>
    );
  } else {
    return null;
  }
}
