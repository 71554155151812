import React, { ReactElement } from "react";
import { FeatureCollection, Geometry } from "geojson";
import isEmpty from "lodash/isEmpty";
import { IFeature, IFeatureProperties } from "../../../ts/interfaces";
import GeoJsonWithUpdates from "./GeoJsonWithUpdates";
import { PathOptions, StyleFunction } from "leaflet";
interface Props {
  features: Array<IFeature>;
  pathOptions?: PathOptions;
  style?: PathOptions | StyleFunction<any>;
}

export default function FeaturesLayer({ features, pathOptions, style }: Props): ReactElement | null {
  if (isEmpty(features)) {
    return null;
  }
  const featuresGeoJson: FeatureCollection<Geometry, IFeatureProperties> = {
    type: "FeatureCollection",
    features: features
  };

  return <GeoJsonWithUpdates data={featuresGeoJson} interactive={false} pathOptions={pathOptions} style={style} />;
}
