import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITopic } from "../ts/interfaces";
import { RootState } from "./rootReducer";

interface ITopicSetsState {
  loading: boolean;
  hasErrors: boolean;
  topicSets: Array<{ name: string; topics: Array<ITopic> }>;
}

const initialState: ITopicSetsState = {
  loading: false,
  hasErrors: false,
  topicSets: []
};

export const fetchTopicSets = createAsyncThunk("topics/fetchTopics", async (apiUrl: string) => {
  const response = await fetch(apiUrl);
  const topicSets = await response.json();
  return topicSets;
});

// A slice for topicSets with our three reducers
const topicSetsSlice = createSlice({
  name: "topicSets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTopicSets.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTopicSets.fulfilled, (state, action) => {
      state.topicSets = action.payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchTopicSets.rejected, (state, action) => {
      state.loading = false;
      state.hasErrors = true;
    });
  }
});

export const selectTopicSets = (state: RootState) => state.topicSets;

export default topicSetsSlice.reducer;
