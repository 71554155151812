import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import { isFullApp, isMiniApp } from "../../../../utils/appSetup";
import ns from "../../../../utils/namespace";
import DownloadDropdown from "../../../molecules/DownloadDropdown";
import IndicatorOrTopicList from "../IndicatorOrTopicList";
import Loader from "../../../molecules/Loader";
import RemarkDropdown from "../../../molecules/RemarkDropdown";
import StatisticsDataLicense from "../../../molecules/StatisticsDataLicense";
import { ReactNode } from "react";
import ChartContent from "./ChartContent";
import { useGetStatisticsData } from "./useGetStatisticsData";
import { useDiagramData } from "./useDiagramData";
import { useChartTitle } from "./useChartTitle";
import { selectIsDemographicTypes, selectStatisticType } from "../../../../redux/indicatorsOrTopicsSlice";
import { StatisticType } from "../../../../ts/interfaces";

export const CHART_COMPONENT_NAME = "chart";

interface Props {
  yearsToDisplay: number[];
  availableYears: number[];
  allYears: number[];
  yearsToFetch?: number[];
  shallRenderAside?: boolean;
  title?: string | null;
  footerContent?: ReactNode;
}

export default function Chart({
  yearsToDisplay,
  availableYears,
  allYears,
  yearsToFetch,
  shallRenderAside = true,
  title,
  footerContent
}: Props) {
  yearsToFetch = yearsToFetch || yearsToDisplay;

  const appConfig = useSelector(selectAppConfig);
  const statisticType = useSelector(selectStatisticType);
  const isDemographicTypes = useSelector(selectIsDemographicTypes);
  const isInFullApp = isFullApp(appConfig);

  const { statisticsData, statisticsDataArgs, isLoading, hasErrors } = useGetStatisticsData(allYears, yearsToFetch);

  const diagramData = useDiagramData(statisticsData, statisticsDataArgs, allYears, availableYears, yearsToDisplay);

  const chartTitle = useChartTitle(title);
  const chartHasData = diagramData && !isEmpty(diagramData.data);

  return (
    <div
      className={classNames(ns(CHART_COMPONENT_NAME), {
        [ns(`${CHART_COMPONENT_NAME}--mini-app`)]: isMiniApp(appConfig),
        [ns(`${CHART_COMPONENT_NAME}--full-app`)]: isInFullApp,
        [ns(`${CHART_COMPONENT_NAME}--demographic-types`)]: isDemographicTypes,
        [ns(`${CHART_COMPONENT_NAME}--geographic-migration`)]: statisticType?.type === StatisticType.GeographicMigration
      })}
    >
      <div className={ns("row")}>
        {isInFullApp && shallRenderAside && (
          <div className={ns("col chart__aside")}>
            <IndicatorOrTopicList />
          </div>
        )}
        <div className={ns("col chart__main")}>
          {isInFullApp && chartHasData && (
            <div className={ns("remark-download-panel")}>
              <RemarkDropdown className={classNames(ns("remark-download-panel__remark"))} />

              <DownloadDropdown className={classNames(ns("remark-download-panel__download"))} title="Grafik" />
            </div>
          )}
          <div className={ns("chart__loading-area")}>
            <ChartContent
              statisticsData={statisticsData}
              diagramData={diagramData}
              hasErrors={hasErrors}
              title={chartTitle}
            />
            {isLoading && <Loader isCentered />}
          </div>
          {footerContent && chartHasData && <div className={ns("chart__footer")}>{footerContent}</div>}
          {!isEmpty(statisticsData?.source) && (
            <div className={ns("chart__content-sources")}>
              Quelle: {statisticsData?.source} - <a href="/dois">DOIs</a>
            </div>
          )}
          <StatisticsDataLicense className={ns("chart__content-sources")} />
        </div>
      </div>
    </div>
  );
}
