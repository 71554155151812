import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../redux/appConfigSlice";
import ns from "../../utils/namespace";
import isEmpty from "lodash/isEmpty";
import FilterContainer from "./tag-container/FilterContainer";
import {
  fetchRegionFilter,
  resetAllFilters,
  selectFiltersState,
  unsetRegionFilter,
  stopEditRegionFilter
} from "../../redux/filtersSlice";
import { buildExcludeQueryParamString } from "../../utils/helpers";
import Button from "../molecules/button/Button";
import Icon from "../atoms/Icon";
import { useGetSvgPath } from "../../utils/svgPath";

export default function FiltersContainer(): ReactElement {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const {
    regionTypes,
    parents,
    populations,
    demographicTypes,
    gkzs,
    regionFilter,
    regionFilterInEditing
  } = useSelector(selectFiltersState);

  const apiUrl = appConfig.urls.apiRegionFilter;

  useEffect(() => {
    if (apiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch RegionFilter, apiUrl is: ", apiUrl);
      return;
    }

    if (
      isEmpty(regionTypes) &&
      isEmpty(parents) &&
      isEmpty(populations) &&
      isEmpty(demographicTypes) &&
      isEmpty(gkzs)
    ) {
      dispatch(unsetRegionFilter());
    } else {
      const regionTypeIds = regionTypes.map((r) => r.id);
      const parentIds = parents.map((p) => p.id);
      const populationIds = populations.map((p) => p.id);
      const demographicTypeIds = demographicTypes.map((d) => d.id);
      const gkzIds = gkzs.map((g) => g.id);

      dispatch(
        fetchRegionFilter({
          apiUrl,
          regionTypeIds,
          parentIds,
          populationIds,
          demographicTypeIds,
          gkzIds
        })
      );
    }
  }, [dispatch, apiUrl, regionTypes, parents, populations, demographicTypes, gkzs]);

  const regionsAddableCount = regionFilter === null || isEmpty(regionFilter.regions) ? 0 : regionFilter.regions.length;
  const addLabel = `${regionsAddableCount} Kommune${regionsAddableCount !== 1 ? "n" : ""}`;

  return (
    <div className={ns("filters-container")}>
      <div className={ns("filters-container__heading")}>
        <h5 className={ns("filters-container__label")}>Filter</h5>
        <span className={ns("filters-container__addlabel")}>({addLabel})</span>
        <Button
          label="zurücksetzen"
          className={ns("btn--reset filters-container__reset")}
          handleClick={() => {
            dispatch(resetAllFilters());
            if (regionFilterInEditing) {
              dispatch(stopEditRegionFilter());
            }
          }}
          disabled={
            isEmpty(regionTypes) &&
            isEmpty(parents) &&
            isEmpty(populations) &&
            isEmpty(demographicTypes) &&
            isEmpty(gkzs)
          }
          iconBefore={
            <Icon className={ns("filters-container__reset-icon")} width={12} height={12}>
              <use xlinkHref={useGetSvgPath("#reset")}></use>
            </Icon>
          }
        />
      </div>
      <FilterContainer
        label="Verwaltungstyp"
        plural="Verwaltungstypen"
        filterName="regionTypes"
        optionsAPIUrl={appConfig.urls.apiRegionTypeAutocompletion}
        placeholder={"Verwaltungstyp"}
        noOptionsInfo={"Kein Verwaltungstyp gefunden"}
        className={ns("filters-container__filter")}
      />
      <FilterContainer
        label="Bundesland/Landkreis"
        plural="Bundesländer/Landkreise"
        filterName="parents"
        optionsAPIUrl={appConfig.urls.apiRegionAutocompletion}
        optionsAPIUrlQueryParams={`&types=LANDKREIS&types=BUNDESLAND`}
        placeholder={"Bundesland/Landkreis"}
        noOptionsInfo={"Kein Bundesland/Landkreis gefunden"}
        className={ns("filters-container__filter")}
      />
      <FilterContainer
        label="Einwohnerzahl/Größenklasse"
        plural="Einwohnerzahlen/Größenklassen"
        filterName="populations"
        optionsAPIUrl={appConfig.urls.apiPopulationRangeAutocompletion}
        placeholder={"Einwohnerzahl/Größenklasse"}
        noOptionsInfo={"Keine Einwohnerzahl/Größenklasse gefunden"}
        className={ns("filters-container__filter")}
      />
      <FilterContainer
        label="Demografietyp"
        plural="Demografietypen"
        filterName="demographicTypes"
        optionsAPIUrl={appConfig.urls.apiDemographicTypeAutocompletion}
        // 10 is default, but there are 11 Demografietypen: So show them all
        optionsAPIUrlQueryParams={`&max=11${buildExcludeQueryParamString(parents)}`}
        placeholder={"Demografietyp"}
        noOptionsInfo={"Kein Demografietyp gefunden"}
        className={ns("filters-container__filter")}
      />
      <FilterContainer
        label="GKZ / ARS"
        plural="Regionalschlüssel"
        filterName="gkzs"
        optionsAPIUrl={appConfig.urls.apiGkzAutocompletion}
        placeholder={"GKZ / ARS"}
        noOptionsInfo={"Keine Kommune gefunden"}
        className={ns("filters-container__filter")}
      />
    </div>
  );
}
