import React, { ReactElement } from "react";
import ns from "../../../utils/namespace";
import Icon from "../../atoms/Icon";
import Button from "./Button";
import { useGetSvgPath } from "../../../utils/svgPath";
import { IIndicator } from "../../../ts/interfaces";
interface Props {
  tagObj: IIndicator;
  handleUnchoose: (tagName: IIndicator) => void;
}

export default function RemoveIndicatorButton({ tagObj, handleUnchoose }: Props): ReactElement {
  const handleUnchooseClick = () => {
    handleUnchoose(tagObj);
  };

  return (
    <Button
      handleClick={handleUnchooseClick}
      className={ns("btn--dara btn--icon")}
      label="Indikator entfernen"
      srOnly
      icon={
        <Icon className={ns("btn__icon")} top={4} width={18} height={11}>
          <use xlinkHref={useGetSvgPath("#minus")}></use>
        </Icon>
      }
    />
  );
}
