import classNames from "classnames";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRenderer,
  setActiveIndicator,
  selectIsDemographicTypes
} from "../../../redux/indicatorsOrTopicsSlice";
import { IIndicator, IIndicatorOrTopic, IRenderer } from "../../../ts/interfaces";
import ns from "../../../utils/namespace";
import { useGetSvgPath } from "../../../utils/svgPath";
import Icon from "../../atoms/Icon";
import Button from "./Button";

interface Props {
  indicator: IIndicatorOrTopic;
  label: string;
  iconId: string;
  rendererType: IRenderer;
  width: number;
  height: number;
  className?: string;
  saveScrollPosition?: () => void;
}

export default function RendererButton({
  indicator,
  label,
  iconId,
  rendererType,
  width,
  height,
  className,
  saveScrollPosition
}: Props): ReactElement {
  const dispatch = useDispatch();
  const isDemographicTypes = useSelector(selectIsDemographicTypes);

  const handleClick = () => {
    if (saveScrollPosition) {
      saveScrollPosition();
    }

    if (!isDemographicTypes) {
      dispatch(setActiveIndicator(indicator as IIndicator));
    }
    dispatch(setActiveRenderer(rendererType));
  };

  return (
    <Button
      label={label}
      srOnly
      handleClick={handleClick}
      className={ns("indicator-or-topic-cell__renderer-btn")}
      icon={
        <Icon
          className={classNames(ns("indicator-or-topic-cell__renderer-icon"), className)}
          width={width}
          height={height}
        >
          <use xlinkHref={useGetSvgPath(iconId)}></use>
        </Icon>
      }
    />
  );
}
