import React, { ReactElement, ReactNode, useMemo, useRef, useState } from "react";
import useDimensions from "react-cool-dimensions";
import uniqueId from "lodash/uniqueId";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import ns from "../../../utils/namespace";
import TagDropdown from "../../molecules/TagDropdown";
import TagList from "../../molecules/TagList";
import FormLabel from "../../atoms/FormLabel";
import FormControl from "../../atoms/FormControl";
import { ITagBase } from "../../../ts/interfaces";

interface IMoreItem {
  content: ReactNode;
  onSelect: () => void;
}
interface Props {
  label: string;
  isLabelSrOnly?: boolean;
  plural: string;
  tags: Array<ITagBase>;
  selectAutocompletion: ReactElement;
  className?: string;
  moreItem?: IMoreItem;
  noOptionsMoreItem?: IMoreItem;
  onUnchoose: (tag: ITagBase) => void;
  MoreComponent?: (props: { className: string }) => ReactElement | null;
}

interface ISplitUpReturn {
  visibleTags: Array<ITagBase>;
  inDropdownTags: Array<ITagBase>;
}

const tagContainerBreakpoints = { XS: 0, SM: 297, MD: 417, LG: 641 };

function splitUpTags(tags: Array<ITagBase>, width: number): ISplitUpReturn {
  let splitUpAfter = 0;
  if (width < tagContainerBreakpoints.SM) {
    splitUpAfter = 0;
  } else if (width < tagContainerBreakpoints.MD) {
    splitUpAfter = 1;
  } else if (width < tagContainerBreakpoints.LG) {
    splitUpAfter = tags.length === 2 ? 2 : 1;
  } else {
    splitUpAfter = tags.length === 3 ? 3 : 2;
  }
  const visibleTags = tags.slice(0, splitUpAfter);
  const inDropdownTags = tags.slice(splitUpAfter);
  return {
    visibleTags: visibleTags,
    inDropdownTags: inDropdownTags
  };
}

function parseMoreItem(moreItem: IMoreItem) {
  return {
    value: "add-more",
    formatLabel: () => moreItem.content,
    onSelect: moreItem.onSelect
  };
}

export default function TagContainer({
  label,
  isLabelSrOnly,
  plural,
  tags,
  selectAutocompletion,
  className,
  moreItem,
  noOptionsMoreItem,
  onUnchoose: handleUnchoose,
  MoreComponent = () => null
}: Props): ReactElement {
  const inputId = useRef(`${ns("search-input")}-${uniqueId()}`);

  const { observe, width } = useDimensions({
    breakpoints: tagContainerBreakpoints,
    // Will only update the state on breakpoint changed, default is false
    updateOnBreakpointChange: true
  });

  const [inputIsFocused, setInputIsFocused] = useState(false);

  const handleInputFocus = () => {
    setInputIsFocused(true);
  };

  const handleInputBlur = () => {
    setInputIsFocused(false);
  };

  const { visibleTags, inDropdownTags } = splitUpTags(tags, width);

  const dummyOptions = useMemo(() => (moreItem ? [parseMoreItem(moreItem)] : undefined), [moreItem]);
  const noOptionsDummyOptions = useMemo(() => (noOptionsMoreItem ? [parseMoreItem(noOptionsMoreItem)] : undefined), [
    noOptionsMoreItem
  ]);

  return (
    <div className={classNames(ns("tag-container tag-container--with-more"), className)} ref={observe}>
      <FormLabel
        inputId={inputId.current}
        label={label}
        srOnly={isLabelSrOnly}
        className={ns("tag-container__label")}
      />
      <MoreComponent className={ns("tag-container__more")} />
      <FormControl className={ns("tag-container__field")} active={inputIsFocused}>
        <ol className={ns("tag-container__tag-list tag-container__tag-list--horizontal")}>
          <TagList tags={visibleTags} handleUnchoose={handleUnchoose} className={ns("tag-container__tag-list-item")} />
          {!isEmpty(inDropdownTags) && (
            <li className={ns("tag-container__tag-list-item")}>
              <TagDropdown
                title={`${inDropdownTags.length} ${inDropdownTags.length === 1 ? label : plural}`}
                listClassName={ns("tag-container__tag-list tag-container__tag-list--dropdown")}
              >
                <TagList
                  tags={inDropdownTags}
                  handleUnchoose={handleUnchoose}
                  className={ns("tag-container__tag-list-item")}
                />
              </TagDropdown>
            </li>
          )}
        </ol>
        <div className={ns("tag-container__control-group")}>
          {React.cloneElement(selectAutocompletion, {
            dummyOptions: dummyOptions,
            noOptionsDummyOptions: noOptionsDummyOptions,
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
            inputId: inputId.current
          })}
        </div>
      </FormControl>
    </div>
  );
}
