import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { latLng, latLngBounds, LatLngBounds, Map } from "leaflet";

import { selectAreIndicatorsAvailable, selectHasStatisticType } from "../../../redux/indicatorsOrTopicsSlice";
import { selectMapPickerBbox, fetchMapPickerBbox } from "../../../redux/mapPickerBboxSlice";
import { fetchMapPickerData, selectMapPickerData } from "../../../redux/mapPickerDataSlice";
import { selectActiveLayer, selectActiveRegionOrRegionFilter } from "../../../redux/uiSlice";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import { usePrevious } from "../../../utils/usePrevious";
import isArray from "lodash/isArray";
import { getRegionIds } from "../../../utils/helpers";
import isEmpty from "lodash/isEmpty";

const getLatLngBounds = (bbox: Array<number> | null): LatLngBounds | null => {
  if (isArray(bbox) && bbox.length === 4) {
    const northEast = latLng(bbox[3], bbox[2]);
    const southWest = latLng(bbox[1], bbox[0]);
    const bounds = latLngBounds(southWest, northEast);
    return bounds;
  }
  return null;
};

export const useGetMapPickerData = (map: Map | null, isMapCreated: boolean) => {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;

  // we use ref for storing this value, to let fetchMapPickerBbox effect below be executed independently of this value
  const isMapCreatedRef = useRef(isMapCreated);
  isMapCreatedRef.current = isMapCreated;

  const wasMapCreated = usePrevious(isMapCreated);
  const regions = useSelector(selectChosenRegions);
  const activeLayer = useSelector(selectActiveLayer);
  const activeRegionOrRegionFilter = useSelector(selectActiveRegionOrRegionFilter);
  const previousActiveRegionOrRegionFilter = usePrevious(activeRegionOrRegionFilter);
  const newActiveRegionOrRegionFilter =
    activeRegionOrRegionFilter !== previousActiveRegionOrRegionFilter ? activeRegionOrRegionFilter : null;
  const hasStatisticType = useSelector(selectHasStatisticType);
  const areIndicatorsAvailable = useSelector(selectAreIndicatorsAvailable);

  const { mapPickerBbox, loading: bboxLoading, hasErrors: bboxHasErrors, leafletBbox } = useSelector(
    selectMapPickerBbox
  );

  const bounds = React.useMemo(() => getLatLngBounds(mapPickerBbox), [mapPickerBbox]);

  const { mapPickerData, loading: mapPickerDataLoading, hasErrors: mapPickerDataHasErrors } = useSelector(
    selectMapPickerData
  );

  // 1st Get bbox for chosen regions
  const bboxApiUrl = appConfig.urls.apiMapBbox;
  const regionIds = React.useMemo(() => regions.map((c) => c.id), [regions]);
  const previousRegionIds = usePrevious(regionIds);
  const newRegionIds = previousRegionIds
    ? regionIds !== previousRegionIds
      ? regionIds.filter((x) => !previousRegionIds.includes(x))
      : undefined
    : regionIds;

  useEffect(() => {
    if (bboxApiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch bbox data, bboxApiUrl is: ", bboxApiUrl);
      return;
    }

    if (newActiveRegionOrRegionFilter || (newRegionIds && !isEmpty(newRegionIds))) {
      dispatch(
        fetchMapPickerBbox({
          apiUrl: bboxApiUrl,
          regionIds: newActiveRegionOrRegionFilter ? getRegionIds(newActiveRegionOrRegionFilter) : newRegionIds!,
          // minimal is only false when initializing map
          minimal: isMapCreatedRef.current
        })
      );
    }
  }, [dispatch, bboxApiUrl, newRegionIds, newActiveRegionOrRegionFilter]);

  // 2nd move leaflet map to this bbox
  useEffect(() => {
    if (isMapCreated && map && bounds) {
      if (!wasMapCreated) {
        map.fitBounds(bounds);
      } else if (!map.getBounds().contains(bounds)) {
        // extend current bounds to include new bounds
        map.fitBounds(map.getBounds().extend(bounds));
      }
    }
    // The map param is not passed in the dependency list, because the `map` object changes every time something with the map changes
    // But we still need to check the map to make sure it has been created (not null), when the bounds are available
    // For this we use `isMapCreated`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapCreated, bounds, wasMapCreated]);

  // 3rd Whenever map moves or zooms, then fetch geojson for new leaflet bounds/bbox
  const mapDataApiUrl = appConfig.urls.apiMapData;

  useEffect(() => {
    if (mapDataApiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch map data, mapDataApiUrl is: ", mapDataApiUrl);
      return;
    }
    if (!leafletBbox) {
      return;
    }
    const bbox = leafletBbox.join(",");

    const fetchData = {
      apiUrl: mapDataApiUrl,
      bbox,
      regionIds: regionIds,
      layer: activeLayer
    };

    dispatch(fetchMapPickerData(fetchData));
  }, [activeLayer, areIndicatorsAvailable, dispatch, hasStatisticType, leafletBbox, mapDataApiUrl, regionIds]);

  return {
    mapPickerBbox,
    bboxLoading,
    bboxHasErrors,
    mapPickerData,
    mapPickerDataLoading,
    mapPickerDataHasErrors
  };
};
