import React, { ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import TagContainer from "./TagContainer";
import { ITagBase, IIndicatorOrTopic } from "../../../ts/interfaces";
import {
  chooseIndicatorOrTopic,
  unchooseIndicatorOrTopic,
  selectChosenIndicatorsOrTopics,
  unchooseAllIndicatorsOrTopics
} from "../../../redux/indicatorsOrTopicsSlice";
import isEmpty from "lodash/isEmpty";
import SelectAutocompletion from "../../molecules/SelectAutocompletion";
import ns from "../../../utils/namespace";
import TagContainerInOverlay from "./TagContainerInOverlay";
import { getCurrentlyChosenType, isTopic } from "../../../utils/helpers";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import ModalButton from "../../molecules/button/ModalButton";
import { useOpenModalItem } from "./useOpenModalItem";
import { selectModalIsOpen } from "../../../redux/uiSlice";

interface Props {
  isInOverlay?: boolean;
}

export default function IndicatorOrTopicContainer({ isInOverlay = false }: Props): ReactElement {
  const tags = useSelector(selectChosenIndicatorsOrTopics);
  const modalIsOpen = useSelector(selectModalIsOpen);
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const openModalItem = useOpenModalItem({
    which: "indicatorsOrTopics",
    label: "Weitere Indikatoren / Themen auswählen ..."
  });
  const noOptionsOpenModalItem = useOpenModalItem({
    which: "indicatorsOrTopics",
    label: "Indikatoren / Themen auswählen ..."
  });

  const handleChooseIndicatorOrTopic = (indicatorOrTopic: ITagBase) => {
    dispatch(chooseIndicatorOrTopic(indicatorOrTopic as IIndicatorOrTopic, appConfig.types));
  };

  const handleUnchooseIndicatorOrTopic = (indicatorOrTopic: ITagBase) => {
    dispatch(unchooseIndicatorOrTopic(indicatorOrTopic as IIndicatorOrTopic, appConfig.types));
  };

  function buildQueryParams(): string {
    if (isEmpty(tags)) {
      return "";
    } else {
      return `&${tags
        .map((tag) => {
          if (isTopic(tag)) {
            return `excludeTopic=${encodeURIComponent(tag.id)}`;
          } else {
            return `excludeIndicator=${encodeURIComponent(tag.id)}`;
          }
        })
        .join("&")}`;
    }
  }

  const selectAutocompletion = (
    <SelectAutocompletion
      className={ns("tag-container__select")}
      optionsAPIUrl={appConfig.urls.apiIndicatorOrTopicAutocompletion}
      optionsAPIUrlQueryParams={buildQueryParams()}
      placeholder={"Indikator oder Thema hinzufügen"}
      noOptionsInfo="Kein Thema gefunden, siehe diese <a href='/hilfe'>Hilfe</a>"
      inputInfo="Bitte geben Sie einen Indikator oder ein Thema ein."
      shouldLoadDefaultOptions
      shouldShowInputInfo={!isInOverlay && isEmpty(tags)}
      shouldFocus={isEmpty(tags) && !isInOverlay && !modalIsOpen}
      onChoose={handleChooseIndicatorOrTopic}
      isOptionDisabled={(option: ITagBase) => {
        const currentlyChosenType = getCurrentlyChosenType(tags);
        const optionType = (option as IIndicatorOrTopic).type;
        return (
          // only disabled if there is a currentlyChosenType value,
          // but this empty e.g. when all indicators have been removed
          !isEmpty(currentlyChosenType) &&
          // and also only disabled if optionType not same as currently active one
          optionType !== currentlyChosenType
        );
      }}
      // Required props that will be added later in TagContainer/TagContainerInOverlay
      // Added dummy placeholders here to make TypeScript happy
      onFocus={() => {}}
      onBlur={() => {}}
    />
  );

  return isInOverlay ? (
    <TagContainerInOverlay
      tags={tags}
      handleUnchoose={handleUnchooseIndicatorOrTopic}
      selectAutocompletion={selectAutocompletion}
      label="Indikator / Thema"
      resetLabel="Alle Indikatoren / Themen löschen"
      handleUnchooseAll={() => dispatch(unchooseAllIndicatorsOrTopics())}
    />
  ) : (
    <TagContainer
      label="Indikator / Thema"
      plural="Themen"
      tags={tags}
      selectAutocompletion={selectAutocompletion}
      moreItem={openModalItem}
      noOptionsMoreItem={noOptionsOpenModalItem}
      onUnchoose={handleUnchooseIndicatorOrTopic}
      MoreComponent={(props) => (
        <ModalButton {...props} which="indicatorsOrTopics" srOnly={false} label="Zur Themenübersicht" />
      )}
    />
  );
}
