import { PathOptions } from "leaflet";
import { useCallback } from "react";
import { IDataFeature, IMapPickerData } from "../../../ts/interfaces";
import { getPickerRegionStyle } from "./getPickerRegionStyle";

export const usePickerRegionStyleCallback = (
  mapPickerData: IMapPickerData | null,
  currentZoom?: number,
  className?: string
) => {
  return useCallback(
    (feature: IDataFeature | undefined): PathOptions =>
      getPickerRegionStyle(feature, mapPickerData, currentZoom, className),
    [className, currentZoom, mapPickerData]
  );
};
