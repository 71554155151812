import React, { ReactElement } from "react";
import ns from "../../../utils/namespace";
import RegionContainer from "../tag-container/RegionContainer";
import IndicatorOrTopicContainer from "../tag-container/IndicatorOrTopicContainer";

export default function SelectorArea(): ReactElement {
  return (
    <div className={ns("ribbon ribbon--selector-aria")}>
      <div className={ns("row")}>
        <div className={ns("col col-12 col-lg-6")}>
          <IndicatorOrTopicContainer />
        </div>
        <div className={ns("col col-12 col-lg-6")}>
          <RegionContainer />
        </div>
      </div>
    </div>
  );
}
