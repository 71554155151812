import React, { ReactElement, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "react-leaflet";
import { geoJSON } from "leaflet";
import { FeatureCollection } from "geojson";
import isNumber from "lodash/isNumber";
import classNames from "classnames";

import { IFeature, IRegionOrIRegionFilter, ITagBase } from "../../../ts/interfaces";
import Tag from "../Tag";
import { unchooseRegionOrRegionFilter } from "../../../redux/regionsOrRegionFiltersSlice";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import { reduceFeature } from "../../../utils/map";
import { selectActiveRegionOrRegionFilter, setActiveRegionOrRegionFilter } from "../../../redux/uiSlice";
import { isRegionFilter } from "../../../utils/helpers";
import { editRegionFilter, stopEditRegionFilter } from "../../../redux/filtersSlice";
import ns from "../../../utils/namespace";

interface Props {
  chosenFeatures: Array<IFeature>;
  exclude?: IFeature;
  areClickable?: boolean;
}

export default function RegionMarkers({ chosenFeatures, exclude, areClickable = false }: Props): ReactElement | null {
  const { selectChosenRegionsAndRegionFilters } = useMemoizedSelectors().regionsOrRegionFilters;

  const dispatch = useDispatch();
  const [chosenRegionFilters, chosenRegions] = useSelector(selectChosenRegionsAndRegionFilters);
  const activeRegionOrRegionFilter = useSelector(selectActiveRegionOrRegionFilter);

  const regionMarkersBlueprints = useMemo(() => {
    const result: Array<{
      features: Array<IFeature>;
      tagObj: IRegionOrIRegionFilter;
    }> = [];
    const regionFilterBlueprintIndexMapping: {
      [Key: string]: number;
    } = {};
    chosenFeatures.forEach((feature) => {
      if (feature.id === exclude?.id) {
        // early exit, feature shall not get a marker
        return;
      }
      // we reduce the feature such that getBounds().getCenter()
      // down below returns the center of the first contained
      // polygon and not of all contained polygons
      const reducedFeature = reduceFeature(feature);
      const relatedRegion = chosenRegions.find((region) => region.id === feature.id);
      if (relatedRegion) {
        // feature belongs to region which was directly chosen
        result.push({
          features: [reducedFeature],
          tagObj: relatedRegion
        });
      }
      const regionFiltersContainingFeature = chosenRegionFilters.filter((regionFilter) =>
        regionFilter.regions.some((region) => region.id === feature.id)
      );
      regionFiltersContainingFeature.forEach((regionFilter) => {
        const existingBlueprintIndex = regionFilterBlueprintIndexMapping[regionFilter.id];
        if (isNumber(existingBlueprintIndex)) {
          const existingBlueprint = result[existingBlueprintIndex];
          existingBlueprint.features.push(reducedFeature);
        } else {
          regionFilterBlueprintIndexMapping[regionFilter.id] =
            result.push({
              features: [reducedFeature],
              tagObj: regionFilter
            }) - 1;
        }
      });
    });
    return result;
  }, [chosenFeatures, chosenRegionFilters, chosenRegions, exclude?.id]);

  const handleRegionOrRegionFilterClick = useCallback(
    (regionOrRegionFilter: ITagBase, isActive: boolean): void => {
      if (isRegionFilter(regionOrRegionFilter)) {
        if (isActive) {
          dispatch(stopEditRegionFilter());
        } else {
          dispatch(editRegionFilter(regionOrRegionFilter));
        }
      } else {
        if (isActive) {
          dispatch(setActiveRegionOrRegionFilter(null));
        } else {
          dispatch(setActiveRegionOrRegionFilter(regionOrRegionFilter as IRegionOrIRegionFilter));
        }
      }
    },
    [dispatch]
  );

  const popups = useMemo(
    () =>
      regionMarkersBlueprints.map(({ tagObj, features }) => {
        const isActive = areClickable && tagObj.id === activeRegionOrRegionFilter?.id;
        return (
          <Popup
            key={`popup_${tagObj.id}${isActive ? "--active" : ""}`}
            position={geoJSON({
              type: "FeatureCollection",
              features: features
            } as FeatureCollection)
              .getBounds()
              .getCenter()}
            autoClose={false}
            autoPan={false}
            closeButton={false}
            closeOnEscapeKey={false}
            closeOnClick={false}
            className={classNames(ns("region-marker"), {
              [ns("region-marker--active")]: isActive
            })}
          >
            <Tag
              fullWidth
              tagObj={tagObj}
              isRemovable
              isActive={isActive}
              getActionLabel={
                areClickable
                  ? isRegionFilter(tagObj)
                    ? isActive
                      ? (tagObj) => `${tagObj.title ?? tagObj.name} – Bearbeitung abbrechen`
                      : (tagObj) => `${tagObj.title ?? tagObj.name} bearbeiten`
                    : (tagObj) => `${tagObj.title ?? tagObj.name}`
                  : (tagObj) => `${tagObj.title ?? tagObj.name}`
              }
              handleClick={
                // `Tag` checks `handleClick` for `undefined` to see if it is an actionable button
                areClickable
                  ? () => {
                      handleRegionOrRegionFilterClick(tagObj, isActive);
                    }
                  : undefined
              }
              handleUnchoose={() => dispatch(unchooseRegionOrRegionFilter(tagObj))}
            />
          </Popup>
        );
      }),
    [regionMarkersBlueprints, areClickable, activeRegionOrRegionFilter?.id, handleRegionOrRegionFilterClick, dispatch]
  );

  return popups ? <>{popups}</> : null;
}
