import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../redux/appConfigSlice";
import { setActiveSelection } from "../../redux/indicatorsOrTopicsSlice";
import { selectActiveRenderer } from "../../redux/indicatorsOrTopicsSlice";
import { IIndicatorOrTopic } from "../../ts/interfaces";
import { getRendererIconProps, getRendererTypes, isTopic } from "../../utils/helpers";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import Button from "./button/Button";
import RendererButton from "./button/RendererButton";
import IndicatorOrTopicInfoModal from "./IndicatorOrTopicInfoModal";

interface Props {
  indicatorOrTopic: IIndicatorOrTopic;
  isActive: boolean;
  saveScrollPosition: () => void;
}

export default function IndicatorOrTopicCell({ indicatorOrTopic, isActive, saveScrollPosition }: Props) {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const allRenderers = getRendererTypes(indicatorOrTopic.type, appConfig.types);
  const currentRenderer = useSelector(selectActiveRenderer);

  const [isInfoOverlayOpen, setIsInfoOverlayOpen] = useState(false);

  let indicatorOrTopicLabel = indicatorOrTopic.name;
  if (!isTopic(indicatorOrTopic) && !isEmpty(indicatorOrTopic.unit)) {
    indicatorOrTopicLabel += ` (${indicatorOrTopic.unit})`;
  }

  const allButCurrentRenderer = allRenderers.filter((renderer) => renderer !== currentRenderer);

  const handleSetActiveSelection = (indicatorOrTopic: IIndicatorOrTopic) => {
    dispatch(setActiveSelection(indicatorOrTopic));
  };

  const infoIconPath = useGetSvgPath("#info");

  return (
    <div
      className={classNames(ns("indicator-or-topic-cell"), {
        [ns("indicator-or-topic-cell--active")]: isActive
      })}
    >
      <div className={ns("indicator-or-topic-cell__name")}>
        {!isActive ? (
          <span
            role="button"
            className={ns("link indicator-or-topic-cell__btn")}
            onClick={() => {
              handleSetActiveSelection(indicatorOrTopic);
            }}
          >
            {indicatorOrTopicLabel}
          </span>
        ) : (
          <span>{indicatorOrTopicLabel}</span>
        )}

        {!isEmpty(indicatorOrTopic.explanation) && (
          <Button
            className={ns("indicator-or-topic-cell__name-info")}
            label={`Info zum ${isTopic(indicatorOrTopic) ? "Thema" : "Indikator"}`}
            srOnly
            handleClick={() => setIsInfoOverlayOpen(true)}
            icon={
              <Icon className={ns("indicator-or-topic-cell__name-icon")} width={20} height={20}>
                <use xlinkHref={infoIconPath}></use>
              </Icon>
            }
          />
        )}
      </div>

      {!isEmpty(indicatorOrTopic.explanation) && isInfoOverlayOpen && (
        <IndicatorOrTopicInfoModal
          closeModal={() => setIsInfoOverlayOpen(false)}
          isOpen={isInfoOverlayOpen}
          indicatorOrTopic={indicatorOrTopic}
        />
      )}

      <div className={ns("indicator-or-topic-cell__renderer-icons")}>
        {allButCurrentRenderer.map((renderer, idx) => {
          const commonProps = {
            key: `key_${renderer}_${idx}`,
            rendererType: renderer,
            indicator: indicatorOrTopic,
            saveScrollPosition: saveScrollPosition
          };
          const rendererIconProps = getRendererIconProps(renderer);

          return rendererIconProps ? (
            <RendererButton iconId={rendererIconProps.id} {...rendererIconProps} {...commonProps} />
          ) : null;
        })}
      </div>
    </div>
  );
}
