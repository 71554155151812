import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDemographicType } from "../ts/interfaces";
import { RootState } from "./rootReducer";

interface IDemographicTypeState {
  loading: boolean;
  hasErrors: boolean;
  demographicType: IDemographicType | null;
}

const initialState: IDemographicTypeState = {
  loading: false,
  hasErrors: false,
  demographicType: null
};

interface IFetchDemographicTypeArgs {
  apiUrl: string;
  demographicTypeNumber: number;
}

export const fetchDemographicType = createAsyncThunk(
  "demographicType/fetchDemographicType",
  async ({ apiUrl, demographicTypeNumber }: IFetchDemographicTypeArgs) => {
    const response = await fetch(`${apiUrl}/${demographicTypeNumber}`);
    const demographicType = await response.json();
    return demographicType;
  }
);

// A slice for DemographicType with our three reducers
const demographicTypeSlice = createSlice({
  name: "demographicType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDemographicType.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDemographicType.fulfilled, (state, action) => {
      state.demographicType = action.payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchDemographicType.rejected, (state, action) => {
      state.loading = false;
      state.hasErrors = true;
    });
  }
});

export const selectDemographicType = (state: RootState) => state.demographicType;

export default demographicTypeSlice.reducer;
