import React, { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";

import isEmpty from "lodash/isEmpty";

import {
  selectActiveIndicator,
  selectChosenIndicatorsOrTopics,
  selectIsDemographicTypes,
  selectStatisticType
} from "../../../../redux/indicatorsOrTopicsSlice";
import classNames from "classnames";
import { getAvailableYears } from "../../../../utils/helpers";
import ns from "../../../../utils/namespace";
import YearPicker from "../../../molecules/YearPicker";
import IndicatorOrTopicList from "../IndicatorOrTopicList";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import { selectMapData } from "../../../../redux/mapDataSlice";
import MapContent from "./MapContent";
import DownloadDropdown from "../../../molecules/DownloadDropdown";
import RemarkDropdown from "../../../molecules/RemarkDropdown";
import { isFullApp, isMiniApp } from "../../../../utils/appSetup";
import StatisticsDataLicense from "../../../molecules/StatisticsDataLicense";
import PlayPauseButton from "../../../molecules/PlayPauseButton";
import { useYearsPlayback } from "../chart/useYearsPlayback";
import { useChosenSingleYear } from "../chart/useChosenSingleYear";
import { StatisticType } from "../../../../ts/interfaces";

interface Props {
  shallRenderYearPicker?: boolean;
}

export default function StatisticsMap({ shallRenderYearPicker = true }: Props): ReactElement {
  const appConfig = useSelector(selectAppConfig);

  const activeIndicator = useSelector(selectActiveIndicator);
  const availableYears = useMemo(() => (activeIndicator ? getAvailableYears([activeIndicator], appConfig.types) : []), [
    activeIndicator,
    appConfig.types
  ]);

  const { chosenSingleYear, setChosenSingleYear } = useChosenSingleYear();
  const { mapData, loading: isMapDataLoading } = useSelector(selectMapData);
  const statisticType = useSelector(selectStatisticType);
  const chosenIndicatorsOrTopics = useSelector(selectChosenIndicatorsOrTopics);
  // If current visible map is different than new map (e.g. when switching from standard indicator to demographic types) render placeholder
  const shallRenderPlaceholder = isEmpty(chosenIndicatorsOrTopics) || mapData?.type !== statisticType?.type;

  const isInFullApp = isFullApp(appConfig);
  const isDemographicTypes = useSelector(selectIsDemographicTypes);

  const { areYearsPlaying, wereYearsPlaying, handlePlayPauseClick } = useYearsPlayback(
    availableYears,
    chosenSingleYear,
    setChosenSingleYear,
    isMapDataLoading,
    1000,
    2000
  );

  const fetchAllYears = !wereYearsPlaying && areYearsPlaying;
  const yearsToFetch = useMemo(() => (fetchAllYears ? availableYears : chosenSingleYear ? [chosenSingleYear] : []), [
    availableYears,
    chosenSingleYear,
    fetchAllYears
  ]);

  return (
    <div
      className={classNames(ns("chart chart--map statistics-map"), {
        [ns("chart--mini-app")]: isMiniApp(appConfig),
        [ns("chart--full-app")]: isInFullApp,
        [ns("chart--demographic-types-map")]: isDemographicTypes,
        [ns("chart--geographic-migration")]: statisticType?.type === StatisticType.GeographicMigration
      })}
    >
      <div className={ns("row")}>
        {isInFullApp && (
          <div className={ns("col chart__aside")}>
            <IndicatorOrTopicList />
          </div>
        )}
        <div className={ns("col chart__main")}>
          {!shallRenderPlaceholder && isInFullApp && (
            <div className={ns("remark-download-panel")}>
              <RemarkDropdown className={classNames(ns("remark-download-panel__remark"))} />
              <DownloadDropdown className={classNames(ns("remark-download-panel__download"))} title="Daten" />
            </div>
          )}
          {chosenSingleYear && (
            <MapContent
              chosenSingleYear={chosenSingleYear}
              availableYears={availableYears}
              yearsToFetch={yearsToFetch}
              areYearsPlaying={areYearsPlaying}
              shallRenderPlaceholder={shallRenderPlaceholder}
            />
          )}
          {!shallRenderPlaceholder && shallRenderYearPicker && chosenSingleYear && !isEmpty(availableYears) && (
            <div className={ns("chart__footer")}>
              <div className={ns(`chart__year-chooser`)}>
                <YearPicker
                  availableYears={availableYears}
                  currentYear={chosenSingleYear}
                  setChosenSingleYear={setChosenSingleYear}
                />
                <span className={ns(`chart__footer-separator`)} />
                <PlayPauseButton isPlaying={areYearsPlaying} handleClick={handlePlayPauseClick} />
              </div>
            </div>
          )}
          {!isEmpty(mapData?.source) && (
            <div className={ns("chart__content-sources")}>
              Quelle: {mapData?.source} - <a href="/dois">DOIs</a>
            </div>
          )}
          <StatisticsDataLicense className={ns("chart__content-sources")} />
        </div>
      </div>
    </div>
  );
}
