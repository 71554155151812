import { createSlice, PayloadAction, CombinedState } from "@reduxjs/toolkit";
import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import { IClassItem, IDataFeature, IMapData, OutlineType } from "../ts/interfaces";
import { RootState } from "./rootReducer";
import createNonConcurrentAsyncThunk from "../utils/createNonConcurrentAsyncThunk";
import { AppDispatch, AppThunk } from "./store";
import { useNonConcurrentAsyncThunks } from "../utils/reduxHooks";
import { getDynamicSlices } from "./dynamicSlices";

interface IMapDataState {
  loading: boolean;
  hasErrors: boolean;
  mapData: IMapData | null;
  mapClasses: Array<IClassItem>;
}

const initialState: IMapDataState = {
  loading: false,
  hasErrors: false,
  mapData: null,
  mapClasses: []
};

interface IFetchMapDataArgs {
  apiUrl: string;
  bbox: string;
  indicatorId?: number;
  topicId?: number;
  regionIds: Array<number>;
  years: number[];
  outline: OutlineType;
}

interface IClassIndex {
  classIndex: number;
}

export const createMapDataNonConcurrentAsyncThunks = () => {
  const fetchMapData = createNonConcurrentAsyncThunk(
    "map/fetchMapData",
    async ({ apiUrl, bbox, indicatorId, topicId, regionIds, years, outline }: IFetchMapDataArgs) => {
      const requestBody: Partial<IFetchMapDataArgs> = {
        bbox,
        regionIds,
        years,
        outline
      };
      if (indicatorId) {
        requestBody.indicatorId = indicatorId;
      } else {
        requestBody.topicId = topicId;
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      const mapData = await response.json();
      return mapData as IMapData;
    }
  );

  return {
    fetchMapData
  };
};
export const useFetchMapData = () => {
  return useNonConcurrentAsyncThunks().mapData.fetchMapData;
};

export const createMapDataSlice = ({ fetchMapData }: ReturnType<typeof createMapDataNonConcurrentAsyncThunks>) => {
  // A slice for mapData with our three reducers
  return createSlice({
    name: "mapData",
    initialState,
    reducers: {
      handleClassToggle(state, action: PayloadAction<IClassIndex>) {
        const { classIndex } = action.payload;

        const newMapClasses = state.mapClasses.map((cl) => {
          if (cl.classIndex === classIndex) {
            return { ...cl, selected: !cl.selected };
          }
          return cl;
        });

        state.mapClasses = newMapClasses;
      },
      handleAllClassesToggle(state) {
        const areAllSelected = state.mapClasses.every((cl) => cl.selected);

        const newMapClasses = state.mapClasses.map((cl) => {
          if (areAllSelected) {
            return { ...cl, selected: false };
          } else {
            return { ...cl, selected: true };
          }
        });

        state.mapClasses = newMapClasses;
      }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchMapData.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(fetchMapData.fulfilled, (state, action) => {
        const mapData = action.payload;
        state.mapData = mapData;
        state.loading = false;
        state.hasErrors = false;
        if (
          mapData.classes.length !== state.mapClasses.length ||
          mapData.classes.some((classItem) => !state.mapClasses.find((cl) => cl.label === classItem.label))
        ) {
          state.mapClasses = mapData.classes.map((cl) => {
            return {
              ...cl,
              selected: true
            };
          });
        }
      });
      builder.addCase(fetchMapData.rejected, (state, action) => {
        state.loading = false;
        // Only set errors if rejected was not called from our own abort in createNonConcurrentAsyncThunk
        if (!action.meta.aborted) {
          state.hasErrors = true;
        }
      });
    }
  });
};

const getMapDataSlice = (getState: () => CombinedState<RootState>) => {
  const appId = getState().appId.value;
  return getDynamicSlices(appId).mapData;
};

export const handleClassToggle = (classIndex: number): AppThunk => async (
  dispatch: AppDispatch,
  getState: () => CombinedState<RootState>
) => {
  const mapDataSlice = getMapDataSlice(getState);
  dispatch(
    mapDataSlice.actions.handleClassToggle({
      classIndex: classIndex
    })
  );
};

export const handleAllClassesToggle = (): AppThunk => async (
  dispatch: AppDispatch,
  getState: () => CombinedState<RootState>
) => {
  const mapDataSlice = getMapDataSlice(getState);
  dispatch(mapDataSlice.actions.handleAllClassesToggle());
};

export const selectMapData = (state: RootState) => state.mapData;

export const selectMapClasses = (state: RootState) => state.mapData.mapClasses;

export const selectMapRemark = (state: RootState) => state.mapData.mapData?.remark;

/**********************************************
 * Begion of special handling of chosenFeatures:
 *
 * With every map zoom we request a new bbox and following that we also request new features
 * However, the chosen features (regions) stay mostly the same.
 * Hence we use an better compareFunction that is more intelligent than a simple reference comparison
 * that would always return "new" Array of chosenFeatures
 *
 **********************************************/

// Have to resort to `any` here due to bug https://github.com/reduxjs/reselect/issues/384
const compareFeaturesArrays = (featuresArrayA: any, featuresArrayB: any) => {
  if (featuresArrayA && featuresArrayB) {
    const idsA = featuresArrayA.map((feature: IDataFeature) => feature.id);
    const idsB = featuresArrayB.map((feature: IDataFeature) => feature.id);

    // do a deepcompare, not just reference compare
    const res = isEqual(idsA, idsB);

    return res;
  } else if (isUndefined(featuresArrayA) && isUndefined(featuresArrayB)) {
    return true;
  }

  // Either featuresArrayA or featuresArrayB is `undefined` while the other is not, so not equal
  return false;
};

// create a "selector creator" that uses lodash.isequal instead of ===
const createChosenFeaturesSelector = createSelectorCreator(defaultMemoize, compareFeaturesArrays);

export const createMapDataMemoizedSelectors = () => {
  return {
    selectChosenFeatures: createChosenFeaturesSelector(
      (state: RootState) =>
        // the result of this selector will be checked using the more intelligent `compareFeaturesArrays` from above
        state.mapData.mapData?.regions?.features.filter((feature) => feature.properties.selected),
      (features: Array<IDataFeature> | undefined) => {
        if (features) {
          return features;
        }
        return [];
      }
    )
  };
};

/**********************************************
 * End of special handling of chosenFeatures.
 **********************************************/
