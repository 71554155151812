import { combineReducers } from "@reduxjs/toolkit";
import appConfig from "./appConfigSlice";
import regionsOrRegionFilters from "./regionsOrRegionFiltersSlice";
import filters from "./filtersSlice";
import indicatorsOrTopics from "./indicatorsOrTopicsSlice";
import demographicType from "./demographicTypeSlice";
import years from "./yearsSlice";
import ui from "./uiSlice";
import topicSets from "./topicSetsSlice";
import statisticsData from "./statisticsDataSlice";
import mapPickerBbox from "./mapPickerBboxSlice";
import mapPickerData from "./mapPickerDataSlice";
import { getDynamicSlices } from "./dynamicSlices";

export const createRootReducer = (appId: string) => {
  const dynamicSlices = getDynamicSlices(appId);

  return combineReducers({
    appId: dynamicSlices.appId.reducer,
    appConfig,
    regionsOrRegionFilters,
    filters,
    indicatorsOrTopics,
    demographicType,
    years,
    ui,
    topicSets,
    statisticsData,
    mapBbox: dynamicSlices.mapBbox.reducer,
    mapData: dynamicSlices.mapData.reducer,
    mapPickerBbox,
    mapPickerData
  });
};

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
