import React, { ReactElement, ReactNode, RefObject, useCallback, useEffect, useRef } from "react";
import { Column, useTable, useBlockLayout, CellProps } from "react-table";
import { useSticky } from "react-table-sticky";
import classNames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";

import { unchooseIndicatorOrTopic } from "../../../redux/indicatorsOrTopicsSlice";
import { useSelector, useDispatch } from "react-redux";
import ns from "../../../utils/namespace";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import RemoveIndicatorButton from "../../molecules/button/RemoveIndicatorButton";
import { IIndicatorOrTopic } from "../../../ts/interfaces";
import { getAvailableYears, getFormattedValue } from "../../../utils/helpers";
import IndicatorOrTopicCell from "../../molecules/IndicatorOrTopicCell";
import ModalButton from "../../molecules/button/ModalButton";

import { fetchStatisticsData, selectStatisticsData } from "../../../redux/statisticsDataSlice";
import { chooseYears, selectChosenYears } from "../../../redux/yearsSlice";
import YearSlider from "../../molecules/YearSlider";
import { selectScrollPosition, setScrollPosition } from "../../../redux/uiSlice";
import DownloadDropdown from "../../molecules/DownloadDropdown";
import RemarkDropdown from "../../molecules/RemarkDropdown";
import { usePrevious } from "../../../utils/usePrevious";
import { isFullApp } from "../../../utils/appSetup";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import Loader from "../../molecules/Loader";
import StatisticsDataLicense from "../../molecules/StatisticsDataLicense";

type IRow = {
  removeCol: ReactNode;
  indicatorCol: string;
  [key: string]: string | ReactNode;
};

type ICellValue = {
  value: string | number;
  title?: string;
  hasRemark?: boolean;
};

const getOffsetHeight = (headerRef: RefObject<HTMLDivElement | null>): number => {
  return headerRef.current ? headerRef.current.offsetHeight : 0;
};

export default function StatisticsTable(): ReactElement {
  const dispatch = useDispatch();

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenIndicators } = memoizedSelectors.indicatorsOrTopics;
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;

  const appConfig = useSelector(selectAppConfig);
  const isInFullApp = isFullApp(appConfig);

  const scrollPosition = useSelector(selectScrollPosition);

  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);

  // we use the ref to calc the height for syncing the scroll position
  useEffect(() => {
    if (scrollContentRef.current) {
      const scrollTop = scrollPosition.scrollTop;
      switch (scrollPosition.comesFrom) {
        case "IndicatorOrTopicList": {
          // TABLE renderer has a fixed header (offset) that the IndicatorList has not
          const offset = getOffsetHeight(headerRef);
          // scrollTop greater than max scrollbar is handled by browser, i.e. set to max scrollbar
          scrollContentRef.current.scrollTop = scrollTop + offset;
          break;
        }
        case "Table":
        default: {
          scrollContentRef.current.scrollTop = scrollTop;
        }
      }
    }
    // Only running once when mounting this component is sufficient
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indicators = useSelector(selectChosenIndicators);
  const prevIndicators = usePrevious(indicators);
  const regions = useSelector(selectChosenRegions);
  const chosenYears = useSelector(selectChosenYears);

  const setChosenYears = useCallback(
    (years: Array<number>) => {
      dispatch(chooseYears(years));
    },
    [dispatch]
  );

  // when indicators change & a new one is added, the table should be scrolled down
  // so the new indicator will be visible to thew user
  useEffect(() => {
    if (scrollContentRef.current && prevIndicators && prevIndicators.length < indicators.length) {
      scrollContentRef.current.scrollTop = scrollContentRef.current.scrollHeight;
    }
  }, [indicators, prevIndicators]);

  // All indicators are of the same type, simply use the first get type
  const availableYears = getAvailableYears(indicators, appConfig.types);

  const { statisticsData, loading: isLoading, hasErrors } = useSelector(selectStatisticsData);
  const hasStatisticsData = statisticsData ? true : false;

  const apiUrl = appConfig.urls.apiStatisticsData;

  useEffect(() => {
    if (apiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch statistics data for table, apiUrl is: ", apiUrl);
      return;
    }
    dispatch(
      fetchStatisticsData({
        apiUrl,
        indicators: indicators,
        regions: regions,
        years: chosenYears
      })
    );
  }, [dispatch, apiUrl, indicators, regions, chosenYears]);

  const lastYearRef = useRef<HTMLInputElement>(null);
  // This gets only executed when table gets first time data
  useEffect(() => {
    const lastYearNode = lastYearRef.current;
    if (lastYearNode) {
      scrollContentRef.current?.scrollTo(lastYearNode.offsetLeft, 0);
    }
  }, [hasStatisticsData]);

  const ourCols: Array<Column<IRow>> = React.useMemo(() => {
    const firstCols = {
      Header: "",
      id: "firstColLeft",
      sticky: "left",
      columns: [
        {
          Header: "",
          accessor: "removeCol",
          width: "56"
        },
        {
          // Width of indicator item/cell must be the same for IndicatorList in charts as for StatisticsTable.
          // Hence, set to a fixed value.
          // If you change `width` here, you also need to check the width of indicator item/cell in charts, e.g. in `indicator-list.scss`
          Header: "INDIKATOREN",
          accessor: "indicatorCol",
          width: "367"
        }
      ]
    };

    let yearRegionsCols: Array<object> = [];
    if (statisticsData) {
      yearRegionsCols = chosenYears.map((year) => {
        return {
          Header: year,
          id: `year${year}`,
          columns: statisticsData.regions.map((r) => {
            const colId = `${year}_${r.id}`;
            return {
              Header: r.name,
              id: colId,
              accessor: colId,
              Cell: ({ value }: CellProps<any, ICellValue>) => {
                return (
                  <span
                    title={value.title}
                    className={classNames(ns("statistics-table__cell-value"), {
                      [ns("statistics-table__cell-value--remark")]: value.hasRemark
                    })}
                  >
                    {value.value}
                  </span>
                );
              }
            };
          })
        };
      });
    }

    return [firstCols, ...yearRegionsCols];
  }, [chosenYears, statisticsData]) as Array<Column<IRow>>;

  const data = React.useMemo(() => {
    const saveScrollPosition = () => {
      const scrollTop = scrollContentRef.current ? scrollContentRef.current.scrollTop : 0;
      const offset = getOffsetHeight(headerRef);

      dispatch(
        setScrollPosition({
          scrollTop,
          offset,
          comesFrom: "Table"
        })
      );
    };

    const handleUnchooseIndicatorOrTopic = (indicatorOrTopic: IIndicatorOrTopic) => {
      dispatch(unchooseIndicatorOrTopic(indicatorOrTopic, appConfig.types));
    };

    const allIndicators = indicators;

    // Replacing `any` here was not possible due to `react-table` shenanigans
    const allRows: Array<any> = allIndicators.map((indi, i) => {
      const firstCells = {
        removeCol: (
          <RemoveIndicatorButton tagObj={indi} handleUnchoose={handleUnchooseIndicatorOrTopic} />
        ) as ReactNode,
        indicatorCol: (
          <IndicatorOrTopicCell indicatorOrTopic={indi} isActive={true} saveScrollPosition={saveScrollPosition} />
        )
      };

      const dataCells: {
        [key: string]: ICellValue;
      } = {};
      chosenYears.forEach((year, yi) => {
        statisticsData?.regions.forEach((r, ri) => {
          const value = get(statisticsData, `indicators[${i}].regionYearValues[${ri}][${yi}]`);
          const remark = get(statisticsData, `indicators[${i}].remark`);
          dataCells[`${year}_${r.id}`] = {
            value: isNumber(value)
              ? getFormattedValue(indi, value)
              : i < get(statisticsData, `indicators`, []).length
              ? "k.A."
              : "…",
            title: !isNumber(value) ? remark || "keine Angaben" : undefined,
            hasRemark: remark ? true : false
          };
        });
      });
      return {
        ...firstCells,
        ...dataCells
      };
    });

    return allRows;
  }, [indicators, dispatch, appConfig.types, chosenYears, statisticsData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: ourCols,
      data
    },
    useBlockLayout,
    useSticky
  );

  if (!isEmpty(statisticsData)) {
    return (
      <>
        <div {...getTableProps()} className={ns("statistics-table")}>
          {isInFullApp && (
            <div className={ns("remark-download-panel statistics-table__remark-download-panel")}>
              <RemarkDropdown className={classNames(ns("remark-download-panel__remark"))} />
              <DownloadDropdown className={classNames(ns("remark-download-panel__download"))} title="Daten" />
            </div>
          )}
          <div className={ns("statistics-table__scrollcontent-wrapper")}>
            <div className={ns("statistics-table__scrollcontent")} ref={scrollContentRef}>
              <div className={ns("statistics-table__header")} ref={headerRef}>
                {headerGroups.map((headerGroup, groupIdx) => (
                  <div {...headerGroup.getHeaderGroupProps()} className={ns("statistics-table__tr")}>
                    {headerGroup.headers.map((column, headersIdx) => {
                      // First header row are years
                      const isYearRow = groupIdx === 0;
                      // Second row are regions
                      const isRegionsRow = groupIdx === 1;

                      const isLastYearCol = isYearRow && headersIdx === headerGroup.headers.length - 1;

                      // Ignore first left cell for years and first 3 cells for regions:
                      // removeCol (0), indicatorCol (1) and first year cell (2)
                      const isNotFirst = isYearRow ? headersIdx > 0 : headersIdx > 2;
                      const isFirstCellInYear =
                        isNotFirst &&
                        (isYearRow ||
                          (isRegionsRow &&
                            statisticsData?.regions &&
                            (headersIdx - 2) % statisticsData.regions.length === 0));

                      return (
                        <div
                          {...column.getHeaderProps()}
                          className={classNames(ns("statistics-table__td"), {
                            [ns("statistics-table__th--first-cell-in-year")]: isFirstCellInYear
                          })}
                          // Last header col gets ref so we can scroll to it
                          // So this is the first header row (=year row) and there the last item
                          {...(isLastYearCol ? { ref: lastYearRef } : {})}
                        >
                          {column.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className={ns("statistics-table__body")}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className={ns("statistics-table__tr")}>
                      {row.cells.map((cell, ci) => {
                        // Ignore first 3 cells:
                        // removeCol (0), indicatorCol (1) and first year cell (2)
                        const isNotFirst = ci > 2;
                        const isFirstCellInYear =
                          isNotFirst && statisticsData?.regions && (ci - 2) % statisticsData.regions.length === 0;
                        return (
                          <div
                            {...cell.getCellProps()}
                            className={classNames(ns("statistics-table__td"), {
                              [ns("statistics-table__td--first-cell-in-year")]: isFirstCellInYear
                            })}
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            {isLoading && <Loader isCentered />}
          </div>
          <div className={ns("statistics-table__footer")}>
            <div className={ns("statistics-table__indicator-add")}>
              <ModalButton
                className={ns("btn--primary btn--icon indicator-add")}
                label="Indikator hinzufügen"
                srOnly={false}
                which="indicatorsOrTopics"
              />
            </div>
            <div className={ns("statistics-table__slider")}>
              {!isEmpty(availableYears) && (
                <YearSlider
                  availableYears={availableYears}
                  currentYears={chosenYears}
                  setChosenYears={setChosenYears}
                />
              )}
            </div>
          </div>
        </div>
        {!isEmpty(statisticsData?.source) && (
          <div className={ns("statistics-table__sources")}>
            Quelle: {statisticsData?.source} - <a href="/dois">DOIs</a>
          </div>
        )}
        <StatisticsDataLicense className={ns("statistics-table__sources")} />
      </>
    );
  } else if (isLoading) {
    return (
      <div className={classNames(ns("statistics-table"), ns("statistics-table--loading"))}>
        <Loader isCentered />
      </div>
    );
  } else if (hasErrors) {
    return (
      <p>
        <strong>
          Leider gab es einen Fehler bei der Verbindung mit dem Statistikdienst! Bitte versuchen Sie es später erneut.
        </strong>
      </p>
    );
  } else {
    return <div />;
  }
}
