import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { useMapEvents } from "react-leaflet";
import { Map } from "leaflet";
import debounce from "lodash/debounce";

import { setLeafletPickerBbox } from "../../../redux/mapPickerBboxSlice";

const calcAndSetLeafletBbox = (dispatch: Dispatch<any>, map: Map) => {
  const mapBounds = map.getBounds();
  const leafletBbox = [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()];
  dispatch(setLeafletPickerBbox(leafletBbox));
};

export default function MapEvents(): null {
  const dispatch = useDispatch();
  const debounceWait = 100;

  const map = useMapEvents({
    moveend: debounce(() => {
      calcAndSetLeafletBbox(dispatch, map);
    }, debounceWait)
  });
  return null;
}
