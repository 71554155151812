import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMapBbox } from "../ts/interfaces";
import { RootState } from "./rootReducer";
import { AppThunk, AppDispatch } from "./store";
import createNonConcurrentAsyncThunk from "../utils/createNonConcurrentAsyncThunk";

interface IMapPickerBboxState {
  loading: boolean;
  hasErrors: boolean;
  // 1st: The bbox as calculated by the backend for the given features
  mapPickerBbox: IMapBbox | null;
  // 2nd: The bbox Leaflet zooms/pans to when it tries to `fitBounds()` to it
  // Calucalated after dragend/panend/zoomend
  // The leafletBbox is greater (or equals) as mapBbox
  // and is used for getting the features for the visible bbox
  leafletBbox: IMapBbox | null;
}

const initialState: IMapPickerBboxState = {
  loading: false,
  hasErrors: false,
  mapPickerBbox: null,
  leafletBbox: null
};

interface IFetchMapPickerBboxArgs {
  apiUrl: string;
  regionIds: Array<number>;
  minimal?: boolean;
}

export const fetchMapPickerBbox = createNonConcurrentAsyncThunk(
  "mapPicker/fetchMapPickerBbox",
  async ({ apiUrl, regionIds, minimal }: IFetchMapPickerBboxArgs) => {
    const response = await fetch(`${apiUrl}${minimal ? "?outline=LANDKREIS" : ""}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(regionIds)
    });
    const mapPickerBbox = await response.json();
    return mapPickerBbox;
  }
);

// A slice for mapPickerBbox with our three reducers
const mapPickerBboxSlice = createSlice({
  name: "mapPickerBbox",
  initialState,
  reducers: {
    setLeafletPickerBbox(state, action: PayloadAction<{ leafletBbox: IMapBbox }>) {
      const { leafletBbox } = action.payload;
      state.leafletBbox = leafletBbox;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMapPickerBbox.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchMapPickerBbox.fulfilled, (state, action) => {
      state.mapPickerBbox = action.payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchMapPickerBbox.rejected, (state, action) => {
      state.loading = false;
      // Only set errors if rejected was not called from our own abort in createNonConcurrentAsyncThunk
      if (!action.meta.aborted) {
        state.hasErrors = true;
      }
    });
  }
});

export const setLeafletPickerBbox = (leafletBbox: IMapBbox): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    mapPickerBboxSlice.actions.setLeafletPickerBbox({
      leafletBbox: leafletBbox
    })
  );
};

export const selectMapPickerBbox = (state: RootState) => state.mapPickerBbox;

export default mapPickerBboxSlice.reducer;
