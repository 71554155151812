import { Layer, Map } from "leaflet";
import { useCallback, useMemo, useRef, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { chooseRegionOrRegionFilter, unchooseRegionOrRegionFilter } from "../../../redux/regionsOrRegionFiltersSlice";
import { clearActiveFeature } from "../../../redux/uiSlice";
import { IFeature, IPickerFeature, IRegion } from "../../../ts/interfaces";
import { usePrevious } from "../../../utils/usePrevious";
import RegionPopup from "../RegionPopup";
import { addActiveFeatureHandler } from "./addActiveFeatureHandler";

/**
 * Whether feature includes IPickerFeatureProperties or IDataFeatureProperties
 * @param feature
 * @returns true if IPickerFeatureProperties (otherwise it is IDataFeatureProperties)
 */
const isPickerProperties = (feature: IFeature): feature is IPickerFeature =>
  "parent" in feature.properties && !("values" in feature.properties || "classIndices" in feature.properties);

/**
 * Convert a feature to an IRegion
 *
 * @param feature
 * @returns the region
 */
const getRegion = (feature: IFeature): IRegion => {
  const { name, gkz, type, friendlyUrl } = feature.properties;
  let { demographicType } = feature.properties;
  let parent;

  if (isPickerProperties(feature)) {
    parent = feature.properties.parent;
  }

  return {
    id: feature.id as number,
    name: name,
    demographicType: demographicType,
    gkz: gkz,
    title: name,
    type: type,
    friendlyUrl: friendlyUrl,
    parent: parent
  };
};

/**
 * Returns a callback function for each feature in a leaflet map that registers eventhandlers to bind tooltips using
 * provided addTooltip function and unbinds the tooltip for provided `activeFeature`.
 * Furthermore if `activeFeature` is defined it returns a "rendered" `RegionPopup` instance to be included in a map component.
 * @param map the map instance which is passed to provided addTooltip function for direction calculation
 * @param activeFeature current active feature in leaflet map
 * @param addTooltip a callback which binds a tooltip to provided layer
 * @param setActiveFeature a callback which is executed when "Add region" button of `RegionPopup` is clicked and also with `null` when `RegionPopup` gets closed
 * @param renderPopupContent an optional callback which can render additionial information inside of `RegionPopup` (by default only name is rendered)
 * @param popupClassName optional class name to be passed as property to `RegionPopup`
 * @param linkRegionInPopup weither to link to commune page inside of created `RegionPopup`
 */
export const useRegionPopupAndTooltip = <Feature extends IFeature>(
  map: Map | null,
  activeFeature: Feature | null,
  addTooltip: (feature: Feature, layer: Layer, map: Map) => void,
  setActiveFeature: (feature: Feature | null) => void,
  renderPopupContent?: (activeFeature: Feature) => ReactNode,
  popupClassName?: string,
  linkRegionInPopup?: boolean
) => {
  const dispatch = useDispatch();

  // we need this ref to use activeFeature in static leaflet event handlers
  // which are not updated on state change
  const activeFeatureRef = useRef(activeFeature);
  activeFeatureRef.current = activeFeature;
  const previousActiveFeature = usePrevious(activeFeature);

  // we need this mapping from feature id to related current layer in map since
  // onEachFeatureCallback is recalled when activeFeature changes but the map
  // doesn't automatically rerenders
  // therefore we use this mapping for interacting with specific feature related layers
  // on component rendering
  const featureIdLayerMapping = useRef<{ [featureId: string]: Layer }>({});

  if (activeFeature?.id) {
    featureIdLayerMapping.current[activeFeature.id]?.unbindTooltip();
  }
  if (map && previousActiveFeature?.id && previousActiveFeature !== activeFeature) {
    const currentLayerOfPreviousFeature = featureIdLayerMapping.current[previousActiveFeature.id];
    if (currentLayerOfPreviousFeature) {
      addTooltip(previousActiveFeature, currentLayerOfPreviousFeature, map);
    }
  }

  const onEachFeatureCallback = useCallback(
    (feature: Feature, layer: Layer) => {
      layer.on("add", () => {
        // note that we update stored layer for current feature only
        // when layer is actually added to the map
        if (feature.id) {
          featureIdLayerMapping.current[feature.id] = layer;
        }

        if (map && feature.id !== activeFeatureRef.current?.id) {
          addTooltip(feature, layer, map);
        }

        addActiveFeatureHandler(feature, layer, activeFeatureRef, setActiveFeature);
      });
    },
    [addTooltip, map, setActiveFeature]
  );

  const renderedRegionPopup = useMemo(() => {
    if (activeFeature) {
      return (
        <RegionPopup
          className={popupClassName}
          feature={activeFeature}
          onClose={() => dispatch(clearActiveFeature())}
          onChoose={() => dispatch(chooseRegionOrRegionFilter(getRegion(activeFeature)))}
          onUnchoose={() => dispatch(unchooseRegionOrRegionFilter(activeFeature))}
          linkRegion={linkRegionInPopup}
        >
          {renderPopupContent && renderPopupContent(activeFeature)}
        </RegionPopup>
      );
    } else {
      return null;
    }
  }, [activeFeature, popupClassName, linkRegionInPopup, renderPopupContent, dispatch]);

  return {
    onEachFeatureCallback,
    renderedRegionPopup
  };
};
