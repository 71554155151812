import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import {
  selectActiveIndicator,
  selectActiveTopic,
  selectAreIndicatorsAvailable,
  selectStatisticType
} from "../../../../redux/indicatorsOrTopicsSlice";
import { selectLowRegionsCount, selectTopRegionsCount } from "../../../../redux/regionsOrRegionFiltersSlice";
import { fetchStatisticsData, selectStatisticsData } from "../../../../redux/statisticsDataSlice";
import { isSingleRegion } from "../../../../utils/helpers";
import { useMemoizedSelectors } from "../../../../utils/reduxHooks";
import { usePrevious } from "../../../../utils/usePrevious";

export const useGetStatisticsData = (allYears: number[], years?: number[]) => {
  const dispatch = useDispatch();

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenLeafTopics } = memoizedSelectors.indicatorsOrTopics;
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;

  const regions = useSelector(selectChosenRegions);
  const topRegionsCount = useSelector(selectTopRegionsCount);
  const lowRegionsCount = useSelector(selectLowRegionsCount);
  const indicator = useSelector(selectActiveIndicator);
  const topic = useSelector(selectActiveTopic);
  const chosenLeafTopics = useSelector(selectChosenLeafTopics);
  const statisticType = useSelector(selectStatisticType);
  const hasStatisticType = statisticType ? true : false;
  const areIndicatorsAvailable = useSelector(selectAreIndicatorsAvailable);
  const appConfig = useSelector(selectAppConfig);
  const apiUrl = appConfig.urls.apiStatisticsData;

  // Storing "core" arguments (all statistics-data related arguments except years)
  // in own variable to be able to say if they kept same since last fetching
  const fetchStatisticsDataCoreArgs = useMemo(() => {
    const areTopLowRegionsAvailableAndUseful = indicator?.topLowRegionsAvailable && isSingleRegion(regions);

    return {
      indicators: indicator ? [indicator] : [],
      topics: topic ? [topic] : chosenLeafTopics,
      regions: regions,
      topRegionsCount: areTopLowRegionsAvailableAndUseful && topRegionsCount ? topRegionsCount : undefined,
      lowRegionsCount: areTopLowRegionsAvailableAndUseful && lowRegionsCount ? lowRegionsCount : undefined
    };
  }, [chosenLeafTopics, indicator, lowRegionsCount, regions, topRegionsCount, topic]);
  const previousFetchStatisticsDataCoreArgs = usePrevious(fetchStatisticsDataCoreArgs);
  const previousYears = usePrevious(years);

  const yearsToFetch = years ? years : allYears;

  // Once all available years for current "core" arguments get fetched this is set to true in effect below
  // Using ref here since value changes shall not trigger rerendering
  const hasFetchedAvailableYearsRef = useRef(false);

  useEffect(() => {
    if (apiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch statistics data for charts, apiUrl is: ", apiUrl);
      return;
    }

    if (years?.length === 0) {
      return;
    }

    if (
      fetchStatisticsDataCoreArgs === previousFetchStatisticsDataCoreArgs &&
      (hasFetchedAvailableYearsRef.current || years === previousYears)
    ) {
      // Fetched already all available years for current "core" arguments (indicatorIds, topicIds, regionIds) => no need to fetch anything
      return;
    }

    hasFetchedAvailableYearsRef.current = isEqual(yearsToFetch, allYears);

    dispatch(
      fetchStatisticsData({
        apiUrl,
        indicators: fetchStatisticsDataCoreArgs.indicators,
        topics: hasStatisticType && !areIndicatorsAvailable ? fetchStatisticsDataCoreArgs.topics : undefined,
        regions: fetchStatisticsDataCoreArgs.regions,
        years: yearsToFetch,
        topRegionsCount: fetchStatisticsDataCoreArgs.topRegionsCount,
        lowRegionsCount: fetchStatisticsDataCoreArgs.lowRegionsCount
      })
    );
  }, [
    apiUrl,
    areIndicatorsAvailable,
    allYears,
    dispatch,
    fetchStatisticsDataCoreArgs,
    hasStatisticType,
    previousFetchStatisticsDataCoreArgs,
    previousYears,
    years,
    yearsToFetch
  ]);

  const { statisticsData, loading: isLoading, hasErrors, args } = useSelector(selectStatisticsData);

  return {
    statisticsData,
    statisticsDataArgs: args,
    isLoading,
    hasErrors
  };
};
