import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { setActiveLeftRight, setModalIsOpen, selectIsLeftActive } from "../../redux/uiSlice";
import Button from "../molecules/button/Button";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";

interface Props {
  leftLabel: string;
  rightLabel: string;
}

export default function LeftRightToggleBar({ leftLabel, rightLabel }: Props): ReactElement {
  const dispatch = useDispatch();
  const isLeftActive = useSelector(selectIsLeftActive);

  return (
    <div className={ns("left-right-toggle-bar")}>
      <Button
        label={leftLabel}
        className={classNames(ns("left-right-toggle-bar__toggle-btn"), {
          [ns("left-right-toggle-bar__toggle-btn--active")]: isLeftActive
        })}
        handleClick={() => dispatch(setActiveLeftRight("left"))}
      />
      <Button
        label={rightLabel}
        className={classNames(ns("left-right-toggle-bar__toggle-btn"), {
          [ns("left-right-toggle-bar__toggle-btn--active")]: !isLeftActive
        })}
        handleClick={() => dispatch(setActiveLeftRight("right"))}
      />
      <Button
        label="Schließen"
        className={ns("btn--mira left-right-toggle-bar__close-btn")}
        srOnly
        icon={
          <Icon className={ns("btn__icon")} width={23} height={23}>
            <use xlinkHref={useGetSvgPath("#close")}></use>
          </Icon>
        }
        handleClick={() => dispatch(setModalIsOpen(false))}
      />
    </div>
  );
}
