import classNames from "classnames";
import React, { ReactElement, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import head from "lodash/head";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import {
  selectActiveSelection,
  selectAreIndicatorsAvailable,
  selectIsDemographicTypes,
  selectStatisticType,
  unchooseIndicatorOrTopic
} from "../../../redux/indicatorsOrTopicsSlice";
import { selectScrollPosition, setScrollPosition } from "../../../redux/uiSlice";
import { IIndicator, IIndicatorOrTopic, ITopic, StatisticType } from "../../../ts/interfaces";
import { isTopic } from "../../../utils/helpers";
import ns from "../../../utils/namespace";
import ModalButton from "../../molecules/button/ModalButton";
import RemoveIndicatorButton from "../../molecules/button/RemoveIndicatorButton";
import FurtherInfoBox from "../../molecules/FurtherInfoBox";
import IndicatorOrTopicCell from "../../molecules/IndicatorOrTopicCell";
import { usePrevious } from "../../../utils/usePrevious";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import DemograpicTypeInfoBox from "../../molecules/DemograpicTypeInfoBox";

export default function IndicatorOrTopicList(): ReactElement {
  const dispatch = useDispatch();
  const { selectChosenIndicators, selectChosenLeafTopics } = useMemoizedSelectors().indicatorsOrTopics;

  const appConfig = useSelector(selectAppConfig);

  const statisticType = useSelector(selectStatisticType);
  const areItemsAddableOrRemovable = statisticType?.type !== StatisticType.GeographicMigration;
  const areIndicatorsAvailable = useSelector(selectAreIndicatorsAvailable);
  const isDemographicTypes = useSelector(selectIsDemographicTypes);

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;
  const regions = useSelector(selectChosenRegions);
  const firstRegion = head(regions);

  const indicators = useSelector(selectChosenIndicators);
  const prevIndicators = usePrevious(indicators);
  const leafTopics = useSelector(selectChosenLeafTopics);

  const indicatorsOrLeafTopics: Array<IIndicator | ITopic> = areIndicatorsAvailable ? indicators : leafTopics;
  const activeSelection = useSelector(selectActiveSelection);

  const scrollPosition = useSelector(selectScrollPosition);
  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const scrollContentMobileRef = useRef<HTMLOListElement | null>(null);
  const activeElementRef = useRef<HTMLLIElement | null>(null);

  const saveScrollPosition = () => {
    const scrollTop = scrollContentRef.current ? scrollContentRef.current.scrollTop : 0;
    dispatch(
      setScrollPosition({
        scrollTop: scrollTop,
        offset: 0,
        comesFrom: "IndicatorOrTopicList"
      })
    );
  };

  useEffect(() => {
    if (scrollContentRef.current) {
      const scrollTop = scrollPosition.scrollTop;
      switch (scrollPosition.comesFrom) {
        case "Table": {
          const offset = scrollPosition.offset;
          // scrollTop below 0 is handled by browser, i.e. set to 0
          scrollContentRef.current.scrollTop = scrollTop - offset;
          break;
        }
        case "IndicatorOrTopicList":
        default: {
          scrollContentRef.current.scrollTop = scrollTop;
        }
      }
    }
    // Only running once when mounting this component is sufficient
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when indicators change & a new one is added, the indicator list should be scrolled down
  // so the new indicator will be visible to thew user
  useLayoutEffect(() => {
    if (scrollContentRef.current && scrollContentMobileRef.current && prevIndicators) {
      scrollContentRef.current.scrollTop = activeElementRef.current?.offsetTop || 0;
      scrollContentMobileRef.current.scrollLeft = activeElementRef.current?.offsetLeft || 0;
    }
  }, [indicators, prevIndicators]);

  const handleUnchooseIndicatorOrTopic = (indicatorOrTopic: IIndicatorOrTopic) => {
    dispatch(unchooseIndicatorOrTopic(indicatorOrTopic, appConfig.types));
  };

  const isSingleIndicatorOrTopic = indicatorsOrLeafTopics.length === 1;

  return (
    <div className={ns("indicator-or-topic-list")}>
      <div className={ns("indicator-or-topic-list__container")} ref={scrollContentRef}>
        <ol className={ns("indicator-or-topic-list__items")} ref={scrollContentMobileRef}>
          {indicatorsOrLeafTopics.map((indiOrTopic, i) => {
            const isIndicatorActive = activeSelection?.id === indiOrTopic.id || isSingleIndicatorOrTopic;

            return (
              <li
                key={`${indiOrTopic.id}_${i}`}
                ref={isIndicatorActive ? activeElementRef : null}
                className={classNames(ns("indicator-or-topic-list__item"), {
                  [ns("indicator-or-topic-list__item--active")]: isIndicatorActive
                })}
              >
                {areItemsAddableOrRemovable && !isTopic(indiOrTopic) && (
                  <RemoveIndicatorButton tagObj={indiOrTopic} handleUnchoose={handleUnchooseIndicatorOrTopic} />
                )}
                <IndicatorOrTopicCell
                  indicatorOrTopic={indiOrTopic}
                  isActive={isIndicatorActive}
                  saveScrollPosition={saveScrollPosition}
                />
              </li>
            );
          })}
        </ol>
      </div>
      <div className={ns("indicator-or-topic-list__footer")}>
        {areIndicatorsAvailable && areItemsAddableOrRemovable && (
          <ModalButton
            className={ns("btn--primary btn--icon indicator-add")}
            label="Indikator hinzufügen"
            srOnly={false}
            which="indicatorsOrTopics"
          />
        )}
      </div>
      {isDemographicTypes && firstRegion && (
        <>
          {firstRegion.demographicType && firstRegion.demographicType > 0 ? (
            <DemograpicTypeInfoBox demographicTypeNumber={firstRegion.demographicType} />
          ) : undefined}
          <FurtherInfoBox
            title="Typisierung"
            text="Alle Handlungsempfehlungen und die Zuordnung der Kommunen zu den einzelnen Demografietypen finden Sie im Bereich Typisierung."
            links={[{ href: "/demografietypen", label: "Zur Typisierung" }]}
          />
        </>
      )}
    </div>
  );
}
