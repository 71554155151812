/**
 * These hooks mirror the CSS breakpoints as defined in
 * theme\src\css\globals\grid\grid.variables.scss
 * for usage in React/JS
 */

import { useMediaQuery } from "react-responsive";

// From the above scss file:
// $grid-breakpoints: (
//   xs: 0,
//   sm: rem(576),
//   md: rem(768),
//   lg: rem(992),
//   xl: rem(1240),
//   xxl: rem(1400),
// );

const xs = 0;
const sm = 576;
const md = 768;
const lg = 992;
const xl = 1240;
const xxl = 1400;

export const useXsMediaQuery = () => useMediaQuery({ minWidth: xs, maxWidth: sm - 1 });

export const useSmMediaQuery = () => useMediaQuery({ minWidth: sm, maxWidth: md - 1 });

export const useMdMediaQuery = () => useMediaQuery({ minWidth: md, maxWidth: lg - 1 });

export const useLgMediaQuery = () => useMediaQuery({ minWidth: lg, maxWidth: xl - 1 });

export const useXlMediaQuery = () => useMediaQuery({ minWidth: xl, maxWidth: xxl - 1 });

export const useXxlMediaQuery = () => useMediaQuery({ minWidth: xxl });

// Short helper function because we need it in various places
export const useMobileMediaQuery = () => {
  const isXs = useXsMediaQuery();
  const isSm = useSmMediaQuery();
  return isXs || isSm;
};
