import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "./store";
import { IFilter, IRegionFilter, ITagBase } from "../ts/interfaces";
import { RootState } from "./rootReducer";
import { setActivePickerFeature, setActiveRegionOrRegionFilter } from "./uiSlice";

export type IFilterName = keyof IFilter;
interface IFilterState extends IFilter {
  loading: boolean;
  hasErrors: boolean;
  regionFilter: IRegionFilter | null;
  regionFilterInEditing: IRegionFilter | null;
}

const initialState: IFilterState = {
  // State for the filter selection:
  regionTypes: [],
  parents: [],
  populations: [],
  demographicTypes: [],
  gkzs: [],
  // Used for fetching:
  loading: false,
  hasErrors: false,
  regionFilter: null,
  // Used for editing existing filter
  regionFilterInEditing: null
};

interface IFetchRegionFilterArgs {
  apiUrl: string;
  regionTypeIds: Array<number | string>;
  populationIds: Array<number | string>;
  parentIds: Array<number | string>;
  demographicTypeIds: Array<number | string>;
  gkzIds: Array<number | string>;
}

export const fetchRegionFilter = createAsyncThunk(
  "filters/fetchRegionFilter",
  async ({ apiUrl, regionTypeIds, parentIds, populationIds, demographicTypeIds, gkzIds }: IFetchRegionFilterArgs) => {
    const requestBody = {
      regionTypes: regionTypeIds,
      parentIds: parentIds,
      populations: populationIds,
      demographicTypes: demographicTypeIds,
      gkzs: gkzIds
    };
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    });
    const regionFilter = await response.json();

    // TODO Workaround so also a RegionFilter has a uniq ID
    // Until BSTWK-954 is resolved
    regionFilter.id = regionFilter.friendlyUrl;

    return regionFilter;
  }
);

const filtersSlice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    chooseFilterValue(
      state,
      action: PayloadAction<{
        filterName: keyof IFilter;
        value: ITagBase;
      }>
    ) {
      const { filterName, value } = action.payload;
      state[filterName].push(value);
    },
    setFilters(
      state,
      action: PayloadAction<{
        filters: IFilter;
      }>
    ) {
      const { filters } = action.payload;
      return { ...state, ...filters };
    },
    unchooseFilterValue(
      state,
      action: PayloadAction<{
        filterName: keyof IFilter;
        valueId: string | number;
      }>
    ) {
      const { filterName, valueId } = action.payload;
      state[filterName] = state[filterName].filter((v) => v.id !== valueId);
    },
    unsetRegionFilter(state) {
      state.regionFilter = null;
      state.loading = false;
      state.hasErrors = false;
    },
    resetAllFilters(state) {
      return initialState;
    },
    setRegionFilterInEditing(state, action: PayloadAction<{ regionFilter: IRegionFilter | null }>) {
      const { regionFilter } = action.payload;
      state.regionFilterInEditing = regionFilter;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegionFilter.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchRegionFilter.fulfilled, (state, action) => {
      state.regionFilter = action.payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchRegionFilter.rejected, (state, action) => {
      state.loading = false;
      state.hasErrors = true;
    });
  }
});

export const chooseFilterValue = (filterName: keyof IFilter, value: ITagBase): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(filtersSlice.actions.chooseFilterValue({ filterName, value }));
};

export const setFilters = (filters: IFilter): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(filtersSlice.actions.setFilters({ filters }));
};

export const unchooseFilterValue = (filterName: keyof IFilter, valueId: string | number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(filtersSlice.actions.unchooseFilterValue({ filterName, valueId }));
};

export const unsetRegionFilter = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(filtersSlice.actions.unsetRegionFilter());
};

export const resetAllFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(filtersSlice.actions.resetAllFilters());
};

export const setRegionFilterInEditing = (regionFilter: IRegionFilter | null): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(filtersSlice.actions.setRegionFilterInEditing({ regionFilter }));
  if (regionFilter) {
    dispatch(setFilters(regionFilter));
  } else {
    dispatch(resetAllFilters());
  }
};

export const editRegionFilter = (regionFilter: IRegionFilter): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setRegionFilterInEditing(regionFilter));
  dispatch(setActiveRegionOrRegionFilter(regionFilter));
  dispatch(setActivePickerFeature(null));
};

export const stopEditRegionFilter = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setRegionFilterInEditing(null));
  dispatch(setActiveRegionOrRegionFilter(null));
};

export const selectChosenFilterValues = (filterName: keyof IFilter) => (state: RootState) => state.filters[filterName];

export const selectFiltersState = (state: RootState) => state.filters;

export const selectRegionFilterInEditing = (state: RootState) => state.filters.regionFilterInEditing;

export default filtersSlice.reducer;
