import { PathOptions } from "leaflet";
import isNumber from "lodash/isNumber";
import isFinite from "lodash/isFinite";
import { IDataFeature, IMapPickerData } from "../../../ts/interfaces";
import { getWeight } from "../../../utils/map";

const getFillColor = () => {
  // $color-porcelain
  const fallbackColor = "#f2f3f7";

  let fillColor = fallbackColor;

  return fillColor;
};

export const getPickerRegionStyle = (
  feature: IDataFeature | undefined,
  mapPickerData: IMapPickerData | null,
  currentZoom?: number,
  className?: string
): PathOptions => {
  if (!feature || !mapPickerData) {
    return {};
  }

  const weight = isNumber(currentZoom) && isFinite(currentZoom) ? getWeight(currentZoom) : 1;

  const fillColor = getFillColor();

  const style = {
    // $color-french
    color: "#cfcfd1",
    weight: weight,
    fillColor: fillColor,
    fillOpacity: 1,
    className
  };

  return style;
};
