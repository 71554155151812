import { Layer } from "leaflet";
import { MutableRefObject } from "react";
import { IFeature } from "../../../ts/interfaces";

export const addActiveFeatureHandler = <Feature extends IFeature>(
  feature: Feature,
  layer: Layer,
  activeFeatureRef: MutableRefObject<Feature | null>,
  setActiveFeature: (feature: Feature | null) => void
) => {
  layer.on("click", () => {
    if (activeFeatureRef.current?.id === feature.id) {
      setActiveFeature(null);
    } else {
      setActiveFeature(feature);
    }
  });
};
