import classNames from "classnames";
import React, { ReactElement } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import ns from "../../utils/namespace";

interface Props {
  className?: string;
  isCentered?: boolean;
}

export default function Loader({ className, isCentered }: Props): ReactElement {
  return (
    <div className={classNames(ns("loader"), { [ns("loader--centered")]: isCentered }, className)}>
      {/* $color-mariner */}
      <ScaleLoader color="#2664d1" />
    </div>
  );
}
