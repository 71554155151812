import { PathOptions } from "leaflet";
import isNumber from "lodash/isNumber";
import { IDataFeature } from "../../../../ts/interfaces";
import { getWeight } from "../../../../utils/map";

export const getChosenFeatureStyle = (feature: IDataFeature | undefined, currentZoom?: number): PathOptions => {
  const weight = isNumber(currentZoom) && isFinite(currentZoom) ? getWeight(currentZoom) : 1;

  const style = {
    // $color-cerise
    color: "#e803a8",
    weight: weight,
    fillOpacity: 0
  };

  return style;
};
