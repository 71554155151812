import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { AppThunk, AppDispatch } from "./store";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import uniqBy from "lodash/uniqBy";
import { IAppConfig } from "../utils/appSetup";
import { IIndicator, ITopic, IIndicatorOrTopic, IRenderer, IStatisticType, RendererTitle } from "../ts/interfaces";
import { getFullType, getRendererTypes, hasIndicatorsAvailable, isTopic } from "../utils/helpers";
import { trackAction, trackActionType } from "../utils/eTracker";

interface IActiveRendererState {
  activeRenderer: IRenderer;
}

interface IIndicatorOrTopicPayload {
  indicatorOrTopic: IIndicatorOrTopic;
  types: Array<IStatisticType>;
}

interface IIndicatorOrTopicState extends IActiveRendererState {
  activeSelection: IIndicatorOrTopic | null;
  statisticType: IStatisticType | null;
  chosen: Array<IIndicatorOrTopic>;
  lastChosen: Array<IIndicatorOrTopic>;
  lastStatisticType: IStatisticType | null;
  lastActiveSelection: IIndicatorOrTopic | null;
}

// The real "initial" state comes from the preloadedState below
// which is based on the dataConfig from `data-js-wk-config`.
// However, TypeScript requirements force us to still have valid `initialState`
// for `createSlice`. This is just useless dummy data that will never be used at runtime
// See https://github.com/reduxjs/redux-toolkit/issues/873#issuecomment-862147028
const initialState: IIndicatorOrTopicState = {
  activeSelection: null,
  chosen: [],
  activeRenderer: "TABLE",
  statisticType: null,
  // This is only set when the last item in chosen array gets removed.
  // Otherwise it is empty.
  // This way the last chosen (IIndicatorOrTopic) and last active (IIndicator)
  // items can be reset if needed.
  lastChosen: [],
  lastStatisticType: null,
  lastActiveSelection: null
};

export const getPreloadedState = (appConfig: IAppConfig): IIndicatorOrTopicState => {
  return {
    activeSelection: appConfig.activeSelection,
    chosen: appConfig.indicatorsAndTopics,
    activeRenderer: appConfig.renderer,
    statisticType: getFullType(appConfig.indicatorsAndTopics[0]?.type, appConfig.types),
    lastStatisticType: null,
    // This is only set when the last item in chosen array gets removed.
    // Otherwise it is empty.
    // This way the last chosen (IIndicatorOrTopic) and last active (IIndicator)
    // items can be reset if needed.
    lastChosen: [],
    lastActiveSelection: null
  };
};

const indicatorsOrTopicsSlice = createSlice({
  name: "indicatorsOrTopics",
  initialState: initialState,
  reducers: {
    setActiveSelection(state, action: PayloadAction<{ selection: IIndicatorOrTopic }>) {
      const { selection } = action.payload;
      state.activeSelection = selection;
    },
    setActiveRenderer(state, action: PayloadAction<IActiveRendererState>) {
      const { activeRenderer } = action.payload;
      state.activeRenderer = activeRenderer;
    },
    chooseIndicatorOrTopic(state, action: PayloadAction<IIndicatorOrTopicPayload>) {
      const { indicatorOrTopic, types } = action.payload;
      state.chosen.push(indicatorOrTopic);

      state.lastChosen = [];
      state.lastStatisticType = null;
      state.lastActiveSelection = null;

      if (!state.statisticType) {
        state.statisticType = getFullType(indicatorOrTopic.type, types);
      }

      if (isTopic(indicatorOrTopic)) {
        const topic = indicatorOrTopic;

        if (hasIndicatorsAvailable(topic, types)) {
          const firstIndicator = head(topic.indicators) || null;
          state.activeSelection = firstIndicator;
        } else {
          const firstSubTopic = head(topic.topics) || null;
          // in case of no subtopics available, chosen topic seems to be a leaf => activeSelection = topic
          state.activeSelection = firstSubTopic || topic;
        }
      } else {
        const indicator = indicatorOrTopic as IIndicator;
        state.activeSelection = indicator;
      }

      const supportedRenderers = getRendererTypes(indicatorOrTopic.type, types);
      if (!supportedRenderers.includes(state.activeRenderer) && supportedRenderers[0]) {
        state.activeRenderer = supportedRenderers[0];
      }
    },

    unchooseIndicatorOrTopic(state, action: PayloadAction<IIndicatorOrTopicPayload>) {
      const { indicatorOrTopic } = action.payload;

      let newChosen: Array<IIndicatorOrTopic> = [];

      if (isTopic(indicatorOrTopic)) {
        // remove indicatorOrTopic from chosen
        newChosen = state.chosen.filter((t) => t.id !== indicatorOrTopic.id);
      } else {
        // if indicatorOrTopic is indicator, then remove from Topic list (flatten) but also individually
        const currentIndicator = indicatorOrTopic as IIndicator;

        newChosen = state.chosen.reduce((result: Array<IIndicatorOrTopic>, t: IIndicatorOrTopic) => {
          if (isTopic(t) && currentIndicator.topics.includes(t.name)) {
            const topic = t as ITopic;
            const indicators = topic.indicators.filter((i) => i.id !== currentIndicator.id);
            result = result.concat(indicators);
          } else {
            const indicator = t as IIndicator;
            if (indicator.id !== currentIndicator.id) {
              result.push(indicator);
            }
          }
          return result;
        }, [] as Array<IIndicator>);
      }

      const uniqNewChosen = uniqBy(newChosen, "id");

      if (isEmpty(uniqNewChosen)) {
        state.lastChosen = state.chosen;
        state.lastStatisticType = state.statisticType;
        state.statisticType = null;
        state.lastActiveSelection = state.activeSelection;
      } else {
        state.lastChosen = [];
        state.lastStatisticType = null;
        state.lastActiveSelection = null;
      }
      state.activeSelection = isEmpty(newChosen)
        ? null
        : getNewActive(indicatorOrTopic, state.activeSelection, state.chosen);
      state.chosen = uniqNewChosen;
    },

    unchooseAllIndicatorsOrTopics(state) {
      state.lastChosen = state.chosen;
      state.chosen = [];

      state.lastStatisticType = state.statisticType;
      state.statisticType = null;

      state.lastActiveSelection = state.activeSelection;
      state.activeSelection = null;
    },

    resetLastIndicatorOrTopic(state) {
      state.chosen = state.lastChosen;
      state.lastChosen = [];

      state.statisticType = state.lastStatisticType;
      state.lastStatisticType = null;

      state.activeSelection = state.lastActiveSelection;
      state.lastActiveSelection = null;
    }
  }
});

const getFlattenedIndicators = (indicatorsOrTopicsChosen: Array<IIndicatorOrTopic>): Array<IIndicator> => {
  const chosenIndicators = indicatorsOrTopicsChosen.reduce(
    (result: Array<IIndicator>, indicatorOrTopic: IIndicatorOrTopic) => {
      if (isTopic(indicatorOrTopic)) {
        const topic = indicatorOrTopic;
        result = result.concat(topic.indicators);
      } else {
        const indicator = indicatorOrTopic;
        result.push(indicator);
      }
      return result;
    },
    [] as Array<IIndicator>
  );

  const uniqChosenIndicators = uniqBy(chosenIndicators, "id");
  return uniqChosenIndicators;
};

const getFlattenedLeafTopics = (indicatorsOrTopicsChosen: Array<IIndicatorOrTopic>): Array<ITopic> => {
  const chosenSubTopics = indicatorsOrTopicsChosen.reduce(
    (result: Array<ITopic>, indicatorOrTopic: IIndicatorOrTopic) => {
      if (isTopic(indicatorOrTopic) && !isEmpty(indicatorOrTopic.topics)) {
        const topic = indicatorOrTopic;
        result = result.concat(topic.topics);
      } else if (isTopic(indicatorOrTopic)) {
        const subTopic = indicatorOrTopic;
        result.push(subTopic);
      }
      return result;
    },
    [] as Array<ITopic>
  );

  const uniqChosenSubTopics = uniqBy(chosenSubTopics, "id");
  return uniqChosenSubTopics;
};

const isLastInArray = (currentIndicators: Array<IIndicator>, currentIndicator: IIndicatorOrTopic): boolean => {
  const lastIndicator = last(currentIndicators);

  if (lastIndicator) {
    return lastIndicator.id === currentIndicator.id;
  }
  return false;
};

/**
 * Logic for getting the index of the next item in chosen array
 *
 * @param chosenItems - the list of chosen indicators or topics (state.chosen)
 * @param topicIdxInChosen - the index of the unchosen indicator/topic in state.chosen
 *
 * @returns the index of new chosen indicator/topic in state.chosen
 */
const getNextItemIdx = (chosenItems: Array<IIndicatorOrTopic>, topicIdxInChosen: number) => {
  if (chosenItems.length === 1) {
    return topicIdxInChosen;
  }

  if (topicIdxInChosen === 0) {
    return topicIdxInChosen + 1;
  } else {
    return topicIdxInChosen - 1;
  }
};

/**
 * If the current indicator/topic that is going to be unchosen/deleted is/has also the current active one, then we need to pick a new active selection.
 *
 * @param currentUnchosenItem - the currently Indicator or Topic that is unchosen
 * @param activeIndicator - the currently active indicator
 * @param chosenItems - the list of chosen indicators or topics (state.chosen)
 *
 * @returns the indicator that should be the new active indicator or null if none should be active
 */
const getNewActive = (
  currentUnchosenItem: IIndicatorOrTopic,
  active: IIndicatorOrTopic | null,
  chosenItems: Array<IIndicatorOrTopic>
): IIndicatorOrTopic | null => {
  if (!active) {
    return null;
  }

  if (isTopic(currentUnchosenItem)) {
    const topic = currentUnchosenItem as ITopic;

    const topicHasActiveIndicator = !isTopic(active) && topic.indicators.some((i) => i.id === active?.id);

    const topicHasActiveTopic = topic.topics.some((t) => t.id === active?.id);

    if (!topicHasActiveIndicator && !topicHasActiveTopic) {
      // We only need to choose a new active indicator or topic if the currently unchosen topic includes the
      // current active indicator or topic. otherwise we can simply return the old active selection
      return active;
    } else {
      // Check index of removed topic in state.chosen
      const topicIdxInChosen = chosenItems.findIndex((item) => isTopic(item) && item.id === topic.id);

      const nextItemIdx = getNextItemIdx(chosenItems, topicIdxInChosen);

      const nextItem = chosenItems[nextItemIdx];

      // Check if new chosen item is a topic or indicator
      if (isTopic(nextItem)) {
        // If new chosen item is a topic return it's first indicator or topic as active
        const nextTopic = nextItem as ITopic;
        return isEmpty(nextTopic.indicators)
          ? isEmpty(nextTopic.topics)
            ? null
            : nextTopic.topics[0]
          : nextTopic.indicators[0];
      }
      // If new chosen item is indicator simply return it as active
      return nextItem as IIndicator;
    }
  } else {
    // We need another heuristic if the `currentUnchosenItem` is an indicator instead of topic
    if (isTopic(active) || currentUnchosenItem.id !== active.id) {
      // We only need to choose another new active indicator if the currently active one gets unchosen
      // otherwise we can simply return the old indicator
      return active;
    } else {
      const flattenedIndicators = getFlattenedIndicators(chosenItems);
      const activeIndicatorIdx = flattenedIndicators.findIndex((element) => element.id === active.id);

      if (flattenedIndicators.length <= 1) {
        // when there is only one indicator left in the list,
        // the active indicator will definitely be unset because there is no indicator to fall back to.
        return null;
      }

      if (isLastInArray(flattenedIndicators, active)) {
        return flattenedIndicators[activeIndicatorIdx - 1];
      }
      return flattenedIndicators[activeIndicatorIdx + 1];
    }
  }
};

export const setActiveIndicator = (indicator: IIndicator): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(indicatorsOrTopicsSlice.actions.setActiveSelection({ selection: indicator }));
};

export const setActiveSelection = (selection: IIndicatorOrTopic): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(indicatorsOrTopicsSlice.actions.setActiveSelection({ selection: selection }));
};

export const setActiveRenderer = (activeRenderer: IRenderer): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    indicatorsOrTopicsSlice.actions.setActiveRenderer({
      activeRenderer: activeRenderer
    })
  );
  trackAction(trackActionType.RENDERER_SELECTION, RendererTitle[activeRenderer]);
};

export const chooseIndicatorOrTopic = (
  indicatorOrTopic: IIndicatorOrTopic,
  types: Array<IStatisticType>
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    indicatorsOrTopicsSlice.actions.chooseIndicatorOrTopic({
      indicatorOrTopic: indicatorOrTopic,
      types: types
    })
  );

  trackAction(trackActionType.TOPIC_SELECTION, indicatorOrTopic.name);
};

export const unchooseIndicatorOrTopic = (
  indicatorOrTopic: IIndicatorOrTopic,
  types: Array<IStatisticType>
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    indicatorsOrTopicsSlice.actions.unchooseIndicatorOrTopic({
      indicatorOrTopic: indicatorOrTopic,
      types
    })
  );
};

export const resetLastIndicatorOrTopic = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(indicatorsOrTopicsSlice.actions.resetLastIndicatorOrTopic());
};

export const unchooseAllIndicatorsOrTopics = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(indicatorsOrTopicsSlice.actions.unchooseAllIndicatorsOrTopics());
};

export const selectActiveSelection = (state: RootState) => state.indicatorsOrTopics.activeSelection;

export const selectActiveIndicator = (state: RootState) =>
  state.indicatorsOrTopics.activeSelection == null || isTopic(state.indicatorsOrTopics.activeSelection)
    ? null
    : (state.indicatorsOrTopics.activeSelection as IIndicator);

export const selectActiveTopic = (state: RootState) =>
  state.indicatorsOrTopics.activeSelection == null || !isTopic(state.indicatorsOrTopics.activeSelection)
    ? null
    : (state.indicatorsOrTopics.activeSelection as ITopic);

export const selectActiveRenderer = (state: RootState) => state.indicatorsOrTopics.activeRenderer;

export const selectChosenIndicatorsOrTopics = (state: RootState) =>
  state.indicatorsOrTopics.chosen as Array<IIndicator>;

export const selectUsedIndicatorsOrTopics = (state: RootState) =>
  isEmpty(state.indicatorsOrTopics.chosen) ? state.indicatorsOrTopics.lastChosen : state.indicatorsOrTopics.chosen;

export const selectStatisticType = (state: RootState) => state.indicatorsOrTopics.statisticType;

export const selectHasStatisticType = (state: RootState) => (state.indicatorsOrTopics.statisticType ? true : false);

export const selectAreIndicatorsAvailable = (state: RootState) =>
  state.indicatorsOrTopics.statisticType?.indicatorsAvailable;

export const selectComparisonYear = (state: RootState) => state.indicatorsOrTopics.activeSelection?.comparisonYear;

export const selectComparisonYearTitle = (state: RootState) =>
  state.indicatorsOrTopics.activeSelection?.comparisonYearTitle;

export const selectIsDemographicTypes = (state: RootState) =>
  state.indicatorsOrTopics.statisticType?.type === "DEMOGRAPHIC_TYPES";

export const createIndicatorsOrTopicsMemoizedSelectors = () => {
  return {
    /**
     * Only return flattened indicators from list of topics and indicators
     * Using a memoized selector, because `reduce` returns a new reference
     * for each result (immutable)
     */
    selectChosenIndicators: createSelector(
      (state: RootState) => state.indicatorsOrTopics.chosen,
      (indicatorsOrTopicsChosen) => {
        const flattenedIndicators = getFlattenedIndicators(indicatorsOrTopicsChosen);

        return flattenedIndicators;
      }
    ),
    selectChosenTopics: createSelector(
      (state: RootState) => state.indicatorsOrTopics.chosen,
      (indicatorsOrTopicsChosen) => {
        const topics = indicatorsOrTopicsChosen.filter((indicatorOrTopic) => isTopic(indicatorOrTopic));

        return topics;
      }
    ),
    selectChosenLeafTopics: createSelector(
      (state: RootState) => state.indicatorsOrTopics.chosen,
      (indicatorsOrTopicsChosen) => {
        const flattenedSubTopics = getFlattenedLeafTopics(indicatorsOrTopicsChosen);

        return flattenedSubTopics;
      }
    )
  };
};

export default indicatorsOrTopicsSlice.reducer;
