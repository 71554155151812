import classNames from "classnames";
import { ReactElement } from "react";
import ns from "../../../utils/namespace";
import { ILegendItemBase } from "./Legend";

export interface IStaticLegendItemProps extends ILegendItemBase {
  color?: string;
}

const COMPONENT_NAME = "static-legend-item";

export default function StaticLegendItem({ label, color, inactive }: IStaticLegendItemProps): ReactElement {
  return (
    <span
      className={classNames(ns(COMPONENT_NAME), {
        [`${ns(COMPONENT_NAME)}--inactive`]: inactive
      })}
    >
      <span
        className={ns(`${COMPONENT_NAME}__marker`)}
        // we use CSS-in-JS here because colors need to be defined
        // in JS anyway for rendering of the StatisticsMap
        style={{ backgroundColor: color }}
      />
      {label}
    </span>
  );
}
