import ReactDOMServer from "react-dom/server";
import { useCallback } from "react";
import { Layer, Map, PathOptions } from "leaflet";

import { IDataFeature } from "../../../../ts/interfaces";
import ns from "../../../../utils/namespace";
import { getTooltipContent } from "./getTooltipContent";
import { useTooltipContentData } from "./useTooltipContentData";
import { getTooltipDirection } from "../../../../utils/map";
import classnames from "classnames";

export const useAddToolip = (
  mapData: any,
  valueIndex?: number,
  isDemographyType?: boolean,
  regionStyleCallback?: (feature: IDataFeature | undefined, isHovered?: boolean) => PathOptions
) => {
  const tooltipContentData = useTooltipContentData(mapData, valueIndex);

  return useCallback(
    (feature: IDataFeature, layer: Layer, map: Map) => {
      const region = feature.properties.name;

      const tooltipContent = (
        <div className={classnames(ns("chart__tooltip-content"), ns("region-tooltip"))}>
          <span className={ns("region-tooltip__name")}>{region}</span>
          {getTooltipContent(feature, valueIndex, isDemographyType, tooltipContentData, ns("region-tooltip"))}
        </div>
      );
      const renderedTooltipContent = ReactDOMServer.renderToStaticMarkup(tooltipContent);
      const direction = getTooltipDirection(map, feature);

      layer.bindTooltip(renderedTooltipContent, {
        direction
      });
      if (regionStyleCallback) {
        layer.on("tooltipopen", (e) => {
          e.target.setStyle(regionStyleCallback(feature, true));
        });
        layer.on("tooltipclose", (e) => {
          e.target.setStyle(regionStyleCallback(feature, false));
        });
      }
    },
    [isDemographyType, regionStyleCallback, tooltipContentData, valueIndex]
  );
};
