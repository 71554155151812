import classNames from "classnames";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setModalIsOpen } from "../../../redux/uiSlice";
import ns from "../../../utils/namespace";
import { useGetSvgPath } from "../../../utils/svgPath";
import Icon from "../../atoms/Icon";

interface Props {
  className?: string;
  which: "regions" | "indicatorsOrTopics";
  label: string;
}

export const useOpenModalItem = ({ className, which, label }: Props) => {
  const dispatch = useDispatch();
  const plusIconPath = useGetSvgPath("#plus-circle");

  const result = useMemo(
    () => ({
      content: (
        <span className={classNames(ns("more-item"), className)}>
          <Icon className={ns("more-item__icon")} width={16} height={16}>
            <use xlinkHref={plusIconPath}></use>
          </Icon>
          <span className={ns("more-item__label")}>{label}</span>
        </span>
      ),
      onSelect: () => dispatch(setModalIsOpen(which, "Drop-Down"))
    }),
    [className, dispatch, label, plusIconPath, which]
  );

  return result;
};
