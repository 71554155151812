import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import ns from "../../../utils/namespace";
import Button from "./Button";
import Icon from "../../atoms/Icon";
import { setModalIsOpen } from "../../../redux/uiSlice";
import { useGetSvgPath } from "../../../utils/svgPath";

interface Props {
  className?: string;
  which: "regions" | "indicatorsOrTopics";
  label: string;
  srOnly?: boolean;
}

export default function ModalButton({ className, which, label, srOnly = true }: Props): ReactElement {
  const dispatch = useDispatch();
  return (
    <Button
      className={className}
      label={label}
      srOnly={srOnly}
      handleClick={() => dispatch(setModalIsOpen(which, srOnly ? "Plus-Zeichen" : label))}
      iconBefore={
        <Icon className={ns("btn__icon")} width={12} height={13}>
          <use xlinkHref={useGetSvgPath("#plus")}></use>
        </Icon>
      }
    />
  );
}
