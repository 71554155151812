import { createIndicatorsOrTopicsMemoizedSelectors } from "./indicatorsOrTopicsSlice";
import { createMapDataMemoizedSelectors } from "./mapDataSlice";
import { createMapPickerDataMemoizedSelectors } from "./mapPickerDataSlice";
import { createRegionsOrRegionFiltersMemoizedSelectors } from "./regionsOrRegionFiltersSlice";

/**
 * We must create and store memoized selectors for each app instance on its own,
 * else they will not work correctly, because memoized value would be used for all instances.
 * This dictionary holds for each appId (the key) the related created memoized selectors.
 */
const memoizedSelectors: {
  [key: string]: ReturnType<typeof createMemoizedSelectors>;
} = {};

/**
 * Calls createMemoizedSelectors for all slices providing
 * memoizedSelectors and stores resulting selectors
 * structured by slice-name in an object.
 * @returns object with freshly created memoized selectors of all slices (providing such selectors)
 */
const createMemoizedSelectors = () => ({
  indicatorsOrTopics: createIndicatorsOrTopicsMemoizedSelectors(),
  regionsOrRegionFilters: createRegionsOrRegionFiltersMemoizedSelectors(),
  mapData: createMapDataMemoizedSelectors(),
  mapPickerData: createMapPickerDataMemoizedSelectors()
});

/**
 * Creates fresh memoized selectors for given appId.
 * @param appId the id of the app the memoized selectors shall be created for
 */
export const initMemoizedSelectors = (appId: string) => {
  if (memoizedSelectors[appId]) {
    console.warn(`Created already memoized selectors for given appId: ${appId}`);
    return;
  }
  memoizedSelectors[appId] = createMemoizedSelectors();
};

/**
 * Returns created memoized selectors for given appId.
 */
export const getMemoizedSelectors = (appId: string) => {
  return memoizedSelectors[appId];
};
