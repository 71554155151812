import classNames from "classnames";
import { geoJSON } from "leaflet";
import React, { PropsWithChildren, ReactElement } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { selectChosenRegionsOrRegionFilters } from "../../redux/regionsOrRegionFiltersSlice";
import { IFeature } from "../../ts/interfaces";
import { reduceFeature } from "../../utils/map";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import Button from "./button/Button";

export interface RegionPopupProps<Feature extends IFeature> {
  feature: Feature;
  linkRegion?: boolean;
  className?: string;
  onClose: () => void;
  onChoose: () => void;
  onUnchoose: () => void;
}

export default function RegionPopup<Feature extends IFeature>({
  feature,
  linkRegion,
  className,
  onClose: handleClose,
  onChoose: handleChoose,
  onUnchoose: handleUnchoose,
  children
}: PropsWithChildren<RegionPopupProps<Feature>>): ReactElement {
  const chosenRegionsOrRegionsFilters = useSelector(selectChosenRegionsOrRegionFilters);

  const clickOutsideRef = useOnclickOutside(
    () => {
      handleClose();
    },
    {
      // Ignore clicks inside of leaflet container (automatic popup closing is already handled by leaflet itself)
      ignoreClass: "leaflet-container"
    }
  );

  const closeIcon = (
    <Icon className={ns("btn__icon")} width={24} height={24} fill="none">
      <use xlinkHref={useGetSvgPath("#close")}></use>
    </Icon>
  );

  const { chooseUnchooseLabel, handleChooseUnchooseClick, chooseUnchooseIconId } = feature.properties.selected
    ? {
        chooseUnchooseLabel: "Auswahl entfernen",
        handleChooseUnchooseClick: handleUnchoose,
        chooseUnchooseIconId: "#minus-circle"
      }
    : {
        chooseUnchooseLabel: "Kommune hinzufügen",
        handleChooseUnchooseClick: handleChoose,
        chooseUnchooseIconId: "#plus-circle"
      };

  const chooseUnchooseIcon = (
    <Icon className={ns("region-popup__choose-unchoose-btn-icon")} width={16} height={16}>
      <use xlinkHref={useGetSvgPath(chooseUnchooseIconId)}></use>
    </Icon>
  );

  // only regions directly chosen and not coming from a region filter can be
  // removed via their RegionPopup (at the moment)
  const shallRenderChooseUnchooseButton = feature.properties.selected
    ? chosenRegionsOrRegionsFilters.some((elem) => elem.id === feature.id)
    : true;

  const { name, friendlyUrl } = feature.properties;

  // we reduce the feature such that getBounds().getCenter()
  // down below returns the center of the first contained
  // polygon and not of all contained polygons
  const reducedFeature = reduceFeature(feature);

  return (
    <Popup
      position={geoJSON(reducedFeature).getBounds().getCenter()}
      closeButton={false}
      onClose={handleClose}
      // autoClose set to false to fix interferences with RegionMarker-popups
      autoClose={false}
      className={classNames(ns("region-popup"), className)}
    >
      <div className={ns("region-popup__content")} ref={clickOutsideRef}>
        <Button
          label="Schließen"
          className={ns("btn--icon region-popup__close-btn")}
          srOnly
          icon={closeIcon}
          handleClick={handleClose}
        />
        <div className={ns("region-popup__info")}>
          {linkRegion ? (
            <a
              href={`/kommunen/${friendlyUrl}`}
              target="_blank"
              className={classNames(ns("region-popup__name"), ns("link"))}
              rel="noreferrer"
            >
              {name}
            </a>
          ) : (
            <span className={ns("region-popup__name")}>{name}</span>
          )}
          {children}
        </div>
        {shallRenderChooseUnchooseButton && (
          <Button
            className={ns(
              "region-popup__choose-unchoose-btn",
              feature.properties.selected ? "btn--secondary" : "btn--primary"
            )}
            iconBefore={chooseUnchooseIcon}
            handleClick={handleChooseUnchooseClick}
            label={chooseUnchooseLabel}
          />
        )}
      </div>
    </Popup>
  );
}
