import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import { selectActiveRenderer, selectStatisticType } from "../../../redux/indicatorsOrTopicsSlice";
import { setIncludeComparisonYear } from "../../../redux/uiSlice";
import { isMiniApp } from "../../../utils/appSetup";
import ns from "../../../utils/namespace";
import ChartWithYearPicker from "../visualizations/chart/ChartWithYearPicker";
import ChartWithYearSlider from "../visualizations/chart/ChartWithYearSlider";
import StatisticsMap from "../visualizations/map/StatisticsMap";
import StatisticsTable from "../visualizations/StatisticsTable";
import { StatisticType } from "../../../ts/interfaces";
import GeographicMigrationTable from "../visualizations/GeographicMigrationTable";

export default function VisualizationArea(): ReactElement {
  const dispatch = useDispatch();
  const activeRenderer = useSelector(selectActiveRenderer);
  const statisticType = useSelector(selectStatisticType);

  const appConfig = useSelector(selectAppConfig);
  const shallRenderYearChooser = isMiniApp(appConfig) ? appConfig.hasYearSlider || false : true;

  useEffect(() => {
    // On unmount we need to unset the include comparison toggle
    // So it is unset when switching from Bevölkerungspyramide <=> Alterstrukturgrafik
    return () => {
      dispatch(setIncludeComparisonYear(false));
    };
  }, [dispatch, activeRenderer]);

  let component = null;
  switch (activeRenderer) {
    case "BAR_CHART":
    case "LINE_CHART":
    case "MIGRATION_CHART":
    case "RADAR_CHART":
      if (statisticType?.type === StatisticType.GeographicMigration) {
        component = <ChartWithYearPicker shallRenderPicker={shallRenderYearChooser} />;
      } else {
        component = <ChartWithYearSlider shallRenderSlider={shallRenderYearChooser} />;
      }
      break;
    case "DISTRIBUTION_CHART":
      component = <ChartWithYearPicker shallRenderPicker={shallRenderYearChooser} />;
      break;
    case "PYRAMID_CHART":
      component = <ChartWithYearPicker shallRenderPicker={shallRenderYearChooser} shallRenderAside={false} />;
      break;
    case "TABLE":
      if (statisticType?.type === StatisticType.GeographicMigration) {
        component = <GeographicMigrationTable />;
      } else {
        component = <StatisticsTable />;
      }
      break;
    case "MAP":
      component = <StatisticsMap shallRenderYearPicker={shallRenderYearChooser} />;
      break;
    default:
      component = <StatisticsTable />;
  }

  return <div className={ns("ribbon ribbon--prime")}>{component}</div>;
}
