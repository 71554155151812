import isNumber from "lodash/isNumber";
import { useMemo } from "react";
import { IIndicator } from "../../../../ts/interfaces";

interface TooltipData {
  indicator: IIndicator;
  year: number;
}

export const useTooltipContentData = (mapData: any, currentYearIndex?: number): TooltipData | undefined => {
  const indicator = mapData?.indicator;
  const year = currentYearIndex !== undefined && mapData?.years[currentYearIndex];

  return useMemo(
    () =>
      indicator && isNumber(year)
        ? {
            indicator,
            year
          }
        : undefined,
    [indicator, year]
  );
};
