import { useSelector } from "react-redux";
import { selectAppConfig } from "../redux/appConfigSlice";

/**
 * Prefix the Icon string with the URL as provided by the system
 *
 * @param {string} iconHashId - string like "#close"
 * @returns {string} - url to svg
 */
export const useGetSvgPath = (iconHashId: string): string => {
  const appConfig = useSelector(selectAppConfig);
  return `${appConfig.urls.svgSprite}${iconHashId}`;
};

// Workaround function. Same as above function, but not having a parameter so
// the hook is usable for loops. See its usages
export const useGetBaseSvgPath = (): string => {
  const appConfig = useSelector(selectAppConfig);
  return appConfig.urls.svgSprite;
};
