import React, { useEffect } from "react";
import { fetchDemographicType, selectDemographicType } from "../../redux/demographicTypeSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../redux/appConfigSlice";
import FurtherInfoBox from "./FurtherInfoBox";
import { IDemographicType } from "../../ts/interfaces";

interface Props {
  demographicTypeNumber: number;
}

const getLinks = (
  demographicType: IDemographicType
): Array<{
  href: string;
  label: string;
}> => {
  const links = [];
  if (demographicType.descriptionFile) {
    links.push({
      href: demographicType.descriptionFile,
      label: `Handlungsempfehlung für Demografietyp ${demographicType.number} (PDF)`
    });
  }
  if (demographicType.regionsFile) {
    const type = demographicType.regionsFile.replace(/.*\./, "").toUpperCase();
    links.push({
      href: demographicType.regionsFile,
      label: `Übersicht aller Kommunen im Demografietyp ${demographicType.number} (${type})`
    });
  }
  return links;
};

export default function DemograpicTypeInfoBox({ demographicTypeNumber }: Props) {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const { demographicType, loading, hasErrors } = useSelector(selectDemographicType);

  const apiUrl = appConfig.urls.apiDemographicTypes;

  useEffect(() => {
    if (apiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch demographicType, apiUrl is: ", apiUrl);
      return;
    }
    dispatch(fetchDemographicType({ apiUrl, demographicTypeNumber }));
  }, [dispatch, apiUrl, demographicTypeNumber]);

  if (loading || hasErrors || !demographicType) {
    return null;
  }

  const links = getLinks(demographicType);

  return (
    <FurtherInfoBox title={`Demografietyp ${demographicType.number}`} text={demographicType.title} links={links} />
  );
}
