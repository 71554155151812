import isNumber from "lodash/isNumber";
import { IIndicatorOrTopic, IStatisticType, IRenderer, OutlineType, IRegionOrIRegionFilter } from "../ts/interfaces";
import { getAppRootElems, getMiniAppRootElems } from "./appRoot";

export enum AppType {
  Full = "Full",
  Mini = "Mini"
}

// The config as passed in `data-js-wk-config`
interface IAppConfigBase {
  appType: AppType;
  indicatorsAndTopics: Array<IIndicatorOrTopic>;
  activeSelection: IIndicatorOrTopic;
  regionsAndRegionFilters: Array<IRegionOrIRegionFilter>;
  includeComparisonYear?: boolean;
  compareGenerations?: boolean;
  years: Array<number>;
  renderer: IRenderer;
  mapOutline: OutlineType;
  types: Array<IStatisticType>;
  topRegionsCount?: number;
  lowRegionsCount?: number;
  highlightedElements?: Array<String>;
  urls: {
    apiIndicatorAutocompletion: string;
    apiIndicatorOrTopicAutocompletion: string;
    apiMapBbox: string;
    apiMapData: string;
    apiRegionAutocompletion: string;
    apiRegionFilter: string;
    apiStatisticsData: string;
    apiTopicAutocompletion: string;
    apiTopicSets: string;
    apiDemographicTypes: string;
    apiDemographicTypeAutocompletion: string;
    apiGkzAutocompletion: string;
    apiPopulationRangeAutocompletion: string;
    apiRegionTypeAutocompletion: string;
    svgSprite: string;
  };
}

interface IFullAppConfig extends IAppConfigBase {}

interface IMiniAppConfig extends IAppConfigBase {
  title: string;
  hasYearSlider: boolean;
  views: Array<{
    renderer: IRenderer;
    url: string;
  }>;
}

export type IAppConfig = IFullAppConfig | IMiniAppConfig;

/**
 * Return app config / settings as json
 *
 * @param {node} appRoot - the DOM node as returned from getAppRootElem
 *
 * @returns {object} - the app settings
 */
const getAppConfig = (appRoot: Element): IAppConfig | null => {
  const rawappConfig = appRoot?.getAttribute("data-js-wk-config");
  if (!rawappConfig) {
    return null;
  }
  const config: IAppConfig = JSON.parse(decodeURIComponent(rawappConfig));

  // TODO Workaround so also a RegionFilter has a uniq ID
  // Until BSTWK-954 is resolved
  config.regionsAndRegionFilters = config.regionsAndRegionFilters.map((regionOrFilter) => {
    if (!isNumber(regionOrFilter.id)) {
      regionOrFilter.id = regionOrFilter.friendlyUrl;
    }
    return regionOrFilter;
  });

  return config;
};

// an object to hold app info because we can have multiple apps on a single page
// the config is then used in redux' state
export interface IAppSetup {
  rootElement: Element;
  type: AppType;
  appConfig: IAppConfig;
}

const isAppOfType = (config: IAppConfig, type: AppType) => {
  return config.appType === type;
};

export const isFullApp = (config: IAppConfig): config is IFullAppConfig => {
  return isAppOfType(config, AppType.Full);
};

export const isMiniApp = (config: IAppConfig): config is IMiniAppConfig => {
  return isAppOfType(config, AppType.Mini);
};

const getAppSetups = (): Array<IAppSetup> => {
  const fullAppRoots = getAppRootElems();
  const miniAppRoots = getMiniAppRootElems();
  const allApps: Array<IAppSetup> = [];

  fullAppRoots.forEach((elem) => {
    const config = getAppConfig(elem);
    if (config) {
      allApps.push({
        rootElement: elem,
        type: AppType.Full,
        appConfig: { ...config, appType: AppType.Full }
      });
    }
  });

  miniAppRoots.forEach((elem) => {
    const config = getAppConfig(elem);
    if (config) {
      allApps.push({
        rootElement: elem,
        type: AppType.Mini,
        appConfig: { ...config, appType: AppType.Mini }
      });
    }
  });

  return allApps;
};

// we have one array of apps, only once initialized that hold all app config data
const appSetups = getAppSetups();

export default appSetups;
