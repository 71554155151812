import classNames from "classnames";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveLayer, setActiveLayer } from "../../redux/uiSlice";
import { LayerType } from "../../ts/interfaces";
import ns from "../../utils/namespace";
import MapSwitcher from "./MapSwitcher";

const layerOptions = [
  {
    value: LayerType.State,
    label: "Bundesländer",
    iconId: "#states"
  },
  {
    value: LayerType.District,
    label: "Landkreise",
    iconId: "#counties"
  },
  {
    value: LayerType.Commune,
    label: "Gemeinden",
    iconId: "#municipality"
  }
];

interface Props {
  className?: string;
}

export default function LayerSwitcher({ className }: Props): ReactElement {
  const dispatch = useDispatch();

  const activeLayer = useSelector(selectActiveLayer);

  const handleChange = (value: LayerType) => {
    dispatch(setActiveLayer(value));
  };

  return (
    <MapSwitcher
      title="Ebene wechseln"
      items={layerOptions}
      activeItem={activeLayer}
      onItemChange={handleChange}
      className={classNames(ns("layer-switcher"), className)}
    />
  );
}
