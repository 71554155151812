import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import { selectActiveSelection } from "../../../../redux/indicatorsOrTopicsSlice";
import { getAvailableYears } from "../../../../utils/helpers";

export const useAvailableYears = () => {
  const activeSelection = useSelector(selectActiveSelection);
  const appConfig = useSelector(selectAppConfig);

  return useMemo(() => (activeSelection ? getAvailableYears([activeSelection], appConfig.types) : []), [
    activeSelection,
    appConfig.types
  ]);
};
