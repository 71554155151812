import React, { ReactElement, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, selectModalIsOpen, selectWhichModalIsOpen } from "../../../redux/uiSlice";
import { useMobileMediaQuery } from "../../../utils/reactResponsiveHooks";
import Modal from "../../molecules/Modal";
import SelectorOverlay from "../SelectorOverlay";
import RegionContainer from "../tag-container/RegionContainer";
import IndicatorOrTopicContainer from "../tag-container/IndicatorOrTopicContainer";
import TopicSets from "../TopicSets";
import MapPicker from "../map-picker/MapPicker";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import { selectFiltersState } from "../../../redux/filtersSlice";
import { selectChosenRegionsOrRegionFilters } from "../../../redux/regionsOrRegionFiltersSlice";
import { getFlattenedRegions } from "../../../utils/helpers";

export default function ModalArea(): ReactElement {
  const modalIsOpen = useSelector(selectModalIsOpen);
  const whichModalIsOpen = useSelector(selectWhichModalIsOpen);
  const isMobile = useMobileMediaQuery();
  const dispatch = useDispatch();

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;
  const chosenRegions = useSelector(selectChosenRegions);
  const chosenRegionsOrRegionFilters = useSelector(selectChosenRegionsOrRegionFilters);
  const { regionFilter, regionFilterInEditing } = useSelector(selectFiltersState);
  const chosenWithAddableRegions = useMemo(
    () =>
      regionFilter
        ? getFlattenedRegions(
            chosenRegionsOrRegionFilters
              .filter(
                (regionOrRegionFilter) => !regionFilterInEditing || regionOrRegionFilter.id !== regionFilterInEditing.id
              )
              .concat(regionFilter.regions)
          )
        : chosenRegions,
    [chosenRegionsOrRegionFilters, regionFilter, regionFilterInEditing, chosenRegions]
  );
  const regionsTotalAddableCount = chosenWithAddableRegions.length;
  const regionsConfirmLabel = `${regionsTotalAddableCount} Kommune${
    regionsTotalAddableCount !== 1 ? "n" : ""
  } anzeigen`;

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={() => {
        dispatch(closeModal());
      }}
      contentLabel={
        whichModalIsOpen === "regions"
          ? "In dieser Ansicht weitere Kommunen auswählen"
          : "In der Ansicht weitere Indikatoren/Themen auswählen"
      }
    >
      {whichModalIsOpen === "regions" ? (
        <SelectorOverlay
          isMobile={isMobile}
          leftLabel="Liste"
          rightLabel="Karte"
          left={<RegionContainer isInOverlay={true} />}
          right={<MapPicker />}
          confirmLabel={regionsConfirmLabel}
          classNameModifier="regions"
        />
      ) : (
        <SelectorOverlay
          isMobile={isMobile}
          leftLabel="Liste"
          rightLabel="Sets"
          left={<IndicatorOrTopicContainer isInOverlay={true} />}
          right={<TopicSets />}
        />
      )}
    </Modal>
  );
}
