import { createMapBboxNonConcurrentAsyncThunks } from "./mapBboxSlice";
import { createMapDataNonConcurrentAsyncThunks } from "./mapDataSlice";

/**
 * We must create and store non concurrent async thunks for each app instance on its own,
 * else they will not work as expected, because async thunks of different instances would cancel
 * each other (if they are executed at the same time).
 * This dictionary holds for each appId (the key) the related created non concurrent async thunks.
 */
const nonConcurrentAsyncThunks: {
  [key: string]: ReturnType<typeof createNonConcurrentAsyncThunks>;
} = {};

/**
 * Calls createNonConcurrentAsyncThunks for all slices providing
 * non concurrent async thunks and stores resulting thunks
 * structured by slice-name in an object.
 * @returns object with freshly created non concurrent async thunks of all slices (providing such thunks)
 */
const createNonConcurrentAsyncThunks = () => ({
  mapData: createMapDataNonConcurrentAsyncThunks(),
  mapBbox: createMapBboxNonConcurrentAsyncThunks()
});

/**
 * Creates fresh non concurrent async thunks for given appId.
 * @param appId the id of the app the non concurrent async thunks shall be created for
 */
export const initNonConcurrentAsyncThunks = (appId: string) => {
  if (nonConcurrentAsyncThunks[appId]) {
    console.warn(`Created already non concurrent async thunks for given appId: ${appId}`);
    return nonConcurrentAsyncThunks[appId];
  }
  const createdNonConcurrentAsyncThunks = createNonConcurrentAsyncThunks();
  nonConcurrentAsyncThunks[appId] = createdNonConcurrentAsyncThunks;
  return createdNonConcurrentAsyncThunks;
};

/**
 * Returns created non concurrent async thunks for given appId.
 */
export const getNonConcurrentAsyncThunks = (appId: string) => {
  return nonConcurrentAsyncThunks[appId];
};
