import { ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import { createSliderWithTooltip, Range } from "rc-slider";
import ns from "../../utils/namespace";
import head from "lodash/head";
import last from "lodash/last";
import "rc-slider/assets/index.css";
import { setAreYearsPlaying } from "../../redux/yearsSlice";
import { useDispatch } from "react-redux";

interface Props {
  availableYears: Array<number>;
  currentYears: Array<number>;
  setChosenYears: (year: Array<number>) => void;
}

type IMarks = {
  [key: number]: number;
};

const RangeWithTooltip = createSliderWithTooltip(Range);

const isEqualGapBetweenYears = (availableYears: Array<number>): boolean => {
  // Only with at least 3 years we have 2 gaps between years
  if (availableYears.length < 3) {
    return false;
  }
  const firstGap = availableYears[0] - availableYears[1];
  for (let i = 2; i < availableYears.length; i++) {
    if (firstGap !== availableYears[i] - availableYears[i - 1]) {
      return false;
    }
  }
  return true;
};

export default function YearSlider({ availableYears, currentYears, setChosenYears }: Props): ReactElement {
  const dispatch = useDispatch();

  const hasManyYears = availableYears.length > 5;
  const min = head(availableYears);
  const max = last(availableYears);

  const marks: IMarks = {};
  availableYears.forEach((year) => (marks[year] = year));

  // This is only called when user releases mouse. So will sliding, not on every year the picker passes a request/render of data is done
  const handleAfterChange = (value: Array<number>) => {
    const left = value[0];
    const right = value[1];
    const newYears = availableYears.filter((y) => y >= left && y <= right);

    // When user manually picks a year range then stop a possibly running animation
    dispatch(setAreYearsPlaying(false));

    setChosenYears(newYears);
  };

  // Make this a controlled component which updates when currentYears changes upstream
  const [value, setValue] = useState(currentYears);
  const handleChange = (value: Array<number>) => setValue(value);
  useEffect(() => setValue(currentYears), [currentYears]);

  const equalGapBetweenYears = isEqualGapBetweenYears(availableYears);

  const currentLeft = head(value) as number;
  const currentRight = last(value) as number;

  const props = {
    value: [currentLeft, currentRight],
    min,
    max,
    marks,
    step: null,
    className: classNames(ns("year-slider__range"), {
      [ns("year-slider__range--many-years")]: hasManyYears,
      [ns("year-slider__range--few-years")]: !hasManyYears,
      [ns("year-slider__range--has-draggable-track")]: equalGapBetweenYears
    }),
    onChange: handleChange,
    onAfterChange: handleAfterChange,
    draggableTrack: equalGapBetweenYears
  };

  return (
    <div className={ns("year-slider")}>
      {hasManyYears ? (
        <>
          <div className={ns("year-slider__min")}>{min}</div>
          <RangeWithTooltip {...props} />
          <div className={ns("year-slider__max")}>{max}</div>
        </>
      ) : (
        <Range {...props} />
      )}
    </div>
  );
}
