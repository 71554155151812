import { Feature, FeatureCollection, Geometry } from "geojson";

export interface ITagBase {
  // Unfortunately, id is number for regions, topics, indicators - but string for filters
  id: number | string;
  name: string;
  // title is optional for filters
  title?: string;
}

export type IRenderer =
  | "BAR_CHART"
  | "LINE_CHART"
  | "MIGRATION_CHART"
  | "TABLE"
  | "PYRAMID_CHART"
  | "MAP"
  | "RADAR_CHART"
  | "DISTRIBUTION_CHART";

export enum RendererTitle {
  TABLE = "Tabelle",
  BAR_CHART = "Balkendiagramm",
  LINE_CHART = "Liniendiagramm",
  MIGRATION_CHART = "Migrationsdiagramm",
  PYRAMID_CHART = "Pyramidendiagramm",
  RADAR_CHART = "Netzdiagramm",
  DISTRIBUTION_CHART = "Verteilungsdiagramm",
  MAP = "Karte"
}

export enum RendererFriendlyUrl {
  TABLE = "tabelle",
  BAR_CHART = "balkendiagramm",
  LINE_CHART = "liniendiagramm",
  MIGRATION_CHART = "migrationsdiagramm",
  PYRAMID_CHART = "pyramidendiagramm",
  RADAR_CHART = "netzdiagramm",
  DISTRIBUTION_CHART = "verteilungsdiagramm",
  MAP = "karte",
  BUND = "karte",
  BUNDESLAND = "landeskarte",
  LANDKREIS = "kreiskarte"
}

export enum StatisticType {
  CommunalData = "COMMUNAL_DATA",
  PopulationForecast = "POPULATION_FORECAST",
  CareForecast = "CARE_FORECAST",
  AgeMigration = "AGE_MIGRATION",
  GeographicMigration = "GEOGRAPHIC_MIGRATION",
  DemographicTypes = "DEMOGRAPHIC_TYPES",
  PopulationPyramid = "POPULATION_PYRAMID",
  AgeStructure = "AGE_STRUCTURE",
  AgeStructureForecast = "AGE_STRUCTURE_FORECAST"
}
export interface IStatisticType {
  name: string;
  type: StatisticType;
  years: Array<number>;
  rendererTypes: Array<IRenderer>;
  indicatorsAvailable?: boolean;
}

interface ITopicIndicatorBase extends ITagBase {
  id: number;
  type: string;
  friendlyUrl: string;
  explanation?: string;
  comparisonYear?: number;
  comparisonYearTitle?: string;
}

export interface ITopic extends ITopicIndicatorBase {
  indicators: Array<IIndicator>;
  topics: Array<ITopic>;
}

export interface IIndicator extends ITopicIndicatorBase {
  topics: Array<string>;
  years: Array<number>;
  unit: string;
  decimalPlaces: number;
  friendlyUrl: string;
  explanation: string;
  calculation: string;
  colorSchema: string;
  minimumClassification?: number;
  maximumClassification?: number;
  topLowRegionsAvailable?: boolean;
}

export enum RegionType {
  KleineGemeinde = "KLEINE_GEMEINDE",
  Gemeinde = "GEMEINDE",
  GroßeGemeinde = "GROSSE_GEMEINDE",
  KreisfreieStadt = "KREISFREIE_STADT",
  Landkreis = "LANDKREIS",
  Stadtstaat = "STADTSTAAT",
  Bundesland = "BUNDESLAND",
  Bund = "BUND"
}
export interface IRegion extends ITagBase {
  id: number;
  gkz: string;
  title: string;
  type: RegionType;
  demographicType?: number;
  friendlyUrl: string;
  parent?: string;
  smallRegionReplacement?: boolean;
}

export interface IFilter {
  regionTypes: Array<ITagBase>;
  parents: Array<ITagBase>;
  populations: Array<ITagBase>;
  demographicTypes: Array<ITagBase>;
  gkzs: Array<ITagBase>;
}

export interface IRegionFilter extends ITagBase, IFilter {
  friendlyUrl: string;
  regions: Array<IRegion>;
}

export type IRegionOrIRegionFilter = IRegion | IRegionFilter;

export type IIndicatorOrTopic = IIndicator | ITopic;

export interface IClassItem {
  classIndex: number;
  label: string;
  title?: string;
  selected: boolean;
}

export interface IStatisticsData {
  indicators: Array<{
    id: number;
    name: string;
    minValue: number;
    maxValue: number;
    regionYearValues: Array<Array<number>> | Array<Array<Array<number>>>;
    unit?: string;
  }>;
  remark?: string;
  source: string;
  regions: Array<IRegion>;
}

export interface IDiagramData {
  regions: Array<IRegion>;
  renderer: IRenderer;
  years: Array<number>;
  indicator: IIndicator | null;
  topic: ITopic | null;
  data: IStatisticsData | null;
}

export enum LayerType {
  State = "STATE",
  District = "DISTRICT",
  Commune = "COMMUNE"
}

export enum OutlineType {
  Bund = "BUND",
  Bundesland = "BUNDESLAND",
  Landkreis = "LANDKREIS"
}

export interface IMapData {
  years: number[];
  type: StatisticType;
  layerType: LayerType;
  indicator: IIndicator | null;
  classes: Array<IClassItem>;
  regions: FeatureCollection<Geometry, IDataFeatureProperties> | null;
  outlineRegions: FeatureCollection<Geometry, IDataFeatureProperties> | null;
  containerRegions: FeatureCollection<Geometry, IDataFeatureProperties> | null;
  source: string;
  remark?: string;
}

export type IMapBbox = Array<number>;

export interface IMapPickerData {
  type: string;
  layerType: LayerType;
  indicator: IIndicator | null;
  regions: FeatureCollection<Geometry, IPickerFeatureProperties> | null;
  outlineRegions: FeatureCollection<Geometry, IPickerFeatureProperties> | null;
  containerRegions: FeatureCollection<Geometry, IPickerFeatureProperties> | null;
  source: string;
}

export type IDataFeature = Feature<Geometry, IDataFeatureProperties>;
export type IPickerFeature = Feature<Geometry, IPickerFeatureProperties>;

export type IFeature = IDataFeature | IPickerFeature;

export interface IFeatureProperties {
  gkz: string;
  name: string;
  selected?: boolean;
  friendlyUrl: string;
  type: RegionType;
  demographicType?: number;
}

export interface IDataFeatureProperties extends IFeatureProperties {
  classIndices?: Array<number>;
  values?: Array<number | null>;
}

export interface IPickerFeatureProperties extends IFeatureProperties {
  parent?: string;
}

export interface IDemographicType {
  number: number;
  name: string;
  title: string;
  descriptionFile?: string;
  regionsFile?: string;
}
