import React, { ReactElement } from "react";
import Icon from "../atoms/Icon";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Button from "../molecules/button/Button";
import LeftRightToggleBar from "./LeftRightToggleBar";
import { closeModal, selectIsLeftActive } from "../../redux/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useGetHandleAddRegionFilter } from "../../utils/useGetHandleAddRegionFilter";

interface Props {
  isMobile: boolean;
  leftLabel: "Liste";
  rightLabel: "Karte" | "Sets";
  left: ReactElement;
  right: ReactElement;
  confirmLabel?: string;
  classNameModifier?: "regions";
}

export default function SelectorOverlay({
  isMobile,
  leftLabel,
  rightLabel,
  left,
  right,
  confirmLabel = "Anzeigen",
  classNameModifier
}: Props): ReactElement {
  const isLeftActive = useSelector(selectIsLeftActive);
  const dispatch = useDispatch();
  const closeIcon = (
    <Icon className={ns("btn__icon")} width={23} height={23} fill="none">
      <use xlinkHref={useGetSvgPath("#close")}></use>
    </Icon>
  );

  const [isAddRegionFilterDisabled, handleAddRegionFilter] = useGetHandleAddRegionFilter();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleShow = () => {
    // Only on the MapPicker overlay when clicking on "Anzeigen"
    // then the currently/temporarily chosen filter should be added
    // Too many people forgetting to click "X Kommunen hinzufügen" before clicking "Anzeigen"
    if (classNameModifier === "regions" && !isAddRegionFilterDisabled) {
      handleAddRegionFilter();
    }
    handleClose();
  };

  return (
    <div
      className={classNames(ns("data-selection"), {
        [ns(`data-selection--${classNameModifier}`)]: classNameModifier
      })}
    >
      <div className={ns("data-selection__header")}>
        {isMobile ? (
          <LeftRightToggleBar leftLabel={leftLabel} rightLabel={rightLabel} />
        ) : (
          <Button
            label="Schließen"
            className={ns("btn--mira btn--icon modal__close-btn")}
            srOnly
            icon={closeIcon}
            handleClick={handleClose}
          />
        )}
      </div>
      {(!isMobile || (isMobile && isLeftActive)) && <div className={ns("data-selection__aside")}>{left}</div>}
      {(!isMobile || (isMobile && !isLeftActive)) && <div className={ns("data-selection__main")}> {right}</div>}
      <div className={ns("data-selection__footer")}>
        <Button label={confirmLabel} className={ns("btn--primary data-selection__confirm")} handleClick={handleShow} />
      </div>
    </div>
  );
}
