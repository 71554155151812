import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import { IIndicator, IRegion, IStatisticsData, ITopic } from "../ts/interfaces";
import { RootState } from "./rootReducer";

export interface IFetchStatisticsDataPreArgs {
  apiUrl: string;
  indicators: Array<IIndicator>;
  topics?: Array<ITopic>;
  regions: Array<IRegion>;
  years: Array<number>;
  topRegionsCount?: number;
  lowRegionsCount?: number;
}

interface IFetchStatisticsDataArgs {
  apiUrl: string;
  indicatorIds: Array<number>;
  topicIds?: Array<number>;
  regionIds: Array<number>;
  years: Array<number>;
  topRegionsCount?: number;
  lowRegionsCount?: number;
}

interface IStatisticsDataState {
  loading: boolean;
  hasErrors: boolean;
  statisticsData: IStatisticsData | null;
  args: IFetchStatisticsDataPreArgs | null;
}

const initialState: IStatisticsDataState = {
  loading: false,
  hasErrors: false,
  statisticsData: null,
  args: null
};

export const fetchStatisticsData = createAsyncThunk(
  "statistics/fetchStatistics",
  async ({
    apiUrl,
    indicators,
    // if topics are provided they will be used for fetching instead of indicators
    topics,
    regions,
    years,
    topRegionsCount,
    lowRegionsCount
  }: IFetchStatisticsDataPreArgs) => {
    const requestBody: Partial<IFetchStatisticsDataArgs> = {
      regionIds: regions.map((region) => region.id),
      years,
      topRegionsCount,
      lowRegionsCount
    };
    if (topics && !isEmpty(topics)) {
      requestBody.topicIds = topics.map((topic) => topic.id);
    } else {
      requestBody.indicatorIds = indicators.map((indi) => indi.id);
    }

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    });
    const statisticsData = await response.json();
    return statisticsData;
  }
);

// A slice for statisticsData with our three reducers
const statisticsDataSlice = createSlice({
  name: "statisticsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStatisticsData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchStatisticsData.fulfilled, (state, action) => {
      state.statisticsData = action.payload;
      state.args = action.meta.arg;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchStatisticsData.rejected, (state, action) => {
      state.loading = false;
      state.hasErrors = true;
    });
  }
});

export const selectStatisticsData = (state: RootState) => state.statisticsData;

export const selectIsStatisticsDataLoading = (state: RootState) => state.statisticsData.loading;

export const selectStatisticsRemark = (state: RootState) => state.statisticsData.statisticsData?.remark;

export default statisticsDataSlice.reducer;
