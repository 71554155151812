import React, { ReactElement, ReactNode } from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import ns from "../../utils/namespace";

interface Props {
  className?: string;
  contentLabel: string;
  children: ReactNode;
  isOpen: boolean;
  closeModal: () => void;
}

export default function Modal({ className, contentLabel, children, isOpen, closeModal }: Props): ReactElement {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      portalClassName={classNames(ns("modal"), className)}
      overlayClassName={ns("modal__overlay")}
      className={ns("modal__content")}
      bodyOpenClassName={ns("modal__body--open")}
      // Set main liferay #page to aria-hidden=true when modal is open
      appElement={document.getElementById("page") as HTMLElement}
      contentLabel={contentLabel}
    >
      {children}
    </ReactModal>
  );
}
