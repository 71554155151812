import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  MapContainer,
  ZoomControl
  // Only for debugging. Show an OSM map as background:
  // TileLayer,
  // Only used for debugging bbox with a red rectangle below:
  // FeatureGroup,
  // Rectangle,
} from "react-leaflet";
import { Map } from "leaflet";
import "leaflet/dist/leaflet.css";

import { IPickerFeature } from "../../../ts/interfaces";
import ns from "../../../utils/namespace";
import { addTooltip } from "./addPickerTooltip";
import MapPickerEvents from "./MapPickerEvents";
import RegionMarkers from "../../molecules/map-utils/RegionMarkers";
import { useGetMapPickerData } from "./useGetMapPickerData";
import GeoJsonWithUpdates from "../../molecules/map-utils/GeoJsonWithUpdates";
import FeaturesLayer from "../../molecules/map-utils/FeaturesLayer";
import useStableCallback from "../../../utils/useStableCallback";
import { useMemoizedSelectors } from "../../../utils/reduxHooks";
import Loader from "../../molecules/Loader";
import LayerSwitcher from "../../molecules/LayerSwitcher";
import { usePickerRegionStyleCallback } from "./usePickerRegionStyleCallback";
import { isRegionFilter } from "../../../utils/helpers";
import {
  selectActivePickerFeature,
  selectActiveRegionOrRegionFilter,
  setActivePickerFeature
} from "../../../redux/uiSlice";
import { useRegionPopupAndTooltip } from "../../molecules/map-utils/useRegionPopupAndTooltip";

export default function MapPicker(): ReactElement | null {
  const dispatch = useDispatch();

  const { selectChosenFeatures } = useMemoizedSelectors().mapPickerData;

  const chosenFeatures = useSelector(selectChosenFeatures);
  const activeRegionOrRegionFilter = useSelector(selectActiveRegionOrRegionFilter);
  const activeFeature = useSelector(selectActivePickerFeature);
  const highlightedFeatures = useMemo(() => {
    if (activeRegionOrRegionFilter) {
      if (isRegionFilter(activeRegionOrRegionFilter)) {
        return chosenFeatures.filter((feature) =>
          activeRegionOrRegionFilter.regions.some((region) => region.id === feature.id)
        );
      } else {
        const relatedFeature = chosenFeatures.find((feature) => feature.id === activeRegionOrRegionFilter.id);
        if (relatedFeature) {
          return [relatedFeature];
        }
      }
    }
  }, [activeRegionOrRegionFilter, chosenFeatures]);
  const chosenFeaturesWithoutHighlightedOrActive = useMemo(
    () =>
      activeFeature?.id || highlightedFeatures
        ? chosenFeatures.filter(
            (feature) => activeFeature?.id !== feature.id && !highlightedFeatures?.includes(feature)
          )
        : chosenFeatures,
    [activeFeature?.id, chosenFeatures, highlightedFeatures]
  );

  const [map, setMap] = useState<Map | null>(null);
  const [isMapCreated, setIsMapCreated] = useState(false);

  const {
    mapPickerBbox,
    bboxLoading,
    bboxHasErrors,
    mapPickerData,
    mapPickerDataLoading,
    mapPickerDataHasErrors
  } = useGetMapPickerData(map, isMapCreated);

  const setActiveFeature = useCallback(
    (feature: IPickerFeature | null) => {
      dispatch(setActivePickerFeature(feature));
    },
    [dispatch]
  );
  const renderPopupContent = useCallback((activeFeature: IPickerFeature) => {
    const { parent, demographicType } = activeFeature.properties;
    return (
      <>
        {parent && <div>{parent}</div>}
        {demographicType && <div className={ns("region-popup__type")}>Typ {demographicType}</div>}
      </>
    );
  }, []);
  const { onEachFeatureCallback, renderedRegionPopup } = useRegionPopupAndTooltip(
    map,
    activeFeature,
    addTooltip,
    setActiveFeature,
    renderPopupContent,
    ns("map-picker__region-popup")
  );

  const stableOnEachFeatureCallback = useStableCallback(onEachFeatureCallback);

  const currentZoom = map?.getZoom();

  const regionStyleCallback = usePickerRegionStyleCallback(mapPickerData, currentZoom);
  const chosenRegionStyleCallback = usePickerRegionStyleCallback(
    mapPickerData,
    currentZoom,
    ns("map-picker__region--chosen")
  );
  const activeRegionStyleCallback = usePickerRegionStyleCallback(
    mapPickerData,
    currentZoom,
    ns("map-picker__region--active")
  );

  if (!mapPickerBbox && bboxLoading) {
    // first time loading
    return (
      <div className={ns("map-picker")}>
        <Loader isCentered />
      </div>
    );
  } else if (bboxHasErrors) {
    return (
      <div className={ns("map-picker")}>
        <p>
          <strong>
            Leider gab es einen Fehler bei der Verbindung mit dem Kartendienst! Bitte versuchen Sie es später erneut.
          </strong>
        </p>
      </div>
    );
  } else if (mapPickerDataHasErrors) {
    return (
      <div className={ns("map-picker")}>
        <p>
          <strong>Error: Could not load map data…</strong>
        </p>
      </div>
    );
  } else if (mapPickerBbox) {
    return (
      <div className={ns("map-picker")}>
        <div className={ns("map-picker__loading-area")}>
          <MapContainer
            className={ns("map-container")}
            zoomControl={false}
            attributionControl={false}
            minZoom={5}
            maxZoom={11}
            whenCreated={(map) => {
              setMap(map);
              setIsMapCreated(true);
            }}
            // Setting tap to false for fixing https://issues.init.de/browse/BSTWK-1271?focusedCommentId=2457209&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2457209
            // which also applies in MapPicker for LayerSwitcher and is caused by https://github.com/Leaflet/Leaflet/issues/7255, fix was proposed here https://stackoverflow.com/questions/67406533/react-leaflet-popups-not-working-on-mobile-devices
            // May be removed once leaflet is updated to 1.8.0 or above
            tap={false}
          >
            <MapPickerEvents />
            <ZoomControl
              position="bottomright"
              zoomInText=""
              zoomInTitle="Vergrößern"
              zoomOutText=""
              zoomOutTitle="Verkleinern"
            />
            {/* Only for debugging: Show an OSM map as background */}
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            /> */}
            {mapPickerData?.regions && !isEmpty(mapPickerData?.regions) && (
              <>
                <GeoJsonWithUpdates
                  data={mapPickerData.regions}
                  onEachFeature={stableOnEachFeatureCallback}
                  style={regionStyleCallback}
                />
                {/* Enable to show a red rectangle around the bbox for better debugging */}
                {/* {bounds && (
                  <FeatureGroup>
                    <Rectangle
                      bounds={bounds}
                      pathOptions={{
                        color: "#ff0000",
                        weight: 1,
                        fillOpacity: 0,
                      }}
                    />
                  </FeatureGroup>
                )} */}
              </>
            )}

            {mapPickerData?.containerRegions && !isEmpty(mapPickerData?.containerRegions) && (
              <GeoJsonWithUpdates
                data={mapPickerData.containerRegions}
                interactive={false}
                pathOptions={{
                  // $color-mineshaft
                  color: "#1f1f1f",
                  weight: 1,
                  fillOpacity: 0
                }}
              />
            )}
            {mapPickerData?.outlineRegions && !isEmpty(mapPickerData?.outlineRegions) && (
              <GeoJsonWithUpdates
                data={mapPickerData.outlineRegions}
                interactive={false}
                pathOptions={{
                  // $color-harlequin
                  color: "#08e803",
                  weight: 1,
                  fillOpacity: 0
                }}
              />
            )}
            {chosenFeatures && !isEmpty(chosenFeatures) && (
              <>
                <FeaturesLayer
                  features={chosenFeaturesWithoutHighlightedOrActive}
                  pathOptions={{
                    // $color-mariner
                    fillColor: "#2664d1"
                  }}
                  style={chosenRegionStyleCallback}
                />
                <RegionMarkers chosenFeatures={chosenFeatures} exclude={activeFeature || undefined} areClickable />
              </>
            )}
            {highlightedFeatures && (
              <>
                <FeaturesLayer
                  features={highlightedFeatures}
                  pathOptions={{
                    // $color-dodger
                    fillColor: "#2876ff"
                  }}
                  style={chosenRegionStyleCallback}
                />
                {renderedRegionPopup}
              </>
            )}
            {activeFeature && (
              <>
                <FeaturesLayer
                  features={[activeFeature]}
                  pathOptions={{
                    // $color-dodger
                    fillColor: "#2876ff"
                  }}
                  style={activeRegionStyleCallback}
                />
                {renderedRegionPopup}
              </>
            )}
            <LayerSwitcher className={ns("map-picker__layer-switcher")} />
            {(bboxLoading || mapPickerDataLoading) && <Loader isCentered />}
          </MapContainer>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
