import ReactDOMServer from "react-dom/server";
import { Layer, Map } from "leaflet";

import { IPickerFeature } from "../../../ts/interfaces";
import ns from "../../../utils/namespace";
import classNames from "classnames";
import { getTooltipDirection } from "../../../utils/map";

export const addTooltip = (feature: IPickerFeature, layer: Layer, map: Map) => {
  const region = feature.properties.name;
  // const type = feature.properties.value;

  const content = (
    <div className={classNames(ns("chart__tooltip-content"), ns("map-picker__tooltip-content"))}>
      {region}
      {/* <br />
      <strong>Typ {type}</strong> */}
    </div>
  );

  const tooltipContent = ReactDOMServer.renderToStaticMarkup(content);
  const direction = getTooltipDirection(map, feature);

  layer.bindTooltip(tooltipContent, {
    direction
  });
};
