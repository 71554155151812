import { createAppIdSlice } from "./appIdSlice";
import { createMapBboxSlice } from "./mapBboxSlice";
import { createMapDataSlice } from "./mapDataSlice";
import { initNonConcurrentAsyncThunks } from "./nonConcurrentAsyncThunks";

/**
 * We must create and store some slices for each app instance on its own,
 * else they will not work correctly, because they contain parts which would interfer when used with multiple app instances.
 * Creating slices dynamically should be the last option, please check before if really parts of the slice are instance dependent
 * or only not directly dependent periphery like memoized selectors (in this case see ./memoizedSelectors.ts).
 * This dictionary holds for each appId (the key) the related created slice.
 */
const dynamicSlices: {
  [key: string]: ReturnType<typeof createDynamicSlices>;
} = {};

/**
 * Creates slices which need to be unique for each app instance.
 * @returns dictionary holding mapping slice name to actual created slice
 */
const createDynamicSlices = (
  appId: string,
  nonConcurrentAsyncThunks: ReturnType<typeof initNonConcurrentAsyncThunks>
) => ({
  appId: createAppIdSlice(appId),
  mapData: createMapDataSlice(nonConcurrentAsyncThunks.mapData),
  mapBbox: createMapBboxSlice(nonConcurrentAsyncThunks.mapBbox)
});

/**
 * Creates fresh dynamic slices for given appId.
 * @param appId the id of the app the dynamic slices shall be created for
 */
export const initDynamicSlices = (appId: string) => {
  if (dynamicSlices[appId]) {
    console.warn(`Created already dynamic slices for given appId: ${appId}`);
    return;
  }
  const createdDynamicSlices = createDynamicSlices(appId, initNonConcurrentAsyncThunks(appId));
  dynamicSlices[appId] = createdDynamicSlices;
};

/**
 * Returns created dynamic slices for given appId.
 */
export const getDynamicSlices = (appId: string) => {
  return dynamicSlices[appId];
};
