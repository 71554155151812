import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isFinite from "lodash/isFinite";
import { selectComparisonYear, selectComparisonYearTitle } from "../../../../redux/indicatorsOrTopicsSlice";
import { selectIncludeComparisonYear, setIncludeComparisonYear } from "../../../../redux/uiSlice";
import ns from "../../../../utils/namespace";
import InteractiveLegendItem from "../../../molecules/legend/InteractiveLegendItem";
import { CHART_COMPONENT_NAME } from "./Chart";

export const useComparisonYear = (chosenSingleYear: number | null) => {
  const dispatch = useDispatch();

  const comparisonYear = useSelector(selectComparisonYear);
  const comparisonYearTitle = useSelector(selectComparisonYearTitle);
  const includeComparisonYear = useSelector(selectIncludeComparisonYear);

  const chosenYears = useMemo(
    () =>
      chosenSingleYear
        ? includeComparisonYear && comparisonYear && isFinite(comparisonYear)
          ? [chosenSingleYear, comparisonYear]
          : [chosenSingleYear]
        : [],
    [comparisonYear, chosenSingleYear, includeComparisonYear]
  );

  const comparisonYearCheckbox =
    comparisonYear && comparisonYearTitle && isFinite(comparisonYear) ? (
      <InteractiveLegendItem
        label={`${comparisonYearTitle}`}
        selected={includeComparisonYear}
        onToggle={() => dispatch(setIncludeComparisonYear(!includeComparisonYear))}
        className={ns(`${CHART_COMPONENT_NAME}__checkbox`)}
      />
    ) : null;

  return {
    chosenYears,
    comparisonYearCheckbox
  };
};
