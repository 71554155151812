import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ActionMeta, components, FormatOptionLabelMeta } from "react-select";
import AsyncSelect from "react-select/async";
import classNames from "classnames";
import parse from "html-react-parser";
import ns from "../../utils/namespace";
import { ITagBase } from "../../ts/interfaces";
import isEmpty from "lodash/isEmpty";
import { isRegion } from "../../utils/helpers";

// allows to add options to the menu list which are not valid
// selectable options in the true sense but "fake" options
// which may contain any content by providing a formatLabel function
// and may provide custom logic when being selected
interface ICustomDummyOption {
  value: string;
  title?: string;
  formatLabel?: (option: IDummyOption, labelMeta: FormatOptionLabelMeta<IDummyOption, boolean>) => ReactNode;
  onSelect?: (value: string) => void;
}

type IDummyOption = ICustomDummyOption & {
  isDummy: true;
};

type IOption = ITagBase | IDummyOption;

function isIDummyOption(option: IOption): option is IDummyOption {
  return (option as IDummyOption).isDummy;
}

function isSmallRegionReplacementOption(option: ITagBase): boolean {
  return isRegion(option) && "smallRegionReplacement" in option;
}

interface Props {
  optionsAPIUrl: string;
  optionsAPIUrlQueryParams?: string;
  placeholder: string;
  className?: string;
  noOptionsInfo: string;
  shouldLoadDefaultOptions?: boolean;
  inputInfo?: string;
  shouldShowInputInfo?: boolean;
  loadingMessage?: string | null;
  shouldFocus?: boolean;
  shouldShowDropdownIndicator?: boolean;
  inputId?: string;
  value?: ITagBase | readonly ITagBase[] | null;
  shouldHideSelectedOptions?: boolean;
  isMulti?: boolean;
  propertyUsedAsValue?: keyof ITagBase;
  dummyOptions?: ICustomDummyOption[];
  noOptionsDummyOptions?: ICustomDummyOption[];
  onChoose: (tag: ITagBase) => void;
  onFocus: () => void;
  onBlur: () => void;
  isOptionDisabled?: (option: ITagBase) => boolean;
}

const formatOptionLabel = (option: IOption, labelMeta: FormatOptionLabelMeta<IOption, boolean>) => {
  if (isIDummyOption(option)) {
    return option.formatLabel
      ? option.formatLabel(option, labelMeta as FormatOptionLabelMeta<IDummyOption, boolean>)
      : option.title || option.value;
  } else {
    return option.title || option.name;
  }
};

const resetStyles = new Proxy(
  {},
  {
    get: (target, propKey) => () => {}
  }
);

const parseDummyOptions = (dummyOptions: ICustomDummyOption[]) => {
  return dummyOptions.map(
    (option) =>
      ({
        ...option,
        isDummy: true
      } as IDummyOption)
  );
};
const useParseDummyOptions = (dummyOptions: ICustomDummyOption[] | undefined): IDummyOption[] => {
  return useMemo(() => (dummyOptions ? parseDummyOptions(dummyOptions) : []), [dummyOptions]);
};

export default function SelectAutocompletion({
  optionsAPIUrl,
  optionsAPIUrlQueryParams = "",
  placeholder,
  className,
  noOptionsInfo,
  shouldLoadDefaultOptions,
  inputInfo,
  shouldShowInputInfo = inputInfo ? true : false,
  loadingMessage = null,
  shouldFocus = false,
  shouldShowDropdownIndicator = false,
  inputId,
  value = null,
  shouldHideSelectedOptions = value ? true : false,
  isMulti = true,
  propertyUsedAsValue = "name",
  dummyOptions,
  noOptionsDummyOptions,
  onChoose,
  onFocus,
  onBlur,
  isOptionDisabled: isLoadedOptionDisabled
}: Props): ReactElement {
  const selectRef = useRef<AsyncSelect<IOption, false>>(null);

  const [hasTyped, setHasTyped] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [defaultOptions, setDefaultOptions] = useState<IOption[] | undefined>(undefined);

  useEffect(() => {
    if (shouldFocus) {
      selectRef.current?.focus();
    }
  }, [shouldFocus]);

  const parsedDummyOptions = useParseDummyOptions(dummyOptions);
  const parsedNoOptionsDummyOptions = useParseDummyOptions(noOptionsDummyOptions);

  const loadOptions = useCallback(
    (inputValue: string) => {
      return fetch(`${optionsAPIUrl}?search=${inputValue}${optionsAPIUrlQueryParams}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error(response.statusText);
          }
        })
        .then((options: Array<IOption>) =>
          options.concat(isEmpty(options) ? parsedNoOptionsDummyOptions : parsedDummyOptions)
        )
        .catch((error) => {
          // currently we do not handle errors
          console.warn("Could not loadOptions in SelectAutocompletion: ", error);
          // simply return empty array
          return [];
        });
    },
    [optionsAPIUrl, optionsAPIUrlQueryParams, parsedDummyOptions, parsedNoOptionsDummyOptions]
  );

  const handleInputChange = (newInputValue: string) => {
    if (newInputValue.length > 0) {
      setHasTyped(true);
    } else {
      setHasTyped(false);
    }
    setInputValue(newInputValue);
  };

  const [hasFocus, setHasFocus] = useState(shouldFocus);

  // We use a custom defaultOptions fetching behavior since react-select does not
  // refetch defaultOptions on every inputValue.length === 0
  // which is needed since API call changes dependent of already selected values
  useEffect(() => {
    if (hasFocus && inputValue.length === 0 && shouldLoadDefaultOptions) {
      loadOptions(inputValue).then((options) => setDefaultOptions(options));
    } else if (!hasFocus) {
      setDefaultOptions(undefined);
    }
  }, [hasFocus, inputValue, loadOptions, parsedDummyOptions, shouldLoadDefaultOptions]);

  const handleChange = (_: any, actionMeta: ActionMeta<IOption>) => {
    if (actionMeta.action === "select-option") {
      const { option } = actionMeta;
      if (option) {
        if (isIDummyOption(option)) {
          option.onSelect && option.onSelect(option.value);
        } else {
          onChoose(option);
        }
      }
    }
  };

  const MenuList = useMemo(
    () => (props: any) => {
      const options: Array<IOption> = props.options;
      const areNoOptionsAvailable = options.length === 0 || options.every((option) => isIDummyOption(option));

      const renderedInputInfoContent = hasTyped
        ? noOptionsInfo && parse(noOptionsInfo)
        : shouldShowInputInfo && inputInfo;

      const allOptionsExceptDummy = options.filter((option) => !isIDummyOption(option)) as Array<ITagBase>;
      const areAllOptionsSmallRegions =
        !isEmpty(allOptionsExceptDummy) &&
        allOptionsExceptDummy.every((option) => isSmallRegionReplacementOption(option));

      return (
        <components.MenuList {...props}>
          {areNoOptionsAvailable && renderedInputInfoContent && (
            <div className={ns("select-autocompletion__no-options-message")}>{renderedInputInfoContent}</div>
          )}
          {areAllOptionsSmallRegions && (
            <div className={ns("select-autocompletion__no-options-message")}>
              Ihre gewählte Kommune hat weniger als 5.000 Einwohner:innen, daher liegen keine Daten vor. Wählen Sie
              stattdessen den zugehörigen Landkreis:
            </div>
          )}
          {props.children}
        </components.MenuList>
      );
    },

    [hasTyped, inputInfo, noOptionsInfo, shouldShowInputInfo]
  );

  const getOptionValue = useMemo(
    () => (option: IOption) => (isIDummyOption(option) ? option.value : (option[propertyUsedAsValue] as string)),
    [propertyUsedAsValue]
  );

  const isOptionDisabled = useMemo(
    () =>
      isLoadedOptionDisabled
        ? (option: IOption) => (isIDummyOption(option) ? false : isLoadedOptionDisabled(option))
        : undefined,
    [isLoadedOptionDisabled]
  );

  return (
    <AsyncSelect
      components={{
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => null,
        MenuList: MenuList,
        ...(shouldShowDropdownIndicator ? {} : { DropdownIndicator: () => null })
      }}
      placeholder={placeholder}
      // we don't use react-select to display selected values
      controlShouldRenderValue={false}
      isClearable={false}
      loadingMessage={() => loadingMessage}
      {...(hasTyped || (!hasTyped && shouldShowInputInfo) ? { menuIsOpen: true } : {})}
      value={value}
      styles={resetStyles}
      ref={selectRef}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      hideSelectedOptions={shouldHideSelectedOptions}
      tabSelectsValue={false}
      isMulti={isMulti}
      inputId={inputId}
      onChange={handleChange}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      onInputChange={handleInputChange}
      onFocus={() => {
        setHasFocus(true);
        onFocus();
      }}
      onBlur={(ev) => {
        // Enable this if you want to style the dropdown menu
        // This avoids that the dropdown vanishes when navigating to the DevTools
        // See https://github.com/JedWatson/react-select/issues/1487
        /* eslint-disable-next-line no-debugger */
        // debugger;

        setHasFocus(false);

        // Without this check, click on a topic tile will not only trigger selection of new topic
        // but the reset handler too (& that would result in the last topic being active again & the new one)
        const isClickOnTopicTile =
          ev.relatedTarget instanceof HTMLElement && ev.relatedTarget.classList.contains("wk-topic-tile");

        // only trigger onBlur (= reset handler) if click was not on topic tile
        if (!isClickOnTopicTile) {
          onBlur();
        }
      }}
      {...(isOptionDisabled ? { isOptionDisabled: isOptionDisabled } : {})}
      className={classNames(ns("select-autocompletion"), className)}
      classNamePrefix={ns("select-autocompletion")}
    />
  );
}
