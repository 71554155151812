import React, { ReactElement, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import uniqueId from "lodash/uniqueId";
import ns from "../../../utils/namespace";
import { useGetSvgPath } from "../../../utils/svgPath";
import TagList from "../../molecules/TagList";
import FormLabel from "../../atoms/FormLabel";
import FormControl from "../../atoms/FormControl";
import Button from "../../molecules/button/Button";
import Icon from "../../atoms/Icon";
import { ITagBase, IIndicatorOrTopic, IRegionOrIRegionFilter } from "../../../ts/interfaces";

interface Props {
  label: string;
  resetLabel: string;
  tags: Array<IIndicatorOrTopic> | Array<IRegionOrIRegionFilter>;
  selectAutocompletion: ReactElement;
  handleUnchoose: (tagName: ITagBase) => void;
  handleUnchooseAll: () => void;
  isTagActive?: (tagObj: ITagBase) => boolean;
  handleTagClick?: (tagObj: ITagBase) => void;
  getTagActionLabel?: (tagObj: ITagBase) => string;
}

export default function TagContainerInOverlay({
  label,
  resetLabel,
  tags,
  selectAutocompletion,
  handleUnchoose,
  handleUnchooseAll,
  isTagActive,
  handleTagClick,
  getTagActionLabel
}: Props): ReactElement {
  const inputId = useRef(`${ns("search-input")}-${uniqueId()}`);

  const [inputIsFocused, setInputIsFocused] = useState(false);

  const handleInputFocus = () => {
    setInputIsFocused(true);
  };

  const handleInputBlur = () => {
    setInputIsFocused(false);
  };

  return (
    <div className={ns("tag-container tag-container--overlay")}>
      <ol className={ns("tag-container__tag-list")}>
        <TagList
          tags={tags}
          handleUnchoose={handleUnchoose}
          className={ns("tag-container__tag-list-item")}
          isActive={isTagActive}
          handleClick={handleTagClick}
          getActionLabel={getTagActionLabel}
        />
      </ol>
      <Button
        label={resetLabel}
        className={ns("btn--reset tag-container__filter-reset")}
        handleClick={handleUnchooseAll}
        disabled={isEmpty(tags)}
        iconBefore={
          <Icon className={ns("tag-container__filter-reset-icon")} width={20} height={20}>
            <use xlinkHref={useGetSvgPath("#delete-all")}></use>
          </Icon>
        }
      />
      <FormLabel inputId={inputId.current} label={label} className={ns("tag-container__label")} />
      <FormControl className={ns("tag-container__field")} active={inputIsFocused}>
        <div className={ns("tag-container__control-group")}>
          {React.cloneElement(selectAutocompletion, {
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
            inputId: inputId.current
          })}
        </div>
      </FormControl>
    </div>
  );
}
