import React, { ReactElement } from "react";
import ns from "../../utils/namespace";
import Icon from "../atoms/Icon";
import Button from "./button/Button";
import { useGetSvgPath } from "../../utils/svgPath";
import { ITagBase } from "../../ts/interfaces";
import classNames from "classnames";
interface Props {
  tagObj: ITagBase;
  fullWidth?: boolean;
  isRemovable?: boolean;
  isActive?: boolean;
  getActionLabel?: (tagObj: ITagBase) => string;
  handleUnchoose?: (tagName: ITagBase) => void;
  handleClick?: (tagObj: ITagBase) => void;
}

export default function Tag({
  tagObj,
  fullWidth,
  isRemovable = true,
  isActive,
  getActionLabel,
  handleUnchoose,
  handleClick
}: Props): ReactElement {
  const handleUnchooseClick = handleUnchoose
    ? () => {
        handleUnchoose(tagObj);
      }
    : undefined;

  const deleteIconSVGPath = useGetSvgPath("#delete");

  return (
    <span
      className={classNames(ns("tag"), {
        [`${ns("tag")}--removable`]: isRemovable,
        [`${ns("tag")}--active`]: isActive
      })}
    >
      <span
        className={classNames(ns("tag__label"), {
          [ns("tag__label--full-width")]: fullWidth
        })}
        title={tagObj.title ?? tagObj.name}
      >
        {tagObj.name}
      </span>
      {handleClick && (
        <Button
          handleClick={() => handleClick(tagObj)}
          className={ns("tag__action")}
          label={getActionLabel ? getActionLabel(tagObj) : tagObj.title ?? tagObj.name}
          srOnly
        />
      )}
      <div className={ns("tag__background")} />
      {isRemovable && (
        <Button
          handleClick={handleUnchooseClick}
          className={ns("btn--pill btn--icon tag__delete")}
          label="Auswahl entfernen"
          srOnly
          icon={
            <Icon className={ns("btn__icon")} width={8} height={8} fill="none">
              <use xlinkHref={deleteIconSVGPath}></use>
            </Icon>
          }
        />
      )}
    </span>
  );
}
