import React, { ReactElement } from "react";
import classNames from "classnames";
import ns from "../../utils/namespace";

interface Props {
  className?: string;
  top?: number;
  width: number;
  height: number;
  children: ReactElement;
  fill?: string;
  ariaHidden?: boolean;
}

export default function Icon({ className, top, width, height, children, fill, ariaHidden }: Props): ReactElement {
  return (
    <svg
      className={classNames(ns("icon"), className)}
      width={width}
      height={height}
      viewBox={`0 ${top || 0} ${width} ${height}`}
      {...(fill ? { fill: fill } : {})}
      focusable="false"
      aria-hidden={ariaHidden}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
}
