import { configureStore, Action, DeepPartial } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { createRootReducer, RootState } from "./rootReducer";
import { IAppConfig } from "../utils/appSetup";
import { getPreloadedState as getPreloadedStateIndicatorsOrTopicsSlice } from "./indicatorsOrTopicsSlice";
import { getPreloadedState as getPreloadedStateregionsOrRegionFiltersSlice } from "./regionsOrRegionFiltersSlice";
import { getPreloadedState as getPreloadedStateYearsSlice } from "./yearsSlice";
import { getPreloadedState as getPreloadedStateUiSlice } from "./uiSlice";
import { initMemoizedSelectors } from "./memoizedSelectors";
import { initDynamicSlices } from "./dynamicSlices";

/**
 * Init and return a store for the React app.
 * Here the dummy `initialState` is overridden with the `preloadedState` based on appConfig from `data-js-wk-config`
 *
 * @param appConfig - config based on `data-js-wk-config`
 * @returns a redux store
 */
export const initStore = (appConfig: IAppConfig, appId: string) => {
  const preloadedState: DeepPartial<RootState> = {
    indicatorsOrTopics: getPreloadedStateIndicatorsOrTopicsSlice(appConfig),
    regionsOrRegionFilters: getPreloadedStateregionsOrRegionFiltersSlice(appConfig),
    years: getPreloadedStateYearsSlice(appConfig),
    ui: getPreloadedStateUiSlice(appConfig),
    appConfig: appConfig
  };

  initDynamicSlices(appId);
  const store = configureStore({
    reducer: createRootReducer(appId),
    preloadedState: preloadedState
  });
  initMemoizedSelectors(appId);

  return store;
};

export type AppDispatch = ReturnType<typeof initStore>["dispatch"];
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
