import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { AppThunk, AppDispatch } from "./store";
import { OutlineType, LayerType, IRegionOrIRegionFilter, IDataFeature, IPickerFeature } from "../ts/interfaces";
import { IAppConfig } from "../utils/appSetup";
import { getRelatedLayersForRegionOrRegionFilter, isRegionFilter } from "../utils/helpers";
import last from "lodash/last";
import { resetAllFilters, setRegionFilterInEditing, stopEditRegionFilter } from "./filtersSlice";
import { setAreYearsPlaying } from "./yearsSlice";
import { trackActionType, trackAction } from "../utils/eTracker";

interface IModalState {
  modalIsOpen: boolean | "regions" | "indicatorsOrTopics";
}

interface IIncludeComparisonYearState {
  includeComparisonYear: boolean;
}

interface ICompareGenerationsState {
  compareGenerations: boolean;
}

interface IHighlightedElementsState {
  highlightedElements: Array<String>;
}

interface IActiveLeftRightState {
  activeLeftRight: "left" | "right";
}

// We need to sync the scroll position of the Indicators between the different renderers
interface IScrollPositionState {
  scrollTop: number;
  offset: number;
  comesFrom: "Table" | "IndicatorOrTopicList" | null;
}

interface IShowRegionMarkersState {
  showRegionMarkers: boolean;
}

interface IActiveOutlineState {
  activeOutline: OutlineType;
}

interface IActiveLayerState {
  activeLayer: LayerType;
}

interface IActivePickerFeatureState {
  activePickerFeature: IPickerFeature | null;
}

interface IActiveDataFeatureState {
  activeDataFeature: IDataFeature | null;
}

interface IActiveRegionOrRegionFilterState {
  activeRegionOrRegionFilter: IRegionOrIRegionFilter | null;
}

interface IRemarkState {
  remarkIsOpen: boolean;
  remarkText: string | undefined;
}

interface IUiState
  extends IModalState,
    IIncludeComparisonYearState,
    ICompareGenerationsState,
    IHighlightedElementsState,
    IActiveLeftRightState,
    IShowRegionMarkersState,
    IActiveOutlineState,
    IActiveLayerState,
    IActivePickerFeatureState,
    IActiveDataFeatureState,
    IActiveRegionOrRegionFilterState,
    IRemarkState {
  scrollPosition: IScrollPositionState;
}

// The real "initial" state comes from the preloadedState below
// which is based on the dataConfig from `data-js-wk-config`.
// However, TypeScript requirements force us to still have valid `initialState`
// for `createSlice`. This is just useless dummy data that will never be used at runtime
// See https://github.com/reduxjs/redux-toolkit/issues/873#issuecomment-862147028
const initialState: IUiState = {
  includeComparisonYear: false,
  compareGenerations: false,
  highlightedElements: [],
  modalIsOpen: false,
  activeLeftRight: "left",
  scrollPosition: {
    scrollTop: 0,
    offset: 0,
    comesFrom: null
  },
  showRegionMarkers: true,
  activeOutline: OutlineType.Bund,
  activeLayer: LayerType.Commune,
  activePickerFeature: null,
  activeDataFeature: null,
  activeRegionOrRegionFilter: null,
  remarkIsOpen: false,
  remarkText: undefined
};

export const getPreloadedState = (appConfig: IAppConfig): IUiState => {
  const lastRegionOrRegionFilter = last(appConfig.regionsAndRegionFilters);
  return {
    modalIsOpen: false,
    includeComparisonYear: appConfig.includeComparisonYear !== undefined && appConfig.includeComparisonYear,
    compareGenerations: appConfig.compareGenerations !== undefined && appConfig.compareGenerations,
    highlightedElements: appConfig.highlightedElements === undefined ? [] : appConfig.highlightedElements,
    activeLeftRight: "left",
    scrollPosition: {
      scrollTop: 0,
      offset: 0,
      comesFrom: null
    },
    showRegionMarkers: true,
    activeOutline: appConfig.mapOutline || OutlineType.Bund,
    activeLayer:
      (lastRegionOrRegionFilter && last(getRelatedLayersForRegionOrRegionFilter(lastRegionOrRegionFilter))) ||
      LayerType.Commune,
    activePickerFeature: null,
    activeDataFeature: null,
    activeRegionOrRegionFilter: null,
    remarkIsOpen: false,
    remarkText: undefined
  };
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    setModalIsOpen(state, action: PayloadAction<IModalState>) {
      const { modalIsOpen } = action.payload;

      // Need to reset left/right setting if modal is closing
      if (modalIsOpen === false) {
        state.activeLeftRight = "left";
      }

      state.modalIsOpen = modalIsOpen;
    },
    setIncludeComparisonYear(state, action: PayloadAction<IIncludeComparisonYearState>) {
      const { includeComparisonYear } = action.payload;
      state.includeComparisonYear = includeComparisonYear;
    },
    setCompareGenerations(state, action: PayloadAction<ICompareGenerationsState>) {
      const { compareGenerations } = action.payload;
      state.compareGenerations = compareGenerations;
    },
    setHighlightedElements(state, action: PayloadAction<IHighlightedElementsState>) {
      const { highlightedElements } = action.payload;
      state.highlightedElements = highlightedElements;
    },
    setActiveLeftRight(state, action: PayloadAction<IActiveLeftRightState>) {
      const { activeLeftRight } = action.payload;
      state.activeLeftRight = activeLeftRight;
    },
    setScrollPosition(state, action: PayloadAction<IScrollPositionState>) {
      const { scrollTop, offset, comesFrom } = action.payload;
      state.scrollPosition = { scrollTop, offset, comesFrom };
    },
    setShowRegionMarkers(state, action: PayloadAction<IShowRegionMarkersState>) {
      const { showRegionMarkers } = action.payload;
      state.showRegionMarkers = showRegionMarkers;
    },
    setActiveOutline(state, action: PayloadAction<IActiveOutlineState>) {
      const { activeOutline } = action.payload;
      state.activeOutline = activeOutline;
    },
    setActiveLayer(state, action: PayloadAction<IActiveLayerState>) {
      const { activeLayer } = action.payload;
      state.activeLayer = activeLayer;
    },
    setActiveLayerByRegionOrRegionFilter(
      state,
      action: PayloadAction<{ regionOrRegionFilter: IRegionOrIRegionFilter }>
    ) {
      const { regionOrRegionFilter } = action.payload;
      const relatedLayers = getRelatedLayersForRegionOrRegionFilter(regionOrRegionFilter);
      if (!relatedLayers.includes(state.activeLayer)) {
        // new layer is most gross related layer
        state.activeLayer = last(relatedLayers) || state.activeLayer;
      }
    },
    setActivePickerFeature(state, action: PayloadAction<IActivePickerFeatureState>) {
      const { activePickerFeature: activeFeature } = action.payload;
      state.activePickerFeature = activeFeature;
      state.activeDataFeature = null;
    },
    setActiveDataFeature(state, action: PayloadAction<IActiveDataFeatureState>) {
      const { activeDataFeature: activeFeature } = action.payload;
      state.activeDataFeature = activeFeature;
      state.activePickerFeature = null;
    },
    setActiveRegionOrRegionFilter(state, action: PayloadAction<IActiveRegionOrRegionFilterState>) {
      const { activeRegionOrRegionFilter } = action.payload;
      state.activeRegionOrRegionFilter = activeRegionOrRegionFilter;
    },
    setRemarkText(state, action: PayloadAction<{ remarkText: string | undefined }>) {
      const { remarkText } = action.payload;
      state.remarkText = remarkText;
    },
    setRemarkIsOpen(state, action: PayloadAction<{ remarkIsOpen: boolean }>) {
      const { remarkIsOpen } = action.payload;
      state.remarkIsOpen = remarkIsOpen;
    }
  }
});

export const setModalIsOpen = (
  modalIsOpen: boolean | "regions" | "indicatorsOrTopics",
  action?: String
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setModalIsOpen({ modalIsOpen: modalIsOpen }));
  dispatch(setAreYearsPlaying(false));
  if (modalIsOpen === "regions") {
    trackAction(trackActionType.REGION_OVERLAY, action || "Sonstiges");
  } else if (modalIsOpen === "indicatorsOrTopics") {
    trackAction(trackActionType.TOPIC_OVERLAY, action || "Sonstiges");
  }
};

export const setIncludeComparisonYear = (includeComparisonYear: boolean): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    uiSlice.actions.setIncludeComparisonYear({
      includeComparisonYear: includeComparisonYear
    })
  );
  if (includeComparisonYear) {
    trackAction(trackActionType.YEAR_SELECTION, "Vergleichsjahr");
  }
};

export const setCompareGenerations = (compareGenerations: boolean): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    uiSlice.actions.setCompareGenerations({
      compareGenerations: compareGenerations
    })
  );
};

export const setHighlightedElements = (highlightedElements: String[]): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    uiSlice.actions.setHighlightedElements({
      highlightedElements: highlightedElements
    })
  );
};

export const closeModal = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setModalIsOpen(false));
  dispatch(resetAllFilters());
  dispatch(stopEditRegionFilter());
  dispatch(clearActiveFeature());
};

export const setActiveLeftRight = (activeLeftRight: "left" | "right"): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setActiveLeftRight({ activeLeftRight: activeLeftRight }));
};

export const setScrollPosition = ({ scrollTop, offset, comesFrom }: IScrollPositionState): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(uiSlice.actions.setScrollPosition({ scrollTop, offset, comesFrom }));
};

export const setShowRegionMarkers = ({ showRegionMarkers }: IShowRegionMarkersState): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(
    uiSlice.actions.setShowRegionMarkers({
      showRegionMarkers: showRegionMarkers
    })
  );
};

export const setActiveOutline = (activeOutline: OutlineType): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setActiveOutline({ activeOutline: activeOutline }));
};

export const setActiveLayer = (activeLayer: LayerType): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setActiveLayer({ activeLayer: activeLayer }));
};

export const setActiveLayerByRegionOrRegionFilter = (regionOrRegionFilter: IRegionOrIRegionFilter): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(
    uiSlice.actions.setActiveLayerByRegionOrRegionFilter({
      regionOrRegionFilter: regionOrRegionFilter
    })
  );
};

export const setActivePickerFeature = (feature: IPickerFeature | null): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setActivePickerFeature({ activePickerFeature: feature }));
  if (feature) {
    dispatch(setRegionFilterInEditing(null));
    dispatch(setActiveRegionOrRegionFilter(null));
  }
};

export const setActiveDataFeature = (feature: IDataFeature | null): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setActiveDataFeature({ activeDataFeature: feature }));
};

export const clearActiveFeature = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setActivePickerFeature(null));
  dispatch(setActiveDataFeature(null));
};

export const setActiveRegionOrRegionFilter = (regionOrRegionFilter: IRegionOrIRegionFilter | null): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(
    uiSlice.actions.setActiveRegionOrRegionFilter({
      activeRegionOrRegionFilter: regionOrRegionFilter
    })
  );
  if (regionOrRegionFilter) {
    dispatch(setActivePickerFeature(null));
    dispatch(setActiveLayerByRegionOrRegionFilter(regionOrRegionFilter));
    if (!isRegionFilter(regionOrRegionFilter)) {
      dispatch(setRegionFilterInEditing(null));
    }
  }
};

export const setRemarkText = (remarkText: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setRemarkText({ remarkText: remarkText }));
};

export const setRemarkIsOpen = (remarkIsOpen: boolean): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(uiSlice.actions.setRemarkIsOpen({ remarkIsOpen: remarkIsOpen }));
};

export const selectModalIsOpen = (state: RootState) => state.ui.modalIsOpen !== false;

export const selectIncludeComparisonYear = (state: RootState) => state.ui.includeComparisonYear;

export const selectHighlightedElements = (state: RootState) => state.ui.highlightedElements;

export const selectCompareGenerations = (state: RootState) => state.ui.compareGenerations;

export const selectWhichModalIsOpen = (state: RootState) => state.ui.modalIsOpen;

export const selectIsLeftActive = (state: RootState) => state.ui.activeLeftRight === "left";

export const selectScrollPosition = (state: RootState) => state.ui.scrollPosition;

export const selectShowRegionMarkers = (state: RootState) => state.ui.showRegionMarkers;

export const selectActiveOutline = (state: RootState) => state.ui.activeOutline;

export const selectActiveLayer = (state: RootState) => state.ui.activeLayer;

export const selectActivePickerFeature = (state: RootState) => state.ui.activePickerFeature;

export const selectActiveDataFeature = (state: RootState) => state.ui.activeDataFeature;

export const selectActiveRegionOrRegionFilter = (state: RootState) => state.ui.activeRegionOrRegionFilter;

export const selectRemarkText = (state: RootState) => state.ui.remarkText;

export const selectRemarkIsOpen = (state: RootState) => state.ui.remarkIsOpen;

export default uiSlice.reducer;
