import classNames from "classnames";
import React, { FunctionComponent, ReactElement } from "react";
import ns from "../../../utils/namespace";
import OrderedList from "../../atoms/OrderedList";

export interface ILegendItemBase {
  label: string;
  title?: string;
  inactive?: boolean;
}

type LegendItemProps<ILegendItem extends ILegendItemBase> = ILegendItem & {
  index: number;
};

interface Props<ILegendItem extends ILegendItemBase> {
  items: Array<ILegendItem>;
  itemComponent: FunctionComponent<LegendItemProps<ILegendItem>>;
  className?: string;
  onItemHoverIn?: (item: ILegendItem) => void;
  onItemHoverOut?: (item: ILegendItem) => void;
  onItemClick?: (item: ILegendItem) => void;
}

export default function Legend<ILegendItem extends ILegendItemBase>({
  items,
  itemComponent: LegendItem,
  className,
  onItemHoverIn,
  onItemHoverOut,
  onItemClick
}: Props<ILegendItem>): ReactElement {
  return (
    <OrderedList className={classNames(ns("legend"), className)}>
      {items.map((item, idx) => {
        const handleMouseEnter = onItemHoverIn
          ? () => {
              onItemHoverIn(item);
            }
          : undefined;
        const handleMouseLeave = onItemHoverOut
          ? () => {
              onItemHoverOut(item);
            }
          : undefined;
        const handleClick = onItemClick
          ? () => {
              onItemClick(item);
            }
          : undefined;

        return (
          <li
            className={classNames(ns("legend__item"), {
              [ns("legend__item--clickable")]: handleClick
            })}
            key={`key_${item.label}_${idx}`}
          >
            <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
              <LegendItem {...item} index={idx} />
            </span>
          </li>
        );
      })}
    </OrderedList>
  );
}
