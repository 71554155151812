import React from "react";
import ns from "./utils/namespace";
import ModalArea from "./components/organisms/areas/ModalArea";
import SelectorArea from "./components/organisms/areas/SelectorArea";
import VisualizationArea from "./components/organisms/areas/VisualizationArea";
import FriendlyUrlHandler from "./utils/FriendlyUrlHandler";

export default function App() {
  return (
    <div className={ns("app")}>
      <FriendlyUrlHandler />
      <SelectorArea />
      <VisualizationArea />
      <ModalArea />
    </div>
  );
}
