import { createSlice } from "@reduxjs/toolkit";
import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import { IMapPickerData, IPickerFeature, LayerType } from "../ts/interfaces";
import { RootState } from "./rootReducer";
import createNonConcurrentAsyncThunk from "../utils/createNonConcurrentAsyncThunk";

interface IMapPickerDataState {
  loading: boolean;
  hasErrors: boolean;
  mapPickerData: IMapPickerData | null;
}

const initialState: IMapPickerDataState = {
  loading: false,
  hasErrors: false,
  mapPickerData: null
};

interface IFetchMapPickerDataArgs {
  apiUrl: string;
  bbox: string;
  regionIds: Array<number>;
  layer: LayerType;
}

export const fetchMapPickerData = createNonConcurrentAsyncThunk(
  "mapPicker/fetchMapPickerData",
  async ({ apiUrl, bbox, regionIds, layer }: IFetchMapPickerDataArgs) => {
    const requestBody: Partial<IFetchMapPickerDataArgs> = {
      bbox,
      regionIds,
      layer
    };
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    });

    const mapPickerData = await response.json();
    return mapPickerData as IMapPickerData;
  }
);

// A slice for mapPickerData with our three reducers
const mapPickerDataSlice = createSlice({
  name: "mapPickerData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMapPickerData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchMapPickerData.fulfilled, (state, action) => {
      const mapPickerData = action.payload;
      state.mapPickerData = mapPickerData;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchMapPickerData.rejected, (state, action) => {
      state.loading = false;
      // Only set errors if rejected was not called from our own abort in createNonConcurrentAsyncThunk
      if (!action.meta.aborted) {
        state.hasErrors = true;
      }
    });
  }
});

export const selectMapPickerData = (state: RootState) => state.mapPickerData;

/**********************************************
 * Begion of special handling of chosenFeatures:
 *
 * With every map zoom we request a new bbox and following that we also request new features
 * However, the chosen features (regions) stay mostly the same.
 * Hence we use an better compareFunction that is more intelligent than a simple reference comparison
 * that would always return "new" Array of chosenFeatures
 *
 **********************************************/

// Have to resort to `any` here due to bug https://github.com/reduxjs/reselect/issues/384
const compareFeaturesArrays = (featuresArrayA: any, featuresArrayB: any) => {
  if (featuresArrayA && featuresArrayB) {
    const idsA = featuresArrayA.map((feature: IPickerFeature) => feature.id);
    const idsB = featuresArrayB.map((feature: IPickerFeature) => feature.id);

    // do a deepcompare, not just reference compare
    const res = isEqual(idsA, idsB);

    return res;
  } else if (isUndefined(featuresArrayA) && isUndefined(featuresArrayB)) {
    return true;
  }

  // Either featuresArrayA or featuresArrayB is `undefined` while the other is not, so not equal
  return false;
};

// create a "selector creator" that uses lodash.isequal instead of ===
const createChosenFeaturesSelector = createSelectorCreator(defaultMemoize, compareFeaturesArrays);

export const createMapPickerDataMemoizedSelectors = () => {
  return {
    selectChosenFeatures: createChosenFeaturesSelector(
      (state: RootState) =>
        // the result of this selector will be checked using the more intelligent `compareFeaturesArrays` from above
        state.mapPickerData.mapPickerData?.regions?.features.filter((feature) => feature.properties.selected),
      (features: Array<IPickerFeature> | undefined) => {
        if (features) {
          return features;
        }
        return [];
      }
    )
  };
};

/**********************************************
 * End of special handling of chosenFeatures.
 **********************************************/

export default mapPickerDataSlice.reducer;
