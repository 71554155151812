import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { ReactElement, useRef } from "react";
import ns from "../../utils/namespace";

interface IRadioItem<RadioItemValue extends string> {
  value: RadioItemValue;
  label?: string;
  icon?: ReactElement;
  title?: string;
}

interface Props<RadioItemValue extends string> {
  items: IRadioItem<RadioItemValue>[];
  itemClassName?: string;
  value: RadioItemValue | undefined;
  onChange: (value: RadioItemValue) => void;
}

export default function RadioGroup<RadioItemValue extends string>({
  items,
  itemClassName,
  value,
  onChange
}: Props<RadioItemValue>): ReactElement {
  const radioGroupNameRef = useRef(`${ns("radio-group")}-${uniqueId()}`);

  return (
    <>
      {items.map((item) => {
        const itemId = `${radioGroupNameRef.current}_${item.value}`;
        const derivedItemLabel = item.label || item.value;

        const isItemActive = item.value === value;

        return (
          <span
            className={classNames(ns("radio-group__item"), itemClassName, {
              [`${itemClassName}--active`]: itemClassName && isItemActive
            })}
            key={itemId}
          >
            <input
              className={ns("radio-group__radio-input")}
              type="radio"
              name={radioGroupNameRef.current}
              id={itemId}
              value={item.value}
              checked={isItemActive}
              onChange={(event) => onChange(event.target.value as RadioItemValue)}
            />
            <label className={ns("radio-group__label")} htmlFor={itemId} title={item.title}>
              <div className={ns("radio-group__synthetic-radio-btn")} />
              {item.icon && <span className={ns("radio-group__icon")}>{item.icon}</span>}
              {derivedItemLabel}
            </label>
          </span>
        );
      })}
    </>
  );
}
