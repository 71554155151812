import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import head from "lodash/head";
import { chooseYears, selectChosenYears } from "../../../../redux/yearsSlice";
import ns from "../../../../utils/namespace";
import YearSlider from "../../../molecules/YearSlider";
import Chart, { CHART_COMPONENT_NAME } from "./Chart";
import { useAvailableYears } from "./useAvailableYears";
import { useYearsPlayback } from "./useYearsPlayback";
import PlayPauseButton from "../../../molecules/PlayPauseButton";
import { selectActiveRenderer } from "../../../../redux/indicatorsOrTopicsSlice";
import { selectIsStatisticsDataLoading } from "../../../../redux/statisticsDataSlice";
import { useAllYears } from "./useAllYears";

interface Props {
  shallRenderAside?: boolean;
  shallRenderSlider?: boolean;
  title?: string;
}

export default function ChartWithYearSlider({ shallRenderAside, shallRenderSlider = true, title }: Props) {
  const dispatch = useDispatch();
  const activeRenderer = useSelector(selectActiveRenderer);
  const isStatisticsDataLoading = useSelector(selectIsStatisticsDataLoading);

  const chosenYears = useSelector(selectChosenYears);
  const availableYears = useAvailableYears();
  const allYears = useAllYears(availableYears);

  const setCurrentYear = useCallback(
    (year: number) => {
      dispatch(chooseYears([year]));
    },
    [dispatch]
  );
  const setChosenYears = useCallback(
    (years: Array<number>) => {
      dispatch(chooseYears(years));
    },
    [dispatch]
  );
  const firstYear = head(chosenYears) as number;

  const { areYearsPlaying, wereYearsPlaying, handlePlayPauseClick } = useYearsPlayback(
    availableYears,
    firstYear,
    setCurrentYear,
    isStatisticsDataLoading
  );

  const fetchAllYears = !wereYearsPlaying && areYearsPlaying ? true : false;
  const yearsToFetch = useMemo(() => (fetchAllYears ? allYears : chosenYears), [allYears, chosenYears, fetchAllYears]);

  return (
    <Chart
      yearsToDisplay={chosenYears}
      yearsToFetch={yearsToFetch}
      allYears={allYears}
      availableYears={availableYears}
      title={title}
      shallRenderAside={shallRenderAside}
      footerContent={
        shallRenderSlider && availableYears.length > 1 ? (
          <div className={ns(`${CHART_COMPONENT_NAME}__year-chooser`)}>
            <YearSlider availableYears={availableYears} currentYears={chosenYears} setChosenYears={setChosenYears} />
            {activeRenderer === "BAR_CHART" && (
              <>
                <span className={ns(`${CHART_COMPONENT_NAME}__footer-separator`)} />
                <PlayPauseButton isPlaying={areYearsPlaying} handleClick={handlePlayPauseClick} />
              </>
            )}
          </div>
        ) : undefined
      }
    />
  );
}
