import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  MapContainer,
  ZoomControl
  // Only for debugging. Show an OSM map as background:
  // TileLayer,
  // Only used for debugging bbox with a red rectangle below:
  // FeatureGroup,
  // Rectangle,
} from "react-leaflet";
import { Map, PathOptions } from "leaflet";
import "leaflet/dist/leaflet.css";

import { IDataFeature } from "../../../../ts/interfaces";
import ns from "../../../../utils/namespace";
import StaticLegend from "../../../molecules/legend/StaticLegend";
import { useAddToolip } from "./useAddTooltip";
import { getColorsArray, getHoverColorsArray, getNoClassColor } from "./mapColors";
import { getRegionStyle } from "./getRegionStyle";
import MapEvents from "./MapEvents";
import RegionMarkers from "../../../molecules/map-utils/RegionMarkers";
import { useGetMapData } from "./useGetMapData";
import GeoJsonWithUpdates from "../../../molecules/map-utils/GeoJsonWithUpdates";
import FeaturesLayer from "../../../molecules/map-utils/FeaturesLayer";
import useStableCallback from "../../../../utils/useStableCallback";
import { selectAreIndicatorsAvailable, selectStatisticType } from "../../../../redux/indicatorsOrTopicsSlice";
import { selectMapClasses } from "../../../../redux/mapDataSlice";
import InteractiveLegend from "../../../molecules/legend/InteractiveLegend";
import { useMemoizedSelectors } from "../../../../utils/reduxHooks";
import Loader from "../../../molecules/Loader";
import { getChosenFeatureStyle } from "./getChosenFeatureStyle";
import {
  clearActiveFeature,
  selectActiveDataFeature,
  selectShowRegionMarkers,
  setActiveDataFeature
} from "../../../../redux/uiSlice";
import { useRegionPopupAndTooltip } from "../../../molecules/map-utils/useRegionPopupAndTooltip";
import { getTooltipContent } from "./getTooltipContent";
import { useTooltipContentData } from "./useTooltipContentData";
import PlaceholderChartContent from "../chart/PlaceholderChartContent";
import classnames from "classnames";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import { isFullApp } from "../../../../utils/appSetup";
import { useClassIndexHighlighting } from "./useClassIndexHighlighting";
import MapConfigurator from "../../../molecules/MapConfigurator";

interface Props {
  chosenSingleYear: number;
  availableYears: number[];
  yearsToFetch: number[];
  areYearsPlaying: boolean;
  shallRenderPlaceholder?: boolean;
}

export default function MapContent({
  chosenSingleYear,
  availableYears,
  yearsToFetch,
  areYearsPlaying,
  shallRenderPlaceholder
}: Props): ReactElement | null {
  const dispatch = useDispatch();

  const { selectChosenFeatures } = useMemoizedSelectors().mapData;

  const chosenFeatures = useSelector(selectChosenFeatures);
  const statisticType = useSelector(selectStatisticType);
  const areIndicatorsAvailable = useSelector(selectAreIndicatorsAvailable);
  const mapClasses = useSelector(selectMapClasses);
  const appConfig = useSelector(selectAppConfig);
  const isInFullApp = isFullApp(appConfig);
  const showRegionMarkers = useSelector(selectShowRegionMarkers);

  const activeFeature = useSelector(selectActiveDataFeature);

  const [map, setMap] = useState<Map | null>(null);
  const [isMapCreated, setIsMapCreated] = useState(false);

  const currentZoom = map?.getZoom();

  const {
    mapBbox,
    bboxLoading,
    bboxHasErrors,
    mapData,
    mapDataLoading,
    mapDataHasErrors,
    currentYearIndex
  } = useGetMapData(map, isMapCreated, chosenSingleYear, availableYears, yearsToFetch);

  const [colorsArr, hoverColorsArr] = useMemo(() => {
    const colorSchema = mapData?.indicator?.colorSchema;
    return [getColorsArray(colorSchema), getHoverColorsArray(colorSchema)];
  }, [mapData?.indicator?.colorSchema]);
  const noClassColor = useMemo(() => getNoClassColor(mapData?.type), [mapData?.type]);

  const {
    highlightedClassIndices,
    handleClassIndexHoverIn,
    handleClassIndexHoverOut,
    handleClassIndexClick
  } = useClassIndexHighlighting(mapClasses);

  const regionStyleCallback = useCallback(
    (feature: IDataFeature | undefined, isHovered?: boolean): PathOptions => {
      return getRegionStyle(
        feature,
        mapData,
        currentYearIndex,
        colorsArr,
        noClassColor,
        mapClasses,
        highlightedClassIndices,
        currentZoom,
        hoverColorsArr,
        isHovered
      );
    },
    [
      mapData,
      currentYearIndex,
      colorsArr,
      noClassColor,
      mapClasses,
      highlightedClassIndices,
      currentZoom,
      hoverColorsArr
    ]
  );

  const addTooltip = useAddToolip(
    mapData,
    currentYearIndex,
    (statisticType && !areIndicatorsAvailable) || false,
    regionStyleCallback
  );

  const setActiveFeature = useCallback(
    (feature: IDataFeature | null) => {
      dispatch(setActiveDataFeature(feature));
    },
    [dispatch]
  );

  // Clear active feature when statistic type changes
  useEffect(() => {
    dispatch(clearActiveFeature());
  }, [dispatch, statisticType]);

  const tooltipContentData = useTooltipContentData(mapData, currentYearIndex);
  const renderPopupContent = useCallback(
    (activeFeature: IDataFeature) =>
      getTooltipContent(
        activeFeature,
        currentYearIndex,
        (statisticType && !areIndicatorsAvailable) || false,
        tooltipContentData,
        ns("region-popup")
      ),
    [areIndicatorsAvailable, currentYearIndex, statisticType, tooltipContentData]
  );

  const { onEachFeatureCallback, renderedRegionPopup } = useRegionPopupAndTooltip(
    map,
    activeFeature,
    addTooltip,
    setActiveFeature,
    renderPopupContent,
    ns("statistics-map__region-popup"),
    true
  );

  const stableOnEachFeatureCallback = useStableCallback(onEachFeatureCallback);

  const chosenFeatureStyleCallback = useCallback(
    (feature: IDataFeature | undefined): PathOptions => {
      return getChosenFeatureStyle(feature, currentZoom);
    },
    [currentZoom]
  );

  // Things related to mapData (e.g. the legend) may change the height of the map container,
  // therefore we invalidate size of map once all mapData related things are drawn
  // else map's position would be related to old invalid size
  useEffect(() => {
    if (map && mapData) {
      map.invalidateSize();
    }
  }, [map, mapData]);

  if (!mapBbox && bboxLoading) {
    // first time loading
    return (
      <div className={ns("chart__content")}>
        <Loader isCentered />
      </div>
    );
  } else if (bboxHasErrors) {
    return (
      <div className={ns("chart__content")}>
        <p>
          <strong>
            Leider gab es einen Fehler bei der Verbindung mit dem Kartendienst! Bitte versuchen Sie es später erneut.
          </strong>
        </p>
      </div>
    );
  } else if (mapDataHasErrors) {
    return (
      <div className={ns("chart__content")}>
        <p>
          <strong>Error: Could not load map data…</strong>
        </p>
      </div>
    );
  } else if (mapBbox) {
    return (
      <>
        {shallRenderPlaceholder && <PlaceholderChartContent />}
        <div
          className={classnames(ns("chart__content"), {
            [ns("chart__content--hidden")]: shallRenderPlaceholder
          })}
        >
          <div className={ns("chart__loading-area")}>
            <MapContainer
              className={ns("map-container")}
              zoomControl={false}
              attributionControl={false}
              minZoom={5}
              maxZoom={11}
              whenCreated={(map) => {
                setMap(map);
                setIsMapCreated(true);
              }}
              // Setting tap to false for fixing https://issues.init.de/browse/BSTWK-1271?focusedCommentId=2457209&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2457209
              // which is caused by https://github.com/Leaflet/Leaflet/issues/7255, fix was proposed here https://stackoverflow.com/questions/67406533/react-leaflet-popups-not-working-on-mobile-devices
              // May be removed once leaflet is updated to 1.8.0 or above
              tap={false}
            >
              <MapEvents />
              <ZoomControl
                position="bottomright"
                zoomInText=""
                zoomInTitle="Vergrößern"
                zoomOutText=""
                zoomOutTitle="Verkleinern"
              />
              {/* Only for debugging: Show an OSM map as background */}
              {/* <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              /> */}
              {mapData?.regions && !isEmpty(mapData?.regions) && (
                <>
                  <GeoJsonWithUpdates
                    data={mapData.regions}
                    onEachFeature={stableOnEachFeatureCallback}
                    style={regionStyleCallback}
                  />
                  {/* Enable to show a red rectangle around the bbox for better debugging */}
                  {/* {bounds && (
                    <FeatureGroup>
                      <Rectangle
                        bounds={bounds}
                        pathOptions={{
                          color: "#ff0000",
                          weight: 1,
                          fillOpacity: 0,
                        }}
                      />
                    </FeatureGroup>
                  )} */}
                </>
              )}

              {mapData?.containerRegions && !isEmpty(mapData?.containerRegions) && (
                <GeoJsonWithUpdates
                  data={mapData.containerRegions}
                  interactive={false}
                  pathOptions={{
                    // $color-mineshaft
                    color: "#1f1f1f",
                    weight: 1,
                    fillOpacity: 0
                  }}
                />
              )}
              {mapData?.outlineRegions && !isEmpty(mapData?.outlineRegions) && (
                <GeoJsonWithUpdates
                  data={mapData.outlineRegions}
                  interactive={false}
                  pathOptions={{
                    // $color-harlequin
                    color: "#08e803",
                    weight: 1,
                    fillOpacity: 0
                  }}
                />
              )}
              {chosenFeatures && !isEmpty(chosenFeatures) && (
                <>
                  <FeaturesLayer features={chosenFeatures} style={chosenFeatureStyleCallback} />
                  {showRegionMarkers && isInFullApp && !areYearsPlaying && (
                    <RegionMarkers chosenFeatures={chosenFeatures} />
                  )}
                </>
              )}
              {isInFullApp && activeFeature && (
                <>
                  <FeaturesLayer
                    features={[activeFeature]}
                    pathOptions={{
                      // $color-dodger
                      fillColor: "#2876ff",
                      fillOpacity: 1
                    }}
                    style={regionStyleCallback}
                  />
                  {renderedRegionPopup}
                </>
              )}
              <MapConfigurator className={ns("statistics-map__configurator")} />
            </MapContainer>
            {(bboxLoading || mapDataLoading) && <Loader isCentered className={ns("chart__content-loader")} />}
          </div>
          {!shallRenderPlaceholder &&
            !isEmpty(mapClasses) &&
            (areIndicatorsAvailable ? (
              <StaticLegend
                items={mapClasses.map((classItem) => ({
                  ...classItem,
                  // classIndex is a logical index starting with 1 not 0 and might not be sequential
                  // classes.length can also be smaller than color array length
                  color: colorsArr[classItem.classIndex - 1]
                }))}
                highlightedClassIndices={highlightedClassIndices}
                noClassLabel="keine Angabe"
                noClassColor={noClassColor}
                onItemHoverIn={(item) => {
                  handleClassIndexHoverIn(item.classIndex);
                }}
                onItemHoverOut={(item) => handleClassIndexHoverOut(item.classIndex)}
                onItemClick={(item) => handleClassIndexClick(item.classIndex)}
              />
            ) : (
              <InteractiveLegend
                items={mapClasses.map((classItem) => ({
                  ...classItem,
                  // classIndex is a logical index starting with 1 not 0 and might not be sequential
                  // classes.length can also be smaller than color array length
                  color: colorsArr[classItem.classIndex - 1]
                }))}
                toggleAllLabel="Alle"
              />
            ))}
        </div>
      </>
    );
  } else {
    return null;
  }
}
