import React, { ReactElement, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../../redux/appConfigSlice";
import { isFullApp } from "../../../utils/appSetup";
import ns from "../../../utils/namespace";
import RemarkDropdown from "../../molecules/RemarkDropdown";
import classNames from "classnames";
import DownloadDropdown from "../../molecules/DownloadDropdown";
import Loader from "../../molecules/Loader";
import IndicatorOrTopicList from "./IndicatorOrTopicList";
import { useGetStatisticsData } from "./chart/useGetStatisticsData";
import { useAvailableYears } from "./chart/useAvailableYears";
import { useAllYears } from "./chart/useAllYears";
import { CellProps, useBlockLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import isEmpty from "lodash/isEmpty";
import { chooseSingleYear } from "../../../redux/yearsSlice";
import YearPicker from "../../molecules/YearPicker";
import { useChosenSingleYear } from "./chart/useChosenSingleYear";
import { selectActiveIndicator } from "../../../redux/indicatorsOrTopicsSlice";
import { getFormattedValue } from "../../../utils/helpers";
import isNumber from "lodash/isNumber";
import { useTopLowRegions } from "./chart/useTopLowRegions";
import first from "lodash/first";

type ICellValue = {
  value: string;
  title?: string;
};

export default function GeographicMigrationTable(): ReactElement {
  const dispatch = useDispatch();

  const activeIndicator = useSelector(selectActiveIndicator);
  // we store activeIndicator as a ref to use it in the memo down below without involving it in the dependency array
  const activeIndicatorRef = useRef(activeIndicator);
  activeIndicatorRef.current = activeIndicator;

  const availableYears = useAvailableYears();
  const allYears = useAllYears(availableYears);
  const { chosenSingleYear } = useChosenSingleYear();
  const yearsToFetch = useMemo(() => (chosenSingleYear ? [chosenSingleYear] : []), [chosenSingleYear]);

  const appConfig = useSelector(selectAppConfig);
  const isInFullApp = isFullApp(appConfig);

  const {
    topRegionsCount,
    hasTopXorLowRegionsCount,
    areTopLowRegionsAvailableAndUseful,
    topLowRegionsRadioGroup
  } = useTopLowRegions();

  const { statisticsData, statisticsDataArgs, isLoading, hasErrors } = useGetStatisticsData(allYears, yearsToFetch);

  const columns = useMemo(() => {
    const infoMultiCol = {
      Header: "",
      id: "info",
      sticky: "left",
      columns: [
        {
          Header:
            areTopLowRegionsAvailableAndUseful &&
            // either topRegionsCounts or lowRegionsCount needs to be defined (xor), not both defined or undefined
            hasTopXorLowRegionsCount
              ? topRegionsCount
                ? "WANDERUNGSGEWINNE"
                : "WANDERUNGSVERLUSTE"
              : "KOMMUNEN",
          accessor: "comparisonRegion",
          width: "250"
        }
      ]
    };
    const activeIndicator = activeIndicatorRef.current;
    const receivedIndicator = first(statisticsData?.indicators);
    const requestedYear = first(statisticsDataArgs?.years);
    if (
      !statisticsData ||
      !statisticsDataArgs ||
      !activeIndicator ||
      !requestedYear ||
      activeIndicator.id !== receivedIndicator?.id
    ) {
      return [infoMultiCol];
    }

    const cellRenderer = ({ value }: CellProps<any, ICellValue>) => {
      return (
        <span title={value?.title} className={classNames(ns("statistics-table__cell-value"))}>
          {value ? (isNumber(value.value) ? getFormattedValue(activeIndicator, value.value) : value.value) : ""}
        </span>
      );
    };
    const yearCol = {
      Header: requestedYear,
      id: `year${requestedYear}`,
      columns: [
        {
          Header: (
            <div>
              {receivedIndicator.name}
              <br />
              <span className={ns("statistics-table__unit")}>({receivedIndicator.unit})</span>
            </div>
          ),
          accessor: `indi${receivedIndicator.id}Year${requestedYear}`,
          width: "190",
          Cell: cellRenderer
        }
      ]
    };

    if (statisticsData.indicators.length > 1) {
      const furtherInfoMultiCol = {
        Header: "ergänzende Informationen",
        id: "further-info",
        columns: statisticsData.indicators
          .filter((_, idx) => idx > 0)
          .map((indi) => {
            return {
              Header: indi.name,
              accessor: `indi${indi.id}Year${requestedYear}`,
              width: "190",
              Cell: cellRenderer
            };
          })
      };
      return [infoMultiCol, yearCol, furtherInfoMultiCol];
    }

    return [infoMultiCol, yearCol];
  }, [
    areTopLowRegionsAvailableAndUseful,
    hasTopXorLowRegionsCount,
    statisticsData,
    statisticsDataArgs,
    topRegionsCount
  ]);

  const data = useMemo(() => {
    if (statisticsData && statisticsDataArgs) {
      return statisticsData.regions.map((region, regionIndex) => {
        return {
          comparisonRegion: region.name,
          ...statisticsData.indicators.reduce((indiYearValueObject, indicator) => {
            const yearValues = indicator.regionYearValues[regionIndex] as number[];
            yearValues.forEach((yearValue, yearIndex) => {
              indiYearValueObject[`indi${indicator.id}Year${statisticsDataArgs.years[yearIndex]}`] = {
                value: yearValue !== null ? yearValue : "k.A.",
                title: yearValue === null ? "keine Angaben" : undefined
              };
            });
            return indiYearValueObject;
          }, {} as any)
        };
      });
    }
    return [];
  }, [statisticsData, statisticsDataArgs]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useBlockLayout,
    useSticky
  );

  return (
    <div className={ns("chart", "chart--geographic-migration")}>
      <div className={ns("row")}>
        {isInFullApp && (
          <div className={ns("col chart__aside")}>
            <IndicatorOrTopicList />
          </div>
        )}
        <div
          {...getTableProps()}
          className={ns("col chart__main statistics-table statistics-table--geographic-migration")}
        >
          {isInFullApp && (
            <div className={ns("remark-download-panel")}>
              <RemarkDropdown className={classNames(ns("remark-download-panel__remark"))} />
              <DownloadDropdown className={classNames(ns("remark-download-panel__download"))} title="Daten" />
            </div>
          )}
          {hasErrors ? (
            <p>
              <strong>
                Leider gab es einen Fehler bei der Verbindung mit dem Statistikdienst! Bitte versuchen Sie es später
                erneut.
              </strong>
            </p>
          ) : (
            <>
              <div className={ns("statistics-table__scrollcontent-wrapper")}>
                <div className={ns("statistics-table__scrollcontent")}>
                  <div className={ns("statistics-table__header")}>
                    {
                      // Loop over the header rows
                      headerGroups.map((headerGroup) => (
                        // Apply the header row props
                        <div {...headerGroup.getHeaderGroupProps()} className={ns("statistics-table__tr")}>
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map((column) => (
                              // Apply the header cell props
                              <div
                                {...column.getHeaderProps()}
                                className={classNames(ns("statistics-table__td"), {
                                  [ns("statistics-table__th--first-cell-in-year")]:
                                    !column.parent || first(column.parent.columns)?.id === column.id
                                })}
                              >
                                {
                                  // Render the header
                                  column.render("Header")
                                }
                              </div>
                            ))
                          }
                        </div>
                      ))
                    }
                  </div>
                  <div {...getTableBodyProps()} className={ns("statistics-table__body")}>
                    {
                      // Loop over the table rows
                      rows.map((row) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <div {...row.getRowProps()} className={ns("statistics-table__tr")}>
                            {
                              // Loop over the rows cells
                              row.cells.map((cell) => {
                                // Apply the cell props
                                return (
                                  <div
                                    {...cell.getCellProps()}
                                    className={classNames(
                                      ns("statistics-table__td statistics-table__th--first-cell-in-year")
                                    )}
                                  >
                                    {
                                      // Render the cell contents
                                      cell.render("Cell")
                                    }
                                  </div>
                                );
                              })
                            }
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                {isLoading && <Loader isCentered />}
              </div>
              <div className={ns("chart__footer")}>
                {topLowRegionsRadioGroup}
                {chosenSingleYear && (
                  <YearPicker
                    availableYears={availableYears}
                    currentYear={chosenSingleYear}
                    setChosenSingleYear={(year) => dispatch(chooseSingleYear(year))}
                  />
                )}
              </div>
              {!isEmpty(statisticsData?.source) && (
                <div className={ns("chart__content-sources")}>
                  Quelle: {statisticsData?.source} - <a href="/dois">DOIs</a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
