import uniqueId from "lodash/uniqueId";
import React, { ReactElement, useRef } from "react";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import { IMapSwitcherItem, IMapSwitcherItemValue } from "./MapSwitcher";

type Props<MapSwitcherItemValue extends IMapSwitcherItemValue> = IMapSwitcherItem<MapSwitcherItemValue> & {
  isActive: boolean;
  groupName: string;
  onChange: (value: MapSwitcherItemValue) => void;
};

export default function MapSwitcherOptionItem<MapSwitcherItemValue extends IMapSwitcherItemValue>({
  label,
  value,
  isActive,
  groupName,
  iconId,
  onChange: handleChange
}: Props<MapSwitcherItemValue>): ReactElement {
  const itemId = useRef(`${ns("map-switcher-option-item")}-${uniqueId()}`);

  return (
    <div className={ns("map-switcher-option-item")}>
      <input
        type="radio"
        value={value}
        name={groupName}
        checked={isActive}
        id={itemId.current}
        className={ns("map-switcher-option-item__radio")}
        onChange={() => handleChange(value)}
      />
      <label className={ns("map-switcher-option-item__label")} htmlFor={itemId.current}>
        <Icon className={ns("map-switcher-option-item__icon")} width={27} height={35} fill="none">
          <use xlinkHref={useGetSvgPath(iconId)}></use>
        </Icon>
        {label}
      </label>
    </div>
  );
}
