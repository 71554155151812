import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

import { IAppConfig, AppType } from "../utils/appSetup";
import { OutlineType } from "../ts/interfaces";

// The real "initial" state comes from the preloadedState in `store.tsx`
// which is based on the dataConfig from `data-js-wk-config`.
// However, TypeScript requirements force us to still have valid `initialState`
// for `createSlice`. This is just useless dummy data that will never be used at runtime
// See https://github.com/reduxjs/redux-toolkit/issues/873#issuecomment-862147028
const initialState: IAppConfig = {
  appType: AppType.Full,
  indicatorsAndTopics: [],
  activeSelection: {
    id: 0,
    name: "dummy-string-name",
    title: "dummy-string-title",
    type: "dummy-string-type",
    friendlyUrl: "dummy-string-friendlyUrl",
    indicators: [],
    topics: []
  },
  regionsAndRegionFilters: [],
  years: [],
  renderer: "TABLE",
  mapOutline: OutlineType.Bund,
  types: [],
  urls: {
    apiIndicatorAutocompletion: "dummy-string-apiIndicatorAutocompletion",
    apiIndicatorOrTopicAutocompletion: "dummy-string-apiIndicatorOrTopicAutocompletion",
    apiMapBbox: "dummy-string-apiMapBbox",
    apiMapData: "dummy-string-apiMapData",
    apiRegionAutocompletion: "dummy-string-apiRegionAutocompletion",
    apiRegionFilter: "dummy-string-apiRegionFilterAutocompletion",
    apiStatisticsData: "dummy-string-apiStatisticsData",
    apiTopicAutocompletion: "dummy-string-apiTopicAutocompletion",
    apiTopicSets: "dummy-string-apiTopicSets",
    apiDemographicTypes: "dummy-string-apiDemographicTypes",
    apiDemographicTypeAutocompletion: "dummy-string-apiDemographicTypeAutocompletion",
    apiGkzAutocompletion: "dummy-string-apiGkzAutocompletion",
    apiPopulationRangeAutocompletion: "dummy-string-apiPopulationRangeAutocompletion",
    apiRegionTypeAutocompletion: "dummy-string-apiRegionTypeAutocompletion",
    svgSprite: "dummy-string-svgSprite"
  }
};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: initialState,
  reducers: {}
});

export const selectAppConfig = (state: RootState): IAppConfig => state.appConfig;

export default appConfigSlice.reducer;
