import React, { ReactElement } from "react";
import Tag from "./Tag";
import { ITagBase } from "../../ts/interfaces";
interface Props {
  className: string;
  tags: Array<ITagBase>;
  handleUnchoose: (tagName: ITagBase) => void;
  isActive?: (tag: ITagBase) => boolean;
  isClickable?: (tag: ITagBase) => boolean;
  handleClick?: (tag: ITagBase) => void;
  getActionLabel?: (tag: ITagBase) => string;
}

export default function TagList({
  className,
  tags,
  handleUnchoose,
  isActive = () => false,
  handleClick,
  getActionLabel
}: Props): ReactElement {
  return (
    <>
      {(tags as Array<ITagBase>).map((t: ITagBase, idx: number) => (
        <li key={`key_${t.id}_${idx}`} className={className}>
          <Tag
            tagObj={t}
            handleUnchoose={handleUnchoose}
            isActive={isActive(t)}
            handleClick={handleClick}
            getActionLabel={getActionLabel}
          />
        </li>
      ))}
    </>
  );
}
