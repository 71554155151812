import React, { ReactElement } from "react";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import ns from "../../../utils/namespace";

interface Props {
  className?: string;
  id?: string;
  label: string;
  labelSrExtension?: string;
  title?: string;
  srOnly?: boolean;
  iconBefore?: ReactElement;
  icon?: ReactElement;
  ariaExpanded?: boolean;
  ariaControls?: string;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export default function Button({
  className,
  id,
  label,
  labelSrExtension,
  title,
  srOnly = false,
  iconBefore,
  icon,
  ariaExpanded,
  ariaControls,
  handleClick,
  disabled = false
}: Props): ReactElement {
  let derivedTitle;
  if (!isEmpty(title)) {
    derivedTitle = title;
  } else if (srOnly && !isEmpty(label)) {
    // If there's no `title` prop and we only have the label for screen-only (sr-only),
    // then we can use the label as a title mouseover popup
    derivedTitle = label;
  }

  return (
    <button
      className={classNames(ns("btn"), className)}
      id={id}
      disabled={disabled}
      {...(!isEmpty(derivedTitle) ? { title: derivedTitle } : {})}
      // Explicitly set aria-expanded attribute so also "false" is outputted and the attribute not only omitted
      {...(ariaExpanded ? { "aria-expanded": "true" } : { "aria-expanded": "false" })}
      {...(ariaControls ? { "aria-controls": ariaControls } : {})}
      {...(handleClick ? { onClick: handleClick } : {})}
    >
      {iconBefore}
      <span className={ns("btn__label")} {...(srOnly ? { "data-type": "sr-only" } : {})}>
        {label}
        {labelSrExtension && <span data-type="sr-only">{labelSrExtension}</span>}
      </span>
      {icon}
    </button>
  );
}
