import { useDispatch, useSelector } from "react-redux";
import { selectActiveRenderer } from "../../../../redux/indicatorsOrTopicsSlice";
import { selectCompareGenerations, setCompareGenerations } from "../../../../redux/uiSlice";
import ns from "../../../../utils/namespace";
import { CHART_COMPONENT_NAME } from "./Chart";
import InteractiveLegendItem from "../../../molecules/legend/InteractiveLegendItem";

export const useCompareGenerations = () => {
  const dispatch = useDispatch();

  const activeRenderer = useSelector(selectActiveRenderer);
  const compareGenerations = useSelector(selectCompareGenerations);

  return activeRenderer === "PYRAMID_CHART" ? (
    <InteractiveLegendItem
      label="Generationen"
      selected={compareGenerations}
      onToggle={() => dispatch(setCompareGenerations(!compareGenerations))}
      className={ns(`${CHART_COMPONENT_NAME}__checkbox`)}
    />
  ) : null;
};
