import classNames from "classnames";
import { ReactElement } from "react";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import Button from "./button/Button";

interface Props {
  isPlaying: boolean;
  playLabel?: string;
  pauseLabel?: string;
  className?: string;
  handleClick: (isPlaying: boolean) => void;
}

export default function PlayPauseButton({
  isPlaying,
  playLabel = "Abspielen",
  pauseLabel = "Pausieren",
  className,
  handleClick
}: Props): ReactElement {
  const iconHashId = isPlaying ? "#pause" : "#play";

  return (
    <Button
      handleClick={() => handleClick(isPlaying)}
      className={classNames(ns("play-pause-btn"), className, {
        [ns("play-pause-btn--playing")]: isPlaying
      })}
      label={isPlaying ? pauseLabel : playLabel}
      srOnly
      icon={
        <Icon
          width={19}
          height={19}
          // necessary to force react to rerender svg
          // see also https://stackoverflow.com/questions/55420535/react-svg-not-rerendering-on-attribute-update
          key={iconHashId}
        >
          <use xlinkHref={useGetSvgPath(iconHashId)}></use>
        </Icon>
      }
    />
  );
}
