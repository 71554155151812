import { ReactElement } from "react";
import { IIndicatorOrTopic } from "../../ts/interfaces";
import { isTopic } from "../../utils/helpers";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import Button from "./button/Button";
import Modal from "./Modal";

interface Props {
  isOpen: boolean;
  indicatorOrTopic: IIndicatorOrTopic;
  closeModal: () => void;
}

export default function IndicatorOrTopicInfoModal({ isOpen, indicatorOrTopic, closeModal }: Props): ReactElement {
  return (
    <Modal
      className={ns("modal--info")}
      closeModal={closeModal}
      isOpen={isOpen}
      contentLabel={`Informationen zu ${indicatorOrTopic.name}`}
    >
      <Button
        label="Schließen"
        className={ns("btn--icon modal__close-btn")}
        srOnly
        icon={
          <Icon className={ns("btn__icon")} width={24} height={24}>
            <use xlinkHref={useGetSvgPath("#close")}></use>
          </Icon>
        }
        handleClick={closeModal}
      />
      <p>
        <strong>{indicatorOrTopic.name}</strong>
      </p>
      {isTopic(indicatorOrTopic) ? (
        <div dangerouslySetInnerHTML={{ __html: indicatorOrTopic.explanation! }}></div>
      ) : (
        <p>{indicatorOrTopic.explanation}</p>
      )}
      {!isTopic(indicatorOrTopic) && <p>{indicatorOrTopic.calculation}</p>}
      <a href={`/methodik${isTopic(indicatorOrTopic) ? "" : "#" + indicatorOrTopic.friendlyUrl}`} target="blank">
        {isTopic(indicatorOrTopic) ? "Zur Methodik" : "Zur Übersicht aller Indikatoren"}
      </a>
    </Modal>
  );
}
