import { StatisticType } from "../../../../ts/interfaces";

const GREEN_COLORS = ["#9af895", "#64dc98", "#2fbf98", "#00a191", "#008384", "#006671", "#004a59"];

const GREEN_HOVER_COLORS = ["#87da83", "#58c185", "#29a885", "#008d7f", "#007374", "#005a63", "#00414e"];

const RED_BLUE_COLORS = ["#c61647", "#e96173", "#ff8f8d", "#e0d1aa", "#4f9de5", "#3b72b3", "#274882"];

const classColors: { [string: string]: string[] } = {
  GREEN_LIGHT_TO_DARK: GREEN_COLORS,
  GREEN_DARK_TO_LIGHT: [...GREEN_COLORS].reverse(),
  RED_TO_BLUE: RED_BLUE_COLORS,
  BLUE_TO_RED: [...RED_BLUE_COLORS].reverse(),
  DEMOGRAPHIC_TYPE: [
    "#c2d295",
    "#5e7173",
    "#f28e00",
    "#d7b426",
    "#a2c1bf",
    "#e56600",
    "#829b9b",
    "#969a5d",
    "#aa5b29",
    "#eada8c",
    "#576800"
  ]
};
const hoverClassColors: { [string: string]: string[] } = {
  GREEN_LIGHT_TO_DARK: GREEN_HOVER_COLORS,
  GREEN_DARK_TO_LIGHT: [...GREEN_HOVER_COLORS].reverse()
};

export const NO_CLASS_COLOR = "#999999";
export const DEMOGRAPHIC_TYPES_NO_CLASS_COLOR = "#ffffff";

export const getColorsArray = (colorSchema: string | undefined) => {
  if (colorSchema === undefined) {
    return classColors["DEMOGRAPHIC_TYPE"];
  }

  return classColors[colorSchema];
};

export const getHoverColorsArray = (colorSchema: string | undefined) => {
  if (colorSchema === undefined) {
    return undefined;
  }

  return hoverClassColors[colorSchema];
};

export const getNoClassColor = (type: StatisticType | undefined) => {
  switch (type) {
    case "DEMOGRAPHIC_TYPES": {
      return DEMOGRAPHIC_TYPES_NO_CLASS_COLOR;
    }
    default: {
      return NO_CLASS_COLOR;
    }
  }
};
