import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppConfig } from "../../redux/appConfigSlice";
import { chooseIndicatorOrTopic, unchooseIndicatorOrTopic } from "../../redux/indicatorsOrTopicsSlice";
import { IIndicator, ITopic } from "../../ts/interfaces";
import ns from "../../utils/namespace";
import { useMemoizedSelectors } from "../../utils/reduxHooks";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import OrderedList from "../atoms/OrderedList";
import Button from "./button/Button";
import Modal from "./Modal";

interface Props {
  isOpen: boolean;
  topic: ITopic;
  closeModal: () => void;
}

export default function TopicInfoModal({ isOpen, topic, closeModal }: Props): ReactElement {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const plusIconPath = useGetSvgPath("#plus");
  const minusIconPath = useGetSvgPath("#minus");

  const { selectChosenIndicators } = useMemoizedSelectors().indicatorsOrTopics;
  const chosenIndicators = useSelector(selectChosenIndicators);

  const { areAllIndicatorsActive, indicatorItems } = topic.indicators.reduce(
    (previousResult, indicator) => {
      const isIndicatorActive = chosenIndicators.some((cIndicator) => cIndicator.id === indicator.id);

      const handleIndicatorClick = () => {
        if (isIndicatorActive) {
          dispatch(unchooseIndicatorOrTopic(indicator, appConfig.types));
        } else {
          dispatch(chooseIndicatorOrTopic(indicator, appConfig.types));
        }
      };

      return {
        areAllIndicatorsActive: previousResult.areAllIndicatorsActive && isIndicatorActive,
        indicatorItems: previousResult.indicatorItems.concat({
          indicator,
          isIndicatorActive,
          handleIndicatorClick
        })
      };
    },
    {
      areAllIndicatorsActive: true,
      indicatorItems: [] as Array<{
        indicator: IIndicator;
        isIndicatorActive: boolean;
        handleIndicatorClick: () => void;
      }>
    }
  );

  return (
    <Modal
      className={ns("topic-info-modal", "modal--small")}
      closeModal={closeModal}
      isOpen={isOpen}
      contentLabel={`Informationen zu ${topic.name}`}
    >
      <div className={ns("topic-info-modal__header")}>
        <strong>{topic.name}</strong>
        <Button
          label="Schließen"
          className={ns("btn--icon", "modal__close-btn")}
          srOnly
          icon={
            <Icon className={ns("btn__icon")} width={24} height={24}>
              <use xlinkHref={useGetSvgPath("#close")}></use>
            </Icon>
          }
          handleClick={closeModal}
        />
      </div>
      <Button
        className={ns("btn--secondary", "topic-info-modal__action-btn")}
        label={`Alle Indikatoren ${areAllIndicatorsActive ? "abwählen" : "auswählen"}`}
        handleClick={() => {
          if (areAllIndicatorsActive) {
            topic.indicators.forEach((indicator) => {
              dispatch(unchooseIndicatorOrTopic(indicator, appConfig.types));
            });
          } else {
            dispatch(chooseIndicatorOrTopic(topic, appConfig.types));
          }
        }}
      />
      <OrderedList className={ns("topic-info-modal__indicator-list")}>
        {indicatorItems.map(({ isIndicatorActive, handleIndicatorClick, indicator }) => {
          return (
            <li key={indicator.id}>
              <div
                role="button"
                className={classNames(ns("topic-info-modal__indicator"), {
                  [ns("topic-info-modal__indicator--active")]: isIndicatorActive
                })}
                onClick={handleIndicatorClick}
              >
                <span>
                  {indicator.name}
                  {!isEmpty(indicator.unit) && ` (${indicator.unit})`}
                </span>
                <span className={ns("topic-info-modal__action-icon")}>
                  {isIndicatorActive ? (
                    <Icon top={4} width={18} height={11} key={minusIconPath}>
                      <use xlinkHref={minusIconPath}></use>
                    </Icon>
                  ) : (
                    <Icon width={12} height={12} key={plusIconPath}>
                      <use xlinkHref={plusIconPath}></use>
                    </Icon>
                  )}
                </span>
              </div>
            </li>
          );
        })}
      </OrderedList>
    </Modal>
  );
}
