export enum trackActionType {
  DOWNLOAD = "Downloads",
  TOPIC_SELECTION = "Themenauswahl",
  TOPIC_OVERLAY = "Themendialog öffnen",
  REGION_SELECTION = "Kommunenauswahl",
  REGION_OVERLAY = "Kommunendialog öffnen",
  RENDERER_SELECTION = "Darstellungsauswahl",
  YEAR_SELECTION = "Jahresauswahl"
}

declare class et_UserDefinedEvent {
  constructor(object: String, category: String, action: String, type: String);
}

declare function et_eC_Wrapper(params: any): void;

export const trackAction = (action: trackActionType, type: String, object?: String) => {
  const _window = window as any;
  if (_window && _window._etracker && _window.et_pagename) {
    _window._etracker.sendEvent(
      new et_UserDefinedEvent(
        object || _window.location.pathname.substring(_window.location.pathname.lastIndexOf("/") + 1),
        _window.et_pagename,
        action,
        type
      )
    );
  }
};

export const trackNewUrl = () => {
  const _window = window as any;
  if (_window && _window._etracker && _window.et_pagename) {
    if (et_eC_Wrapper) {
      et_eC_Wrapper({});
    }
  }
};
