import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { initStore } from "./redux/store";
import MiniStatisticsApp from "./MiniStatisticsApp";
import appSetups, { AppType } from "./utils/appSetup";
import { generateAppId } from "./utils/appId";

appSetups.forEach((appSetup, index) => {
  let Application;
  switch (appSetup.type) {
    case AppType.Full:
      Application = App;
      break;
    case AppType.Mini:
      Application = MiniStatisticsApp;
      break;
  }

  const appId = generateAppId(index);
  const store = initStore(appSetup.appConfig, appId);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Application />
      </Provider>
    </React.StrictMode>,
    appSetup.rootElement
  );
});
