import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import ns from "../../utils/namespace";
import { selectAppConfig } from "../../redux/appConfigSlice";
import { fetchTopicSets, selectTopicSets } from "../../redux/topicSetsSlice";
import TopicTile from "../molecules/TopicTile";
import { selectChosenIndicatorsOrTopics } from "../../redux/indicatorsOrTopicsSlice";
import { ITopic, IIndicatorOrTopic } from "../../ts/interfaces";
import { getCurrentlyChosenType, isTopic } from "../../utils/helpers";

const isActive = (chosenIndicatorOrTopics: Array<IIndicatorOrTopic>, topic: ITopic): boolean =>
  chosenIndicatorOrTopics.some((cIndicatorOrTopic) => isTopic(cIndicatorOrTopic) && cIndicatorOrTopic.id === topic.id);

export default function TopicSets(): ReactElement {
  const dispatch = useDispatch();
  const appConfig = useSelector(selectAppConfig);

  const { topicSets, loading, hasErrors } = useSelector(selectTopicSets);
  const apiUrl = appConfig.urls.apiTopicSets;

  useEffect(() => {
    if (apiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch TopicSets, apiUrl is: ", apiUrl);
      return;
    }
    dispatch(fetchTopicSets(apiUrl));
  }, [dispatch, apiUrl]);

  const chosenIndicatorOrTopics = useSelector(selectChosenIndicatorsOrTopics);
  const currentlyChosenType = getCurrentlyChosenType(chosenIndicatorOrTopics);

  if (!isEmpty(topicSets)) {
    return (
      <div className={ns("topic-sets")}>
        {topicSets.map((topicSet, idx) => (
          <React.Fragment key={`key_${topicSet.name}_${idx}`}>
            <strong className={ns("heading heading--3")}>{topicSet.name}</strong>
            <ul className={ns("topic-set")}>
              {topicSet.topics.map((topic, idx2) => (
                <li key={`key_${topic.id}_${idx2}`} className={ns("topic-set__item")}>
                  <TopicTile
                    topic={topic}
                    active={isActive(chosenIndicatorOrTopics, topic)}
                    combinable={currentlyChosenType === topic.type || isEmpty(currentlyChosenType)}
                  />
                </li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </div>
    );
  } else if (loading) {
    return <div className={ns("topic-sets")}>Die verfügbaren Themen werden geladen…</div>;
  } else if (hasErrors) {
    return (
      <div className={ns("topic-sets")}>
        <p>
          <strong>
            Leider gab es einen Fehler bei der Verbindung mit dem Themendienst! Bitte versuchen Sie es später erneut.
          </strong>
        </p>
      </div>
    );
  } else {
    return <div />;
  }
}
