import classNames from "classnames";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { setHighlightedElements } from "../../../../redux/uiSlice";
import { IDiagramData, IStatisticsData } from "../../../../ts/interfaces";
import ns from "../../../../utils/namespace";
import { CHART_COMPONENT_NAME } from "./Chart";

declare const Raphael: any;
declare const $: any;

interface Props {
  statisticsData?: IStatisticsData | null;
  diagramData?: IDiagramData;
  title?: string | null;
  hasErrors?: boolean;
}

export default function ChartContent({ statisticsData, diagramData, title, hasErrors }: Props) {
  const dispatch = useDispatch();
  const chartContentRef = useRef<HTMLDivElement | null>(null);

  const draw = useCallback(() => {
    const chartContentNode = chartContentRef.current;

    if (chartContentNode) {
      const $chartContentNode = $(chartContentNode);
      $chartContentNode.empty();
      const width = $chartContentNode.width();
      const height = $chartContentNode.height();
      const paper = new Raphael(chartContentNode, width, height);

      paper.drawChart(diagramData, title, (highlightedElements: String[]) =>
        dispatch(setHighlightedElements(highlightedElements))
      );
    }
  }, [diagramData, title, dispatch]);

  useEffect(() => {
    draw();
  }, [draw]);

  const onResize = useCallback(() => {
    draw();
  }, [draw]);

  useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 200,
    skipOnMount: true,
    targetRef: chartContentRef,
    onResize
  });

  const isRenderingPlaceholder = statisticsData === undefined && diagramData === undefined;
  const className = classNames(ns(`${CHART_COMPONENT_NAME}__content`), {
    [ns(`${CHART_COMPONENT_NAME}__content--placeholder`)]: isRenderingPlaceholder
  });

  if (hasErrors) {
    return (
      <div className={className}>
        <p>
          <strong>
            Leider gab es einen Fehler bei der Verbindung mit dem Statistikdienst! Bitte versuchen Sie es später erneut.
          </strong>
        </p>
      </div>
    );
  } else {
    return <div className={className} ref={chartContentRef}></div>;
  }
}
