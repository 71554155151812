import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import {
  selectAreIndicatorsAvailable,
  selectIsDemographicTypes,
  selectStatisticType
} from "../../../../redux/indicatorsOrTopicsSlice";
import { selectChosenRegionsOrRegionFilters } from "../../../../redux/regionsOrRegionFiltersSlice";
import { isFullApp } from "../../../../utils/appSetup";
import { selectChosenSingleYear } from "../../../../redux/yearsSlice";
import { StatisticType } from "../../../../ts/interfaces";

export const useChartTitle = (title?: string | null) => {
  const appConfig = useSelector(selectAppConfig);

  const regions = useSelector(selectChosenRegionsOrRegionFilters);
  const statisticType = useSelector(selectStatisticType);
  const isDemographicTypes = useSelector(selectIsDemographicTypes);
  const areIndicatorsAvailable = useSelector(selectAreIndicatorsAvailable);
  const chosenSingleYear = useSelector(selectChosenSingleYear);

  if (title || title === null) {
    return title;
  } else {
    return !isDemographicTypes && statisticType && !areIndicatorsAvailable && isFullApp(appConfig) && !isEmpty(regions)
      ? statisticType.name +
          (regions.length === 1 ? " - " + regions[0].name : "") +
          (statisticType.type === StatisticType.PopulationPyramid ||
          statisticType.type === StatisticType.AgeStructure ||
          statisticType.type === StatisticType.AgeStructureForecast
            ? " - " + chosenSingleYear
            : "")
      : null;
  }
};
