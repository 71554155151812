import { useSelector } from "react-redux";
import { selectActiveIndicator } from "../../../../redux/indicatorsOrTopicsSlice";
import {
  selectHasTopXorLowRegionsCount,
  selectIsTopLowRegionsCountUseful,
  selectLowRegionsCount,
  selectTopRegionsCount
} from "../../../../redux/regionsOrRegionFiltersSlice";
import ns from "../../../../utils/namespace";
import TopLowRegionsRadioGroup from "../../../molecules/TopLowRegionsRadioGroup";
import { CHART_COMPONENT_NAME } from "./Chart";

export const useTopLowRegions = () => {
  const activeIndicator = useSelector(selectActiveIndicator);
  const topRegionsCount = useSelector(selectTopRegionsCount);
  const lowRegionsCount = useSelector(selectLowRegionsCount);
  const hasTopXorLowRegionsCount = useSelector(selectHasTopXorLowRegionsCount);
  const isTopLowRegionsCountUseful = useSelector(selectIsTopLowRegionsCountUseful);
  const areTopLowRegionsAvailableAndUseful = activeIndicator?.topLowRegionsAvailable && isTopLowRegionsCountUseful;

  return {
    topRegionsCount,
    lowRegionsCount,
    hasTopXorLowRegionsCount,
    areTopLowRegionsAvailableAndUseful,
    topLowRegionsRadioGroup: areTopLowRegionsAvailableAndUseful ? (
      <TopLowRegionsRadioGroup className={ns(`${CHART_COMPONENT_NAME}__top-low-radios`)} />
    ) : null
  };
};
