import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import isNumber from "lodash/isNumber";
import get from "lodash/get";
import {
  IFeature,
  IIndicator,
  IIndicatorOrTopic,
  IRegion,
  IRegionFilter,
  IRegionOrIRegionFilter,
  IRenderer,
  ITagBase,
  ITopic,
  IStatisticType,
  LayerType,
  RegionType,
  StatisticType
} from "../ts/interfaces";
import last from "lodash/last";
import uniqBy from "lodash/uniqBy";

/**
 * Get the currently active type of all chosen topics/indicators for "combinable" filtering
 * All types in chosenIndicatorOrTopics must be the same, simply used the first one available
 * @returns {node} the DOM node
 */
export const getCurrentlyChosenType = (chosenIndicatorOrTopics: Array<IIndicatorOrTopic>): string => {
  const currentlyChosenType = get(chosenIndicatorOrTopics, "[0].type", "");
  return currentlyChosenType;
};

/**
 * Check if indicatorOrTopic is Topic
 * Only ITopic, not IIndicator has "indicators"
 */
export const isTopic = (indicatorOrTopic: IIndicatorOrTopic): indicatorOrTopic is ITopic =>
  has(indicatorOrTopic, "indicators");

/**
 * Check if tag is Region
 * Only isRegion, not IIndicatorOrTopic has "gkz"
 */
export const isRegion = (tag: ITagBase): tag is IRegion => has(tag, "gkz");

/**
 * Check if regionOrRegionFilter is RegionFilter
 * Only IRegionFilter, not IRegion has "regions"
 */
export const isRegionFilter = (regionOrRegionFilter: ITagBase): regionOrRegionFilter is IRegionFilter =>
  has(regionOrRegionFilter, "regions");

/**
 * Check if the given region filter contains only one region.
 *
 * @param regionsOrRegionsFilters references the selected regions
 */
export const isSingleRegion = (regionsOrRegionsFilters: IRegionOrIRegionFilter[]): boolean =>
  regionsOrRegionsFilters.length === 1 &&
  (!isRegionFilter(regionsOrRegionsFilters[0]) || regionsOrRegionsFilters[0].regions.length === 1);

/**
 * Retrieves the available indicators for a given indicator or topic
 *
 * @param indicatorAndTopic the selected indicator or topic
 * @returns an array of all associated indicators
 */
export const getAllIndicators = (indicatorOrTopic: IIndicatorOrTopic): Array<IIndicator> => {
  if (isTopic(indicatorOrTopic)) {
    return (indicatorOrTopic as ITopic).indicators;
  }
  return [indicatorOrTopic as IIndicator];
};

/**
 * Compares two year arrays for equalness.
 *
 * @param years1 the first array of years
 * @param years2 the second array of years
 * @returns true if both arrays contain the same years
 */
export const equalYears = (years1?: Array<number>, years2?: Array<number>): boolean => {
  if (!years1) {
    return !years2;
  }
  if (!years2) {
    return false;
  }
  if (years1.length !== years2.length) {
    return false;
  }
  for (let i = 0; i < years1.length; i++) {
    if (years1[i] !== years2[i]) {
      return false;
    }
  }
  return true;
};

/**
 * Retrieves the available years for a given array of indicators or topics.
 *
 * @param indicatorAndTopics the list of selected indicators or topics
 * @param allTypes all declared statistic types
 * @returns an array of available years for the passed `type`
 */
export const getAvailableYears = (
  indicatorAndTopics: Array<IIndicatorOrTopic>,
  allTypes: Array<IStatisticType>
): Array<number> => {
  if (indicatorAndTopics.length > 0) {
    // Merge the years of all topics and indicators
    const years: Array<number> = [];
    indicatorAndTopics.forEach((indicatorOrTopic) =>
      getAllIndicators(indicatorOrTopic).forEach((indicator) =>
        indicator.years.forEach((year) => {
          for (let i = 0; i < years.length; i++) {
            if (year === years[i]) {
              return;
            } else if (year < years[i]) {
              years.splice(i, 0, year);
              return;
            }
          }
          years.push(year);
        })
      )
    );
    const firstYears = getAllIndicators(indicatorAndTopics[0])[0].years;
    if (years.length > 0 && equalYears(years, firstYears)) {
      return firstYears;
    }

    // Use the years of the types
    const currentType = allTypes.find((t) => t.type === indicatorAndTopics[0].type);
    if (currentType) {
      return currentType.years;
    }
  }
  return [];
};

/**
 * Retrieves the supported renderer types (table, bar chart, map, etc) for a given indicatorOrTopic.type
 *
 * @param type the type as string
 * @returns an array of suppported renderers for the passed `type`
 */
export const getRendererTypes = (type: string, allTypes: Array<IStatisticType>): Array<IRenderer> => {
  const currentType = allTypes.find((t) => t.type === type);

  if (currentType) {
    return currentType.rendererTypes;
  } else {
    return [];
  }
};

export const getRendererIconProps = (renderer: IRenderer) => {
  switch (renderer) {
    case "BAR_CHART":
      return {
        id: "#diagram",
        width: 19,
        height: 16,
        label: "Diagramm"
      };
    case "LINE_CHART":
    case "MIGRATION_CHART":
      return {
        id: "#graph",
        width: 19,
        height: 16,
        label: "Graph"
      };
    case "TABLE":
      return {
        id: "#table",
        width: 19,
        height: 16,
        label: "Tabelle"
      };
    case "MAP":
      return {
        id: "#marker",
        width: 11,
        height: 16,
        label: "Karte"
      };
    case "RADAR_CHART":
      return {
        id: "#radar",
        width: 23,
        height: 20,
        label: "Netzdiagramm"
      };
    case "PYRAMID_CHART":
      return {
        id: "#pyramid",
        width: 12,
        height: 20,
        label: "Pyramidendiagramm"
      };
    case "DISTRIBUTION_CHART":
      return {
        id: "#distribution",
        width: 12,
        height: 20,
        label: "Verteilungsdiagramm"
      };
    default:
      return null;
  }
};

export const getRendererYearChooserType = (
  renderer: IRenderer,
  statisticType?: IStatisticType | null
): "slider" | "picker" => {
  switch (renderer) {
    case "DISTRIBUTION_CHART":
    case "PYRAMID_CHART":
    case "MAP":
      return "picker";
    default:
      return statisticType?.type === StatisticType.GeographicMigration ? "picker" : "slider";
  }
};

export const getFullType = (type: string, types: Array<IStatisticType>): IStatisticType | null => {
  return types.find((fullType) => fullType.type === type) || null;
};

export const hasIndicatorsAvailable = (topic: ITopic, types: Array<IStatisticType>): boolean | undefined => {
  return getFullType(topic.type, types)?.indicatorsAvailable;
};

/**
 * Returns layers given region can be displayed in, sorted from most detailed to most gross.
 * @param region the IRegion the LayerTypes shall be returned for
 * @returns layers given region can be displayed in
 */
export const getRelatedLayers = (region: IRegion): LayerType[] => {
  switch (region.type) {
    case RegionType.KreisfreieStadt:
      return [LayerType.Commune, LayerType.District].concat(
        // special case for Berlin and Hamburg which are registered as Kreisfreie Städte but are actually Stadtstaaten
        !region.parent || region.parent === "Deutschland" ? [LayerType.State] : []
      );
    case RegionType.Landkreis:
      return [LayerType.District];
    case RegionType.Stadtstaat:
    case RegionType.Bund:
      return [LayerType.Commune, LayerType.District, LayerType.State];
    case RegionType.Bundesland:
      return [LayerType.State];
    case RegionType.GroßeGemeinde:
    default:
      return [LayerType.Commune];
  }
};

export const getRelatedLayersForRegionOrRegionFilter = (regionOrRegionFilter: IRegionOrIRegionFilter): LayerType[] => {
  const region = isRegionFilter(regionOrRegionFilter) ? last(regionOrRegionFilter.regions) : regionOrRegionFilter;
  if (region) {
    return getRelatedLayers(region);
  }
  return [];
};

export const getFeaturesChosenViaRegion = (
  chosenFeatures: IFeature[],
  chosenRegionsOrRegionFilters: IRegionOrIRegionFilter[],
  limit?: number
): IFeature[] => {
  const filteredFeatures = chosenFeatures.filter((feature) =>
    chosenRegionsOrRegionFilters.some((el) => el.id === feature.id)
  );
  if (limit) {
    return filteredFeatures.slice(0, limit);
  } else {
    return filteredFeatures;
  }
};

export const getRegionIds = (...regionsOrRegionFilters: IRegionOrIRegionFilter[]): number[] => {
  return regionsOrRegionFilters.reduce((result, regionOrRegionFilter) => {
    if (isRegionFilter(regionOrRegionFilter)) {
      return result.concat(...regionOrRegionFilter.regions.map((region) => region.id));
    } else {
      return result.concat(regionOrRegionFilter.id);
    }
  }, [] as number[]);
};

export const getFlattenedRegions = (regionsOrRegionFilters: Array<IRegionOrIRegionFilter>): Array<IRegion> => {
  const chosenRegions = regionsOrRegionFilters.reduce(
    (result: Array<IRegion>, regionOrRegionFilter: IRegionOrIRegionFilter) => {
      if (isRegionFilter(regionOrRegionFilter)) {
        const regionFilter = regionOrRegionFilter;
        result = result.concat(regionFilter.regions);
      } else {
        const region = regionOrRegionFilter;
        result.push(region);
      }
      return result;
    },
    [] as Array<IRegion>
  );

  const uniqChosenRegions = uniqBy(chosenRegions, "id");
  return uniqChosenRegions;
};

export const setSessionCookie = (name: string, value: string): void => {
  document.cookie = `${name}=${value}; expires=0; path=/`;
};

export const buildExcludeQueryParamString = (tags: Array<ITagBase>): string => {
  const MAX_EXCLUDE_PARAMS = 300;

  if (isEmpty(tags)) {
    return "";
  } else {
    return `&${tags
      // Truncate the exclude URL param. Otherwise backend returns `414 URI Too Long`
      // This limit is already hit at about 300 params for RegionFilters that can have a lot of `regions`
      .slice(0, MAX_EXCLUDE_PARAMS)
      .map((tag) => `exclude=${encodeURIComponent(tag.id)}`)
      .join("&")}`;
  }
};

/**
 * Get a formatted value string from a numeric value
 * The logic is the same as for the Raphael charts
 * done in wk.charts.js#getTooltipInfo function
 */
export const getFormattedValue = (indicator: IIndicator, value: number): string => {
  let formatedValue;
  const df = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: indicator.decimalPlaces,
    maximumFractionDigits: indicator.decimalPlaces
  });

  if (
    isNumber(indicator.minimumClassification) &&
    isFinite(indicator.minimumClassification) &&
    isNumber(indicator.maximumClassification) &&
    isFinite(indicator.maximumClassification)
  ) {
    if (value < indicator.minimumClassification) {
      formatedValue = "< " + df.format(indicator.minimumClassification);
    } else if (value >= indicator.maximumClassification) {
      formatedValue = ">= " + df.format(indicator.maximumClassification);
    } else {
      const factor = Math.pow(10, indicator.decimalPlaces);
      const floor = Math.floor(value * factor) / factor;
      formatedValue = df.format(floor) + " - " + df.format(floor + 1 / factor);
    }
  } else {
    formatedValue = df.format(value);
  }

  return formatedValue;
};

/**
 * Returns the preferred year playback speed for given statisticDataType if provided.
 * @param StatisticType the StatsticsDataType the preferred speed shall be returned for
 * @returns the preferred year playback speed for given statisticDataType if provided
 */
export const getPreferredYearSpeed = (statisticType?: StatisticType) => {
  switch (statisticType) {
    case StatisticType.AgeStructure:
      return 1500;
  }
  return undefined;
};
