import { createAsyncThunk, AsyncThunk, AsyncThunkPayloadCreator, unwrapResult } from "@reduxjs/toolkit";

/**
 * This is a wrapper around redux's `createAsyncThunk` that makes sure that only the last fetch gets executed, previous ones are cancelled
 * See https://stackoverflow.com/a/64697111/1129950
 *
 * The following function creates "internal" async thunk that does the real job,
 * and "outer" async thunk that delegates to the internal one and aborts previous dispatches, if any.
 *
 * The payload creator of the internal thunk is also wrapped to:
 * 1) wait for the previous invocation of payload creator to finish,
 * 2) skip calling the real payload creator (and thus the API call) if the action was aborted while waiting.
 */
export default function createNonConcurrentAsyncThunk<Returned, ThunkArg>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  options?: Parameters<typeof createAsyncThunk>[2]
): AsyncThunk<Returned, ThunkArg, any> {
  let pending: {
    payloadPromise?: Promise<unknown>;
    actionAbort?: () => void;
  } = {};

  const wrappedPayloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg> = (arg, thunkAPI) => {
    const run = () => {
      if (thunkAPI.signal.aborted) {
        return thunkAPI.rejectWithValue({
          name: "AbortError",
          message: "Aborted"
        });
      }
      const promise = Promise.resolve(payloadCreator(arg, thunkAPI)).finally(() => {
        if (pending.payloadPromise === promise) {
          // pending.payloadPromise = null;
          pending.payloadPromise = undefined;
        }
      });
      return (pending.payloadPromise = promise);
    };

    if (pending.payloadPromise) {
      return (pending.payloadPromise = pending.payloadPromise.then(run, run)); // don't use finally(), replace result
    } else {
      return run();
    }
  };

  const internalThunk = createAsyncThunk(typePrefix + "-protected", wrappedPayloadCreator);

  return createAsyncThunk<Returned, ThunkArg>(
    typePrefix,
    async (arg, thunkAPI) => {
      if (pending.actionAbort) {
        pending.actionAbort();
      }
      const internalPromise = thunkAPI.dispatch(internalThunk(arg));
      const abort = internalPromise.abort;
      pending.actionAbort = abort;
      return internalPromise.then(unwrapResult).finally(() => {
        if (pending.actionAbort === abort) {
          // pending.actionAbort = null;
          pending.actionAbort = undefined;
        }
      });
    },
    options
  );
}
