import React, { ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import Slider, { createSliderWithTooltip } from "rc-slider";
import ns from "../../utils/namespace";
import head from "lodash/head";
import last from "lodash/last";
import "rc-slider/assets/index.css";
import { setAreYearsPlaying } from "../../redux/yearsSlice";
import { useDispatch } from "react-redux";

interface Props {
  availableYears: Array<number>;
  currentYear: number;
  setChosenSingleYear: (year: number) => void;
}

type IMarks = {
  [key: number]: number;
};

const SliderWithTooltip = createSliderWithTooltip(Slider);

export default function YearPicker({ availableYears, currentYear, setChosenSingleYear }: Props): ReactElement {
  const dispatch = useDispatch();

  const hasManyYears = availableYears.length > 4;
  const min = head(availableYears);
  const max = last(availableYears);

  const marks: IMarks = {};
  availableYears.forEach((year) => {
    marks[year] = year;
  });

  // This is only called when user releases mouse. So will sliding, not on every year the picker passes a request/render of data is done
  const handleAfterChange = (value: number) => {
    // When user manually picks a single year then stop a possibly running animation
    dispatch(setAreYearsPlaying(false));
    setChosenSingleYear(value);
  };

  // Make this a controlled component which updates when currentYear changes upstream
  const [value, setValue] = useState(currentYear);
  const handleChange = (value: number) => {
    setValue(value);
  };
  useEffect(() => {
    setValue(currentYear);
  }, [currentYear]);

  const props = {
    value,
    min,
    max,
    marks,
    step: null,
    className: classNames(ns("year-slider__range"), {
      [ns("year-slider__range--many-years")]: hasManyYears,
      [ns("year-slider__range--few-years")]: !hasManyYears
    }),
    onChange: handleChange,
    onAfterChange: handleAfterChange
  };

  return (
    <div className={ns("year-slider year-slider--picker")}>
      {hasManyYears ? (
        <>
          <div className={ns("year-slider__min")}>{min}</div>
          <SliderWithTooltip {...props} />
          <div className={ns("year-slider__max")}>{max}</div>
        </>
      ) : (
        <Slider {...props} />
      )}
    </div>
  );
}
