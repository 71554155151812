import classNames from "classnames";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import {
  selectActiveOutline,
  selectShowRegionMarkers,
  setActiveOutline,
  setShowRegionMarkers
} from "../../redux/uiSlice";
import { OutlineType } from "../../ts/interfaces";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import Dropdown from "./Dropdown";
import RadioGroup from "./RadioGroup";

interface Props {
  className?: string;
}

export default function MapConfigurator({ className }: Props): ReactElement {
  const dispatch = useDispatch();

  const outlineOptions = [
    {
      value: OutlineType.Bund,
      label: "Deutschland",
      iconPath: useGetSvgPath("#germany")
    },
    {
      value: OutlineType.Bundesland,
      label: "Bundesländer",
      iconPath: useGetSvgPath("#states")
    },
    {
      value: OutlineType.Landkreis,
      label: "Landkreise",
      iconPath: useGetSvgPath("#counties")
    }
  ];

  const activeOutline = useSelector(selectActiveOutline);

  const handleOutlineChange = (value: OutlineType) => {
    dispatch(setActiveOutline(value));
  };

  const showRegionMarkers = useSelector(selectShowRegionMarkers);

  return (
    <Dropdown
      className={classNames(ns("map-configurator"), className)}
      toggleButton={{
        label: "",
        title: "Kartenansicht verändern",
        className: ns("map-configurator__button", "btn--mira btn--icon"),
        icon: (
          <Icon className={ns("map-configurator__icon")} width={20} height={20}>
            <use xlinkHref={useGetSvgPath("#settings")}></use>
          </Icon>
        )
      }}
      panel={{
        className: ns("map-configurator__panel")
      }}
    >
      {() => (
        <>
          <div className={ns("map-configurator__outlines", "map-configurator__section")}>
            <RadioGroup
              items={outlineOptions.map((outline) => ({
                value: outline.value,
                label: outline.label,
                icon: (
                  <Icon className={ns("map-configurator__outline-icon")} width={27} height={35} fill="none">
                    <use xlinkHref={outline.iconPath}></use>
                  </Icon>
                )
              }))}
              itemClassName={ns("map-configurator__outline-item")}
              value={activeOutline}
              onChange={handleOutlineChange}
            />
          </div>
          <div className={ns("map-configurator__section")}>
            <label className={ns("map-configurator__switch-item")}>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={showRegionMarkers}
                onChange={(checked) => {
                  dispatch(setShowRegionMarkers({ showRegionMarkers: checked }));
                }}
                className={ns("map-configurator__switch")}
                width={28}
                height={16}
                handleDiameter={12}
                // $color-mariner
                onColor="#2664d1"
              />
              <span>Kommunennamen anzeigen</span>
            </label>
          </div>
        </>
      )}
    </Dropdown>
  );
}
