import { createSlice, PayloadAction, CombinedState } from "@reduxjs/toolkit";
import { IMapBbox, OutlineType } from "../ts/interfaces";
import { RootState } from "./rootReducer";
import { AppThunk, AppDispatch } from "./store";
import createNonConcurrentAsyncThunk from "../utils/createNonConcurrentAsyncThunk";
import { getDynamicSlices } from "./dynamicSlices";
import { useNonConcurrentAsyncThunks } from "../utils/reduxHooks";

interface IMapBboxState {
  loading: boolean;
  hasErrors: boolean;
  // 1st: The bbox as calculated by the backend for the given features
  mapBbox: IMapBbox | null;
  // 2nd: The bbox Leaflet zooms/pans to when it tries to `fitBounds()` to it
  // Calucalated after dragend/panend/zoomend
  // The leafletBbox is greater (or equals) as mapBbox
  // and is used for getting the features for the visible bbox
  leafletBbox: IMapBbox | null;
}

const initialState: IMapBboxState = {
  loading: false,
  hasErrors: false,
  mapBbox: null,
  leafletBbox: null
};

interface IFetchMapBboxArgs {
  apiUrl: string;
  regionIds: Array<number>;
  outline: OutlineType;
  minimal?: boolean;
}

export const createMapBboxNonConcurrentAsyncThunks = () => {
  const fetchMapBbox = createNonConcurrentAsyncThunk(
    "map/fetchMapBbox",
    async ({ apiUrl, regionIds, outline, minimal }: IFetchMapBboxArgs) => {
      const response = await fetch(`${apiUrl}?outline=${minimal ? "LANDKREIS" : outline}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(regionIds)
      });
      const mapBbox = await response.json();
      return mapBbox;
    }
  );

  return {
    fetchMapBbox
  };
};
export const useFetchMapBbox = () => {
  return useNonConcurrentAsyncThunks().mapBbox.fetchMapBbox;
};

export const createMapBboxSlice = ({ fetchMapBbox }: ReturnType<typeof createMapBboxNonConcurrentAsyncThunks>) => {
  // A slice for mapBbox with our three reducers
  return createSlice({
    name: "mapBbox",
    initialState,
    reducers: {
      setLeafletBbox(state, action: PayloadAction<{ leafletBbox: IMapBbox | null }>) {
        const { leafletBbox } = action.payload;
        state.leafletBbox = leafletBbox;
      }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchMapBbox.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(fetchMapBbox.fulfilled, (state, action) => {
        state.mapBbox = action.payload;
        state.loading = false;
        state.hasErrors = false;
      });
      builder.addCase(fetchMapBbox.rejected, (state, action) => {
        state.loading = false;
        // Only set errors if rejected was not called from our own abort in createNonConcurrentAsyncThunk
        if (!action.meta.aborted) {
          state.hasErrors = true;
        }
      });
    }
  });
};

const getMapBboxSlice = (getState: () => CombinedState<RootState>) => {
  const appId = getState().appId.value;
  return getDynamicSlices(appId).mapBbox;
};

export const setLeafletBbox = (leafletBbox: IMapBbox | null): AppThunk => async (
  dispatch: AppDispatch,
  getState: () => CombinedState<RootState>
) => {
  const mapBboxSlice = getMapBboxSlice(getState);
  dispatch(mapBboxSlice.actions.setLeafletBbox({ leafletBbox: leafletBbox }));
};

export const selectMapBbox = (state: RootState) => state.mapBbox;
