import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAppId } from "../redux/appIdSlice";
import { getDynamicSlices } from "../redux/dynamicSlices";
import { getMemoizedSelectors } from "../redux/memoizedSelectors";
import { getNonConcurrentAsyncThunks } from "../redux/nonConcurrentAsyncThunks";

// Hook for using created memoized selectors
export const useMemoizedSelectors = () => {
  const appId = useSelector(selectAppId);
  const allMemoizedSelectors = useMemo(() => {
    return getMemoizedSelectors(appId);
  }, [appId]);

  return allMemoizedSelectors;
};

// Hook for using created non concurrent async thunks
export const useNonConcurrentAsyncThunks = () => {
  const appId = useSelector(selectAppId);
  const allMemoizedSelectors = useMemo(() => {
    return getNonConcurrentAsyncThunks(appId);
  }, [appId]);

  return allMemoizedSelectors;
};

// Hook for using created dynamic slices
export const useDynamicSlices = () => {
  const appId = useSelector(selectAppId);
  const dynamicSlices = useMemo(() => {
    return getDynamicSlices(appId);
  }, [appId]);

  return dynamicSlices;
};
