import React, { ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import TagContainer from "./TagContainer";
import SelectAutocompletion from "../../molecules/SelectAutocompletion";
import ns from "../../../utils/namespace";
import {
  chooseFilterValue,
  selectChosenFilterValues,
  IFilterName,
  unchooseFilterValue
} from "../../../redux/filtersSlice";
import { buildExcludeQueryParamString } from "../../../utils/helpers";

interface Props {
  label: string;
  plural: string;
  filterName: IFilterName;
  optionsAPIUrl: string;
  optionsAPIUrlQueryParams?: string;
  placeholder: string;
  noOptionsInfo: string;
  className?: string;
}

export default function FilterContainer({
  label,
  plural,
  filterName,
  optionsAPIUrl,
  optionsAPIUrlQueryParams,
  placeholder,
  noOptionsInfo,
  className
}: Props): ReactElement {
  const dispatch = useDispatch();
  const chosenFilterValues = useSelector(selectChosenFilterValues(filterName));

  const selectAutocompletion = (
    <SelectAutocompletion
      className={ns("tag-container__select")}
      optionsAPIUrl={optionsAPIUrl}
      optionsAPIUrlQueryParams={`${optionsAPIUrlQueryParams || ""}${buildExcludeQueryParamString(chosenFilterValues)}`}
      placeholder={placeholder}
      noOptionsInfo={noOptionsInfo}
      shouldLoadDefaultOptions
      shouldShowDropdownIndicator
      value={chosenFilterValues}
      propertyUsedAsValue={"id"}
      onChoose={(value) => dispatch(chooseFilterValue(filterName, value))}
      // Required props that will be added later in TagContainer/TagContainerInOverlay
      // Added dummy placeholders here to make TypeScript happy
      onFocus={() => {}}
      onBlur={() => {}}
    />
  );

  return (
    <TagContainer
      label={label}
      isLabelSrOnly={true}
      plural={plural}
      tags={chosenFilterValues}
      selectAutocompletion={selectAutocompletion}
      className={classNames(ns("filter-container"), className)}
      onUnchoose={(value) => dispatch(unchooseFilterValue(filterName, value.id))}
    />
  );
}
