import React from "react";
import isEmpty from "lodash/isEmpty";
import ns from "../../utils/namespace";

interface Props {
  title: string;
  text: string;
  links: Array<{ href: string; label: string }>;
}

export default function FurtherInfoBox({ title, text, links }: Props) {
  return (
    <div className={ns("further-info-box")}>
      <p className={ns("further-info-box__title")}>{title}</p>
      <p className={ns("further-info-box__paragraph")}>{text}</p>
      {!isEmpty(links) && (
        <ul className={ns("further-info-box__list")}>
          {links.map((link, i) => (
            <li className={ns("further-info-box__list-item")} key={`key-${link.href}-${link.label}-${i}`}>
              <a className={ns("further-info-box__link")} href={link.href} target="blank">
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
