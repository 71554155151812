import { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { selectAppConfig } from "../../redux/appConfigSlice";
import {
  selectActiveIndicator,
  selectActiveRenderer,
  selectActiveSelection,
  selectComparisonYear,
  selectChosenIndicatorsOrTopics,
  selectStatisticType
} from "../../redux/indicatorsOrTopicsSlice";
import { selectChosenSingleYear, selectChosenYears } from "../../redux/yearsSlice";
import { selectActiveOutline, selectIncludeComparisonYear, selectCompareGenerations } from "../../redux/uiSlice";
import { IRenderer } from "../../ts/interfaces";
import { getFriendlyUrl } from "../../utils/FriendlyUrlHandler";
import { getRendererIconProps, getRendererTypes, isRegionFilter } from "../../utils/helpers";
import ns from "../../utils/namespace";
import { useGetSvgPath, useGetBaseSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import IndicatorOrTopicInfoModal from "../molecules/IndicatorOrTopicInfoModal";
import Button from "../molecules/button/Button";
import { isMiniApp } from "../../utils/appSetup";
import { selectChosenRegionsOrRegionFilters, selectTopLowRegionsCount } from "../../redux/regionsOrRegionFiltersSlice";

export default function MiniStatisticsAppHeader(): ReactElement {
  const appConfig = useSelector(selectAppConfig);

  const chosenRegionsOrRegionsFilter = useSelector(selectChosenRegionsOrRegionFilters);

  const activeIndicator = useSelector(selectActiveIndicator);
  const activeSelection = useSelector(selectActiveSelection);

  const activeRenderer = useSelector(selectActiveRenderer);
  const allRenderers = activeIndicator ? getRendererTypes(activeIndicator.type, appConfig.types) : ([] as IRenderer[]);
  const allButActiveRenderer = allRenderers.filter((renderer) => renderer !== activeRenderer);

  const chosenIndicatorsOrTopics = useSelector(selectChosenIndicatorsOrTopics);
  const chosenYears = useSelector(selectChosenYears);
  const chosenSingleYear = useSelector(selectChosenSingleYear);
  const comparisonYear = useSelector(selectComparisonYear);
  const includeComparisonYear = useSelector(selectIncludeComparisonYear);
  const compareGenerations = useSelector(selectCompareGenerations);
  const topLowRegionsCount = useSelector(selectTopLowRegionsCount);

  const activeMapOutline = useSelector(selectActiveOutline);

  const statisticType = useSelector(selectStatisticType);

  const hasInfoOverlay =
    isMiniApp(appConfig) && !appConfig.title && activeSelection && !isEmpty(activeSelection.explanation);
  const [isInfoOverlayOpen, setIsInfoOverlayOpen] = useState(false);

  // get base part of url from liferay
  const baseUrl = "/daten/";

  const baseSvgPath = useGetBaseSvgPath();
  const infoIconSvgPath = useGetSvgPath("#info");

  let title = "";
  if (isMiniApp(appConfig)) {
    if (appConfig.title) {
      title = appConfig.title;
    } else if (!isEmpty(chosenRegionsOrRegionsFilter)) {
      const first = chosenRegionsOrRegionsFilter[0];
      const regionTitle = isRegionFilter(first) ? first.title! : first.name;
      title = `${regionTitle} | ${activeIndicator?.name || statisticType?.name}`;
    }
  }

  return (
    <div className={ns("mini-statistics-app-header")}>
      <div className={ns("mini-statistics-app-header__info-container")}>
        <span className={ns("mini-statistics-app-header__info")}>{title}</span>
        {hasInfoOverlay && (
          <Button
            className={ns("mini-statistics-app-header__info-button")}
            label="Info zum indikator"
            srOnly
            handleClick={() => setIsInfoOverlayOpen(true)}
            icon={
              <Icon className={ns("mini-statistics-app-header__info-icon")} width={20} height={20}>
                <use xlinkHref={infoIconSvgPath}></use>
              </Icon>
            }
          />
        )}
      </div>
      {hasInfoOverlay && isInfoOverlayOpen && (
        <IndicatorOrTopicInfoModal
          closeModal={() => setIsInfoOverlayOpen(false)}
          isOpen={isInfoOverlayOpen}
          indicatorOrTopic={activeSelection}
        />
      )}
      <div className={ns("mini-statistics-app-header__available-renderers-container")}>
        <span className={ns("mini-statistics-app-header__available-renderers")}>
          {allButActiveRenderer.map((renderer, index) => {
            const rendererUrl =
              baseUrl +
              getFriendlyUrl(
                statisticType,
                activeIndicator,
                renderer,
                activeMapOutline,
                chosenRegionsOrRegionsFilter,
                chosenIndicatorsOrTopics,
                chosenYears,
                chosenSingleYear,
                comparisonYear,
                includeComparisonYear,
                compareGenerations,
                topLowRegionsCount
              );

            const rendererIconProps = getRendererIconProps(renderer);

            return rendererIconProps ? (
              <a
                href={rendererUrl}
                key={`${renderer}_${index}`}
                className={ns("mini-statistics-app-header__renderer-link")}
              >
                <span className={ns("mini-statistics-app-header__renderer-label")} data-type="sr-only">
                  als {rendererIconProps.label}
                </span>
                <Icon
                  width={rendererIconProps.width}
                  height={rendererIconProps.height}
                  className={ns("mini-statistics-app-header__renderer-icon")}
                >
                  <use xlinkHref={`${baseSvgPath}${rendererIconProps.id}`}></use>
                </Icon>
              </a>
            ) : null;
          })}
        </span>
      </div>
    </div>
  );
}
