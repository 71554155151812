import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useRef, useState } from "react";
import { IClassItem } from "../../../../ts/interfaces";

export const useClassIndexHighlighting = (classItems: IClassItem[], handleAlsoNoClass: boolean = true) => {
  const classIndices = useMemo(() => {
    const classIndices: Array<number | null> = classItems.map((item) => item.classIndex);
    return !isEmpty(classIndices) && handleAlsoNoClass ? classIndices.concat(null) : classIndices;
  }, [classItems, handleAlsoNoClass]);

  const [activeClassIndices, setActiveClassIndices] = useState<Array<number | null>>([]);
  useEffect(() => {
    setActiveClassIndices(classIndices);
  }, [classIndices]);

  const [hoveredClassIndex, setHoveredClassIndex] = useState<number | null | undefined>(undefined);
  // isHoverApplied is stored as ref such that changes do not retrigger effect below
  const isHoverAppliedRef = useRef(false);

  const handleClassIndexHoverIn = (classIndex: number | null) => {
    setHoveredClassIndex(classIndex);
    isHoverAppliedRef.current = false;
  };
  const handleClassIndexHoverOut = (classIndex: number | null) => {
    if (hoveredClassIndex === classIndex) {
      setHoveredClassIndex(undefined);
    }
  };

  const [highlightedClassIndices, setHighlightedClassIndices] = useState<Array<number | null> | undefined>(undefined);
  useEffect(() => {
    const isHoverApplied = isHoverAppliedRef.current;
    if (activeClassIndices.length === 0) {
      return setHighlightedClassIndices(undefined);
    } else if (activeClassIndices.length === classIndices.length) {
      if (hoveredClassIndex !== undefined && !isHoverApplied) {
        isHoverAppliedRef.current = true;
        return setHighlightedClassIndices([hoveredClassIndex]);
      } else {
        return setHighlightedClassIndices(undefined);
      }
    } else if (hoveredClassIndex !== undefined && !isHoverApplied) {
      isHoverAppliedRef.current = true;
      if (!activeClassIndices.includes(hoveredClassIndex)) {
        return setHighlightedClassIndices(activeClassIndices.concat(hoveredClassIndex));
      }
    }
    setHighlightedClassIndices(activeClassIndices);
  }, [activeClassIndices, classIndices.length, hoveredClassIndex]);

  const handleClassIndexClick = (classIndex: number | null) => {
    if (activeClassIndices.length === classIndices.length) {
      setActiveClassIndices([classIndex]);
    } else {
      if (activeClassIndices.includes(classIndex)) {
        if (activeClassIndices.length > 1) {
          setActiveClassIndices(activeClassIndices.filter((currentClassIndex) => currentClassIndex !== classIndex));
        } else {
          setActiveClassIndices(classIndices);
        }
      } else {
        setActiveClassIndices(activeClassIndices.concat(classIndex));
      }
    }
  };

  return {
    activeClassIndices,
    highlightedClassIndices,
    handleClassIndexHoverIn,
    handleClassIndexHoverOut,
    handleClassIndexClick
  };
};
