import { useEffect } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import {
  selectLowRegionsCount,
  selectTopRegionsCount,
  selectUsedRegionsOrRegionFilters
} from "../redux/regionsOrRegionFiltersSlice";
import {
  selectActiveRenderer,
  selectActiveSelection,
  selectComparisonYear,
  selectStatisticType,
  selectUsedIndicatorsOrTopics
} from "../redux/indicatorsOrTopicsSlice";
import { selectBareChosenSingleYear, selectChosenYears } from "../redux/yearsSlice";
import { selectActiveOutline, selectIncludeComparisonYear, selectCompareGenerations } from "../redux/uiSlice";
import {
  IIndicatorOrTopic,
  IRegionOrIRegionFilter,
  IRenderer,
  IStatisticType,
  RendererFriendlyUrl,
  OutlineType,
  StatisticType
} from "../ts/interfaces";
import { getRendererYearChooserType, isSingleRegion, isTopic, setSessionCookie } from "./helpers";
import { trackNewUrl } from "./eTracker";

export const getBaseUrl = () => {
  // get base part of url from liferay
  let baseUrl = (window as any).Liferay.ThemeDisplay.getCanonicalURL();
  baseUrl = baseUrl.endsWith("/") ? baseUrl : baseUrl + "/";

  return baseUrl;
};

export function getFriendlyUrl(
  statisticType: IStatisticType | null,
  activeSelection: IIndicatorOrTopic | null,
  activeRenderer: IRenderer,
  activeMapOutline: OutlineType,
  chosenRegionsOrRegionFilters: Array<IRegionOrIRegionFilter>,
  chosenIndicatorsOrTopics: Array<IIndicatorOrTopic>,
  chosenYears: Array<number>,
  chosenSingleYear: number | null,
  comparisonYear: number | undefined,
  includeComparisonYear: boolean,
  compareGenerations: boolean,
  topLowRegionsCount?: {
    topRegionsCount?: number | null;
    lowRegionsCount?: number | null;
  }
): string {
  const currSelection =
    (activeRenderer === "TABLE" && statisticType?.type !== StatisticType.GeographicMigration) ||
    activeRenderer === "RADAR_CHART"
      ? null
      : activeSelection;

  const regions = isEmpty(chosenRegionsOrRegionFilters)
    ? ""
    : `+${chosenRegionsOrRegionFilters.map((regionOrRegionFilter) => regionOrRegionFilter.friendlyUrl).join("+")}`;

  const indicatorsOrTopics = chosenIndicatorsOrTopics
    .reduce((result: Array<string>, indicatorOrTopic: IIndicatorOrTopic) => {
      if (!currSelection || (isTopic(indicatorOrTopic) && currSelection.friendlyUrl !== indicatorOrTopic.friendlyUrl)) {
        result.push(indicatorOrTopic.friendlyUrl);
      }
      return result;
    }, [])
    .join("+");

  const indicator = currSelection ? `${isEmpty(indicatorsOrTopics) ? "" : "+"}${currSelection.friendlyUrl}` : "";

  let rendererType = activeRenderer ? RendererFriendlyUrl[activeRenderer] : RendererFriendlyUrl.TABLE;

  let years: string;
  if (getRendererYearChooserType(activeRenderer, statisticType) === "picker" && chosenSingleYear) {
    if (includeComparisonYear && comparisonYear && isFinite(comparisonYear)) {
      years = `+${chosenSingleYear}+${comparisonYear}`;
    } else {
      years = `+${chosenSingleYear}`;
    }
  } else if (chosenYears.length === 0) {
    years = "";
  } else if (chosenYears.length === 1) {
    years = `+${chosenYears[0]}`;
  } else {
    years = `+${chosenYears[0]}-${chosenYears[chosenYears.length - 1]}`;
  }

  let chartType: string;
  if (rendererType === RendererFriendlyUrl.MAP && activeMapOutline) {
    chartType = "+" + RendererFriendlyUrl[activeMapOutline].toString();
  } else {
    chartType = statisticType?.rendererTypes.length === 1 ? "" : "+" + rendererType.toString();
    if (rendererType === RendererFriendlyUrl.PYRAMID_CHART && compareGenerations) {
      chartType += "+generationen";
    }
  }

  const includeTopAndLowCount =
    activeSelection &&
    !isTopic(activeSelection) &&
    activeSelection?.topLowRegionsAvailable &&
    isSingleRegion(chosenRegionsOrRegionFilters);
  const topRegionsCount =
    includeTopAndLowCount && topLowRegionsCount?.topRegionsCount ? `+top-${topLowRegionsCount.topRegionsCount}` : "";
  const lowRegionsCount =
    includeTopAndLowCount && topLowRegionsCount?.lowRegionsCount ? `+low-${topLowRegionsCount.lowRegionsCount}` : "";

  return `${indicatorsOrTopics}${indicator}${regions}${topRegionsCount}${lowRegionsCount}${years}${chartType}`;
}

export default function FriendlyUrlHandler(): null {
  const statisticType = useSelector(selectStatisticType);
  const chosenRegions = useSelector(selectUsedRegionsOrRegionFilters);
  const chosenIndicatorsOrTopics = useSelector(selectUsedIndicatorsOrTopics);
  const activeSelection = useSelector(selectActiveSelection);
  const chosenYears = useSelector(selectChosenYears);
  const activeRenderer = useSelector(selectActiveRenderer);
  const activeMapOutline = useSelector(selectActiveOutline);
  const chosenSingleYear = useSelector(selectBareChosenSingleYear);
  const comparisonYear = useSelector(selectComparisonYear);
  const includeComparisonYear = useSelector(selectIncludeComparisonYear);
  const compareGenerations = useSelector(selectCompareGenerations);
  const topRegionsCount = useSelector(selectTopRegionsCount);
  const lowRegionsCount = useSelector(selectLowRegionsCount);
  const baseUrl = getBaseUrl();

  useEffect(() => {
    const newUrl = getFriendlyUrl(
      statisticType,
      activeSelection,
      activeRenderer,
      activeMapOutline,
      chosenRegions,
      chosenIndicatorsOrTopics,
      chosenYears,
      chosenSingleYear,
      comparisonYear,
      includeComparisonYear,
      compareGenerations,
      { topRegionsCount, lowRegionsCount }
    );
    setSessionCookie("lastStatisticsRequest", newUrl);
    const newHref = baseUrl + newUrl;
    if (window.location.href !== newHref) {
      window.history.replaceState(null, "", newHref);
      trackNewUrl();
    }
  }, [
    baseUrl,
    chosenRegions,
    chosenIndicatorsOrTopics,
    chosenYears,
    chosenSingleYear,
    activeRenderer,
    activeMapOutline,
    activeSelection,
    comparisonYear,
    includeComparisonYear,
    compareGenerations,
    statisticType,
    topRegionsCount,
    lowRegionsCount
  ]);

  return null;
}
