import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import last from "lodash/last";
import { chooseSingleYear, selectChosenSingleYear, selectChosenYears } from "../../../../redux/yearsSlice";

/**
 * Returns current chosen single year (provided that at least one available year exists),
 * which will we be set to last year of chosen year range on component mount and a stable
 * callback for updating chosen single year.
 * @returns an object containing chosenSingleYear and a stable callback setChosenSingleYear for updating current chosenSingleYear
 */
export const useChosenSingleYear = () => {
  const dispatch = useDispatch();

  const chosenYears = useSelector(selectChosenYears);
  const chosenSingleYear = useSelector(selectChosenSingleYear);
  // Indicates whether the effect below was executed
  const [isChosenSingleYearInitialized, setIsChosenSingleYearInitialized] = useState(false);

  const setChosenSingleYear = useCallback(
    (year: number | null) => {
      dispatch(chooseSingleYear(year));
    },
    [dispatch]
  );

  // Upon mount we always pick the last from range of chosen years (if exists)
  // This is important when coming from a renderer allowing multiple chosen years e.g. a renderer using ChartWithYearSlider
  useEffect(() => {
    const lastYear = last(chosenYears);
    if (lastYear) {
      setChosenSingleYear(lastYear);
      setIsChosenSingleYearInitialized(true);

      // Cleaning up redux state on unmount because
      // once we switch back to table or another renderer
      // that uses the year range instead of year picker
      // then chosenSingleYear is reset (will be set again on mounting)
      return () => {
        setChosenSingleYear(null);
      };
    }
    setIsChosenSingleYearInitialized(true);
  }, [dispatch, chosenYears, setChosenSingleYear]);

  return {
    chosenSingleYear: isChosenSingleYearInitialized ? chosenSingleYear : null,
    setChosenSingleYear
  };
};
