import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import useOnclickOutside from "react-cool-onclickoutside";
import useKey from "@accessible/use-key";
import ns from "../../utils/namespace";
import Button from "./button/Button";
import Icon from "../atoms/Icon";
import { useGetSvgPath } from "../../utils/svgPath";

interface RenderDropdownChildrenArgs {
  closeDropdown: () => void;
}

interface Props {
  toggleButton: {
    label: string;
    title?: string;
    icon?: ReactElement;
    expandedIcon?: ReactElement;
    className?: string;
  };
  panel?: {
    title?: string;
    className?: string;
  };
  children: (props: RenderDropdownChildrenArgs) => ReactNode;
  className?: string;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  hasInnerClose?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  shouldExpand?: boolean;
}

export default function Dropdown({
  toggleButton,
  panel,
  className: customClassName,
  children: renderChildren,
  closeOnClickOutside = true,
  closeOnEsc = true,
  hasInnerClose = false,
  onOpen,
  onClose,
  shouldExpand = false
}: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpen = useCallback(() => {
    if (onOpen) {
      onOpen();
    }
    setIsExpanded(true);
  }, [onOpen]);
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsExpanded(false);
  }, [onClose]);

  useEffect(() => {
    if (shouldExpand) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [handleClose, handleOpen, shouldExpand]);

  const clickOutsideRef = useOnclickOutside(() => {
    if (closeOnClickOutside) {
      handleClose();
    }
  });

  useKey(window, {
    Escape: () => {
      if (closeOnEsc) {
        handleClose();
      }
    }
  });

  // Make sure IDs do not change with each render
  const toggleId = useRef(`${ns("tooltip-toggle")}-${uniqueId()}`);
  const panelId = useRef(`${ns("tooltip-panel")}-${uniqueId()}`);

  const toggle = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    if (isExpanded) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const className = ns("dropdown");

  const closeIcon = (
    <Icon className={ns("btn__icon") + ` ${className}__closeicon`} width={24} height={24} fill="none">
      <use xlinkHref={useGetSvgPath("#close")}></use>
    </Icon>
  );

  return (
    <div className={classNames(className, customClassName)} ref={clickOutsideRef}>
      <Button
        className={classNames(
          `${className}__button`,
          {
            [`${className}__button--expanded`]: isExpanded
          },
          toggleButton.className
        )}
        id={toggleId.current}
        label={toggleButton.label}
        title={toggleButton.title}
        ariaExpanded={isExpanded}
        ariaControls={panelId.current}
        handleClick={toggle}
        icon={isExpanded && toggleButton.expandedIcon !== undefined ? toggleButton.expandedIcon : toggleButton.icon}
      />
      {isExpanded && (
        <div
          id={panelId.current}
          className={classNames(`${className}__panel`, panel?.className)}
          aria-hidden={isExpanded ? "false" : "true"}
          aria-labelledby={toggleId.current}
        >
          {panel?.title && <span className={`${className}__panel-title`}>{panel.title}</span>}
          {hasInnerClose && (
            <Button
              className={ns("btn--icon") + ` ${className}__closebutton`}
              srOnly
              label="Schließen"
              title="Schließen"
              ariaControls={panelId.current}
              handleClick={toggle}
              icon={closeIcon}
            ></Button>
          )}
          {renderChildren({ closeDropdown: handleClose })}
        </div>
      )}
    </div>
  );
}
