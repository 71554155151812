import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

interface IAppIdState {
  value: string;
}

export const createAppIdSlice = (appId: string) => {
  const initialState: IAppIdState = {
    value: appId
  };

  return createSlice({
    name: "appId",
    initialState: initialState,
    reducers: {}
  });
};

export const selectAppId = (state: RootState): string => state.appId.value;
