import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import React, { ReactElement, useRef } from "react";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import OrderedList from "../atoms/OrderedList";
import Dropdown from "./Dropdown";
import MapSwitcherOptionItem from "./MapSwitcherOptionItem";

export type IMapSwitcherItemValue = string | number;

export interface IMapSwitcherItem<SwitcherItemValue extends IMapSwitcherItemValue> {
  value: SwitcherItemValue;
  label: string;
  iconId: string;
}

interface Props<SwitcherItemValue extends IMapSwitcherItemValue> {
  title: string;
  items: Array<IMapSwitcherItem<SwitcherItemValue>>;
  activeItem: IMapSwitcherItemValue;
  iconId?: string;
  className?: string;
  onItemChange: (value: SwitcherItemValue) => void;
}

export default function MapSwitcher<SwitcherItemValue extends IMapSwitcherItemValue>({
  title,
  items,
  activeItem,
  iconId = items.find((item) => item.value === activeItem)?.iconId,
  className,
  onItemChange: handleItemChange
}: Props<SwitcherItemValue>): ReactElement {
  const switcherRadioGroupName = useRef(`${ns("map-switcher")}-${uniqueId()}`);

  const switcherItems = items
    .map((item, idx: number) => {
      const isActive = item.value === activeItem;
      return (
        <li className={ns("map-switcher__option-item")} key={`key_${item.value}_${idx}`}>
          <MapSwitcherOptionItem
            {...item}
            isActive={isActive}
            groupName={switcherRadioGroupName.current}
            onChange={handleItemChange}
          />
        </li>
      );
    })
    .reverse();

  const iconSvgPath = useGetSvgPath(iconId || "");
  const icon = iconId ? (
    <Icon
      className={ns("map-switcher__icon")}
      width={27}
      height={35}
      fill="none"
      // necessary to force react to rerender svg
      // see also https://stackoverflow.com/questions/55420535/react-svg-not-rerendering-on-attribute-update
      key={iconId}
    >
      <use xlinkHref={iconSvgPath}></use>
    </Icon>
  ) : undefined;

  return (
    <Dropdown
      className={classNames(ns("map-switcher"), className)}
      toggleButton={{
        label: "",
        title: title,
        className: ns("map-switcher__button"),
        icon: icon
      }}
      panel={{
        className: ns("map-switcher__panel")
      }}
    >
      {() => <OrderedList className={ns("map-switcher__options-list")}>{switcherItems}</OrderedList>}
    </Dropdown>
  );
}
