import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { latLng, LatLngBounds, latLngBounds, Map } from "leaflet";

import isArray from "lodash/isArray";

import {
  selectActiveIndicator,
  selectActiveTopic,
  selectAreIndicatorsAvailable,
  selectChosenIndicatorsOrTopics,
  selectHasStatisticType
} from "../../../../redux/indicatorsOrTopicsSlice";
import { selectMapBbox, useFetchMapBbox } from "../../../../redux/mapBboxSlice";
import { selectMapData, useFetchMapData } from "../../../../redux/mapDataSlice";
import { selectActiveOutline } from "../../../../redux/uiSlice";
import { selectAppConfig } from "../../../../redux/appConfigSlice";
import { useMemoizedSelectors } from "../../../../utils/reduxHooks";
import { usePrevious } from "../../../../utils/usePrevious";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

const getLatLngBounds = (bbox: Array<number> | null): LatLngBounds | null => {
  if (isArray(bbox) && bbox.length === 4) {
    const northEast = latLng(bbox[3], bbox[2]);
    const southWest = latLng(bbox[1], bbox[0]);
    const bounds = latLngBounds(southWest, northEast);
    return bounds;
  }
  return null;
};

export const useGetMapData = (
  map: Map | null,
  isMapCreated: boolean,
  currentYear: number,
  availableYears: number[],
  years: number[]
) => {
  const dispatch = useDispatch();
  const fetchMapBbox = useFetchMapBbox();
  const fetchMapData = useFetchMapData();
  const appConfig = useSelector(selectAppConfig);

  const memoizedSelectors = useMemoizedSelectors();
  const { selectChosenRegions } = memoizedSelectors.regionsOrRegionFilters;

  const activeIndicator = useSelector(selectActiveIndicator);
  const activeTopic = useSelector(selectActiveTopic);
  const regions = useSelector(selectChosenRegions);
  const activeOutline = useSelector(selectActiveOutline);
  const previousOutline = usePrevious(activeOutline);
  const chosenIndicatorsOrTopics = useSelector(selectChosenIndicatorsOrTopics);
  const hasStatisticType = useSelector(selectHasStatisticType);
  const areIndicatorsAvailable = useSelector(selectAreIndicatorsAvailable);

  const { mapBbox, loading: bboxLoading, hasErrors: bboxHasErrors, leafletBbox } = useSelector(selectMapBbox);

  const bounds = React.useMemo(() => getLatLngBounds(mapBbox), [mapBbox]);

  const { mapData, loading: mapDataLoading, hasErrors: mapDataHasErrors } = useSelector(selectMapData);

  // 1st Get bbox for chosen regions
  const bboxApiUrl = appConfig.urls.apiMapBbox;
  const regionIds = useMemo(() => regions.map((c) => c.id), [regions]);
  const previousRegionIds = usePrevious(regionIds);
  const newRegionIds = previousRegionIds
    ? regionIds !== previousRegionIds
      ? regionIds.filter((x) => !previousRegionIds.includes(x))
      : undefined
    : regionIds;
  const hasOutlineChanged = activeOutline !== previousOutline;

  // This ref's value indicates if next bounds shall be used exactly and not just extend current bounds
  // Value is set to false once bounds were fitted
  // Note that changing of contained value doesn't actually trigger map.fitBounds() directly, but that the value is first evualuated when new bounds are fetched and received
  const forceFitBoundsExactlyRef = useRef(true);
  if (hasOutlineChanged) {
    forceFitBoundsExactlyRef.current = true;
  }

  useEffect(() => {
    if (bboxApiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch bbox data, bboxApiUrl is: ", bboxApiUrl);
      return;
    }
    if ((newRegionIds && !isEmpty(newRegionIds)) || hasOutlineChanged) {
      dispatch(
        fetchMapBbox({
          apiUrl: bboxApiUrl,
          regionIds: !hasOutlineChanged && newRegionIds ? newRegionIds : regionIds,
          outline: activeOutline,
          minimal: !hasOutlineChanged
        })
      );
    }
  }, [dispatch, bboxApiUrl, regionIds, activeOutline, fetchMapBbox, newRegionIds, hasOutlineChanged]);

  // 2nd move leaflet map to this bbox
  useEffect(() => {
    if (isMapCreated && map && bounds) {
      if (forceFitBoundsExactlyRef.current) {
        forceFitBoundsExactlyRef.current = false;
        map.fitBounds(bounds);
      } else if (!map.getBounds().contains(bounds)) {
        // extend current bounds to include new bounds
        map.fitBounds(map.getBounds().extend(bounds));
      }
    }
    // The map param is not passed in the dependency list, because the `map` object changes every time something with the map changes
    // But we still need to check the map to make sure it has been created (not null), when the bounds are available
    // For this we use `isMapCreated`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapCreated, bounds]);

  // 3rd Whenever map moves or zooms, then fetch geojson for new leaflet bounds/bbox
  const mapDataApiUrl = appConfig.urls.apiMapData;

  // Storing "core" arguments (all data related arguments except years)
  // in own variable to be able to say if they kept same since last fetching
  const fetchDataCoreArgs = useMemo(() => {
    if (!leafletBbox) {
      return null;
    }

    const staticCoreArgs = {
      bbox: leafletBbox.join(","),
      regionIds: regionIds,
      outline: activeOutline
    };
    if (hasStatisticType && !areIndicatorsAvailable) {
      return {
        ...staticCoreArgs,
        topicId: activeTopic?.id || chosenIndicatorsOrTopics[0].id
      };
    } else if (activeIndicator?.id) {
      return {
        ...staticCoreArgs,
        indicatorId: activeIndicator?.id
      };
    } else {
      return null;
    }
  }, [
    activeIndicator?.id,
    activeOutline,
    activeTopic?.id,
    areIndicatorsAvailable,
    chosenIndicatorsOrTopics,
    hasStatisticType,
    leafletBbox,
    regionIds
  ]);
  const previousFetchDataCoreArgs = usePrevious(fetchDataCoreArgs);
  const previousYears = usePrevious(years);

  // Once all available years for current "core" arguments get fetched this is set to true in effect below
  // Using ref here since value changes shall not trigger rerendering
  const hasFetchedAvailableYearsRef = useRef(false);

  useEffect(() => {
    if (mapDataApiUrl.startsWith("dummy-string")) {
      console.warn("Cannot fetch map data, mapDataApiUrl is: ", mapDataApiUrl);
      return;
    }

    if (
      fetchDataCoreArgs === previousFetchDataCoreArgs &&
      (hasFetchedAvailableYearsRef.current || years === previousYears)
    ) {
      // Fetched already all available years for current "core" arguments (bbox, regionIds, outline, topicId/indicatorId) => no need to fetch anything
      return;
    }

    hasFetchedAvailableYearsRef.current = isEqual(years, availableYears);

    if (!fetchDataCoreArgs) {
      return;
    }

    dispatch(
      fetchMapData({
        ...fetchDataCoreArgs,
        apiUrl: mapDataApiUrl,
        years
      })
    );
  }, [
    availableYears,
    dispatch,
    fetchDataCoreArgs,
    fetchMapData,
    mapDataApiUrl,
    previousFetchDataCoreArgs,
    previousYears,
    years
  ]);

  return {
    mapBbox,
    bboxLoading,
    bboxHasErrors,
    mapData,
    mapDataLoading,
    mapDataHasErrors,
    currentYearIndex: mapData?.years.indexOf(currentYear)
  };
};
