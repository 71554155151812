import PROJECT_NAMESPACE from "../projectNamespace";

/**
 * Add project namespace to classNames.
 *
 * Example:
 * ```
 * projectNamespace = "prefix"
 * ns("class") -> "prefix-class"
 * ns("A", "B") -> "prefix-A prefix-B"
 * ns("A B") -> "prefix-A prefix-B"
 * ```
 *
 * @param  {...string} classNames List of classNames to prefix with namespace
 * @returns {string} joined className string
 */
const ns = (...classNames) =>
  classNames
    .map((classList) => classList.split(/\s/).filter(Boolean))
    .flat()
    .map((className) => `${PROJECT_NAMESPACE}-${className}`)
    .join(" ");

export default ns;
