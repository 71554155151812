import classNames from "classnames";
import React, { ReactElement } from "react";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import { trackActionType, trackAction } from "../../utils/eTracker";

interface Props {
  label: string;
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

export default function DownloadLink({ label, href, onClick: handleClick, className }: Props): ReactElement {
  return (
    <a
      target="blank"
      href={href}
      className={classNames(ns("btn btn--primary download-dropdown__download-link"), className)}
      onClick={(event) => {
        if (handleClick) {
          handleClick(event);
        }
        trackAction(
          trackActionType.DOWNLOAD,
          href
            .substring(href.indexOf(".") + 1)
            .replace(/\?.*/, "")
            .toUpperCase(),
          href.substring(href.lastIndexOf("/") + 1, href.indexOf("."))
        );
      }}
    >
      <Icon width={24} height={24}>
        <use xlinkHref={useGetSvgPath("#download")}></use>
      </Icon>
      {label}
    </a>
  );
}
