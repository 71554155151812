import React from "react";
import ns from "./utils/namespace";
import VisualizationArea from "./components/organisms/areas/VisualizationArea";
import MiniStatisticsAppHeader from "./components/organisms/MiniStatisticsAppHeader";

export default function MiniStatisticsApp() {
  return (
    <div className={ns("mini-statistics-app")}>
      <MiniStatisticsAppHeader />
      <VisualizationArea />
    </div>
  );
}
