import last from "lodash/last";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveRenderer,
  selectActiveSelection,
  selectChosenIndicatorsOrTopics,
  selectStatisticType
} from "../../../../redux/indicatorsOrTopicsSlice";
import { selectChosenRegionsOrRegionFilters } from "../../../../redux/regionsOrRegionFiltersSlice";
import { selectAreYearsPlaying, setAreYearsPlaying } from "../../../../redux/yearsSlice";
import { usePrevious } from "../../../../utils/usePrevious";

/**
 * Returns memoized object containing all values affecting years playback (changes of these values and thereby returned object shall stop playback).
 */
const useValuesAffectingYearsPlayback = () => {
  const chosenRegionsOrRegionFilters = useSelector(selectChosenRegionsOrRegionFilters);
  const chosenIndicatorsOrTopics = useSelector(selectChosenIndicatorsOrTopics);
  const activeRenderer = useSelector(selectActiveRenderer);
  const statisticType = useSelector(selectStatisticType);
  const activeSelection = useSelector(selectActiveSelection);

  return useMemo(() => {
    return {
      chosenRegionsOrRegionFilters,
      chosenIndicatorsOrTopics,
      activeRenderer,
      statisticType,
      activeSelection
    };
  }, [activeRenderer, activeSelection, chosenIndicatorsOrTopics, chosenRegionsOrRegionFilters, statisticType]);
};

export const useYearsPlayback = (
  availableYears: number[],
  currentYear: number | null,
  setCurrentYear: (year: number) => void,
  isDataLoading: boolean,
  yearAnimationSpeed: number = availableYears.length >= 5 ? 500 : 1500,
  yearAnimationSpeedAtEnd: number = 2000
) => {
  const dispatch = useDispatch();

  const areYearsPlaying = useSelector(selectAreYearsPlaying);
  const wereYearsPlaying = usePrevious(areYearsPlaying);

  const valuesAffectingYearsPlayback = useValuesAffectingYearsPlayback();

  const yearsPlaybackTimerRef = useRef<number | null>(null);
  const timeout =
    currentYear === last(availableYears) && wereYearsPlaying ? yearAnimationSpeedAtEnd : yearAnimationSpeed;

  const handlePlayPauseClick = () => {
    dispatch(setAreYearsPlaying(!areYearsPlaying));
  };

  // When playback starts, call setCurrentYear with currentYear such that it is guaranteed that current year is displayed by its own
  // This is needed when also other years are currently displayed when playback starts (necessary for ChartWithYearSlider)
  useEffect(() => {
    if (areYearsPlaying && currentYear) {
      setCurrentYear(currentYear);
    }
  }, [areYearsPlaying, currentYear, setCurrentYear]);

  useEffect(() => {
    if (yearsPlaybackTimerRef.current) {
      window.clearTimeout(yearsPlaybackTimerRef.current);
      yearsPlaybackTimerRef.current = null;
    }
    if (!isDataLoading && areYearsPlaying && currentYear) {
      yearsPlaybackTimerRef.current = window.setTimeout(() => {
        const currentYearIndex = availableYears.indexOf(currentYear);
        const nextYearIndex = (currentYearIndex + 1) % availableYears.length;
        const nextYear = availableYears[nextYearIndex];
        setCurrentYear(nextYear);
      }, timeout);
    }

    // clear interval on unmount
    return () => (yearsPlaybackTimerRef.current ? window.clearTimeout(yearsPlaybackTimerRef.current) : undefined);
    // Changes of timeout shall not trigger effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areYearsPlaying, availableYears, currentYear, isDataLoading, setCurrentYear]);

  // Whenever any outer setting is changed by the user, then stop the play animation
  useEffect(() => {
    if (yearsPlaybackTimerRef.current) {
      window.clearTimeout(yearsPlaybackTimerRef.current);
      yearsPlaybackTimerRef.current = null;
    }
    dispatch(setAreYearsPlaying(false));
  }, [dispatch, valuesAffectingYearsPlayback]);

  return {
    areYearsPlaying,
    wereYearsPlaying,
    handlePlayPauseClick
  };
};
