import isFinite from "lodash/isFinite";
import isNumber from "lodash/isNumber";
import { IDataFeature, IIndicator } from "../../../../ts/interfaces";
import { getFormattedValue } from "../../../../utils/helpers";

/**
 * Get a tooltip string from a numeric value
 */
const formatValueUnit = (indicator: IIndicator, value: number | null): string => {
  let formatedValue;
  if (!(isNumber(value) && isFinite(value))) {
    formatedValue = "k.A.";
  } else {
    formatedValue = getFormattedValue(indicator, value);
    formatedValue = `${formatedValue} ${indicator.unit}`;
  }
  return formatedValue;
};

export const getTooltipContent = (
  feature: IDataFeature,
  valueIndex?: number,
  isDemographyType?: boolean,
  data?: {
    indicator: IIndicator;
    year: number;
  },
  baseClassName?: string
) => {
  const value = feature.properties.values && valueIndex !== undefined ? feature.properties.values[valueIndex] : null;

  if (value !== undefined && isDemographyType) {
    const type = value;

    return (
      <>
        <br />
        <strong className={baseClassName && `${baseClassName}__type`}>Typ {type}</strong>
      </>
    );
  } else if (data) {
    const formattedValueUnit = formatValueUnit(data.indicator, value);

    return (
      <>
        <br />
        {data.year}
        <br />
        {data.indicator.name}
        <br />
        <strong className={baseClassName && `${baseClassName}__value`}>{formattedValueUnit}</strong>
      </>
    );
  }
  return null;
};
