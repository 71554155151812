import classNames from "classnames";
import React, { ReactElement } from "react";
import { IClassItem } from "../../../ts/interfaces";
import ns from "../../../utils/namespace";
import Legend from "./Legend";
import StaticLegendItem, { IStaticLegendItemProps } from "./StaticLegendItem";

interface IStaticLegendItem extends Omit<IClassItem, "classIndex"> {
  color: string;
  classIndex: number | null;
}

type StaticLegendItemInteractionHandler = ((item: IStaticLegendItemProps) => void) | undefined;

interface Props {
  items: Array<IStaticLegendItem>;
  highlightedClassIndices: Array<number | null> | undefined;
  noClassLabel?: string;
  noClassColor?: string;
  className?: string;
  onItemHoverIn?: (item: IStaticLegendItem) => void;
  onItemHoverOut?: (item: IStaticLegendItem) => void;
  onItemClick?: (item: IStaticLegendItem) => void;
}

export default function StaticLegend({
  items,
  highlightedClassIndices,
  noClassLabel,
  noClassColor,
  className,
  onItemHoverIn,
  onItemHoverOut,
  onItemClick
}: Props): ReactElement {
  let legendItems: Array<IStaticLegendItemProps | IStaticLegendItem> = items;
  if (noClassLabel) {
    legendItems = legendItems.concat([
      {
        label: noClassLabel,
        color: noClassColor,
        classIndex: null
      }
    ]);
  }
  if (highlightedClassIndices) {
    legendItems = legendItems.map((item) => {
      return {
        ...item,
        inactive: !highlightedClassIndices.includes((item as IStaticLegendItem).classIndex)
      };
    });
  }

  return (
    <Legend
      className={classNames(ns("static-legend"), className)}
      items={legendItems}
      itemComponent={StaticLegendItem}
      onItemHoverIn={onItemHoverIn as StaticLegendItemInteractionHandler}
      onItemHoverOut={onItemHoverOut as StaticLegendItemInteractionHandler}
      onItemClick={onItemClick as StaticLegendItemInteractionHandler}
    />
  );
}
