import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import React, { ReactElement, useRef } from "react";
import ns from "../../../utils/namespace";
import { useGetSvgPath } from "../../../utils/svgPath";
import Icon from "../../atoms/Icon";
import { ILegendItemBase } from "./Legend";

export interface IInteractiveLegendItemProps extends ILegendItemBase {
  selected: boolean;
  className?: string;
  color?: string;
  onToggle: (isNowActive: boolean) => void;
}

const COMPONENT_NAME = "interactive-legend-item";

export default function InteractiveLegendItem({
  label,
  selected,
  className,
  color,
  onToggle: handleToggle
}: IInteractiveLegendItemProps): ReactElement {
  const itemId = useRef(`${ns(COMPONENT_NAME)}-${uniqueId()}`);

  const handleCheckboxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleToggle(event.target.checked);
  };

  return (
    <span
      className={classNames(
        ns(COMPONENT_NAME),
        {
          [ns(`${COMPONENT_NAME}--active`)]: selected
        },
        className
      )}
    >
      <input
        type="checkbox"
        checked={selected}
        id={itemId.current}
        className={ns(`${COMPONENT_NAME}__checkbox`)}
        onChange={handleCheckboxInputChange}
      />
      <label className={ns(`${COMPONENT_NAME}__label`)} htmlFor={itemId.current}>
        <Icon
          width={18}
          height={11}
          className={classNames(ns(`${COMPONENT_NAME}__icon`), {
            [`d-none`]: !selected
          })}
        >
          <use xlinkHref={useGetSvgPath("#checkmark")}></use>
        </Icon>
        <Icon
          top={4}
          width={18}
          height={11}
          className={classNames(ns(`${COMPONENT_NAME}__icon`), {
            [`d-none`]: selected
          })}
        >
          <use xlinkHref={useGetSvgPath("#minus")}></use>
        </Icon>
        {color && (
          <span
            className={ns(`${COMPONENT_NAME}__marker`)}
            // we use CSS-in-JS here because colors need to be defined
            // in JS anyway for rendering of the StatisticsMap
            style={{ backgroundColor: color }}
          />
        )}
        <span className={ns(`${COMPONENT_NAME}__label-text`)}>{label}</span>
      </label>
    </span>
  );
}
