import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { selectFiltersState, resetAllFilters } from "../redux/filtersSlice";
import { replaceRegionFilter, chooseRegionOrRegionFilter } from "../redux/regionsOrRegionFiltersSlice";

/**
 * Unified handler for adding region filters
 * Can be used from different places to add a chosen temporary filter
 *
 * @returns the add regionfilter callback
 */
export const useGetHandleAddRegionFilter = (): [boolean, () => void] => {
  const dispatch = useDispatch();
  const { loading, regionFilter, regionFilterInEditing } = useSelector(selectFiltersState);

  const isAddRegionFilterDisabled =
    loading ||
    regionFilter === null ||
    isEmpty(regionFilter.regions) ||
    regionFilter.friendlyUrl === regionFilterInEditing?.friendlyUrl;

  const handleAddRegionFilter = useCallback(() => {
    if (!isAddRegionFilterDisabled) {
      if (regionFilterInEditing) {
        dispatch(replaceRegionFilter(regionFilterInEditing, regionFilter));
      } else {
        dispatch(chooseRegionOrRegionFilter(regionFilter));
      }
      dispatch(resetAllFilters());
    }
  }, [isAddRegionFilterDisabled, dispatch, regionFilter, regionFilterInEditing]);

  return [isAddRegionFilterDisabled, handleAddRegionFilter];
};
