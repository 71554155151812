import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { IClassItem } from "../../../ts/interfaces";
import Legend from "./Legend";
import InteractiveLegendItem, { IInteractiveLegendItemProps } from "./InteractiveLegendItem";
import ns from "../../../utils/namespace";
import classNames from "classnames";
import { handleAllClassesToggle, handleClassToggle } from "../../../redux/mapDataSlice";

interface IInteractiveLegendItem extends IClassItem {
  color: string;
}

interface Props {
  items: Array<IInteractiveLegendItem>;
  toggleAllLabel: string;
  className?: string;
}

export default function InteractiveLegend({ items, toggleAllLabel, className }: Props): ReactElement {
  const dispatch = useDispatch();

  const legendItems: Array<IInteractiveLegendItemProps> = items.map((item) => {
    return {
      ...item,
      onToggle: () => dispatch(handleClassToggle(item.classIndex))
    };
  });

  legendItems.unshift({
    label: toggleAllLabel,
    onToggle: () => dispatch(handleAllClassesToggle()),
    selected: items.every((item) => item.selected)
  });

  return (
    <Legend
      className={classNames(ns("interactive-legend"), className)}
      items={legendItems}
      itemComponent={InteractiveLegendItem}
    />
  );
}
