import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHasTopXorLowRegionsCount,
  selectTopRegionsCount,
  setTopLowRegionsCount
} from "../../redux/regionsOrRegionFiltersSlice";
import ns from "../../utils/namespace";
import RadioGroup from "./RadioGroup";

type RegionsComparisonType = "top" | "low";

const topLowOptions = [
  {
    value: "top" as RegionsComparisonType,
    label: "Gewinne",
    title: "Zeigt die Kommunen mit den höchsten Wanderungsgewinnen"
  },
  {
    value: "low" as RegionsComparisonType,
    label: "Verluste",
    title: "Zeigt die Kommunen mit den höchsten Wanderungsverlusten"
  }
];

interface Props {
  className?: string;
}

export default function TopLowRegionsRadioGroup({ className }: Props) {
  const dispatch = useDispatch();

  const topRegionsCount = useSelector(selectTopRegionsCount);
  const hasTopXorLowRegionsCount = useSelector(selectHasTopXorLowRegionsCount);

  useEffect(() => {
    if (!hasTopXorLowRegionsCount) {
      dispatch(setTopLowRegionsCount({ topRegionsCount: 10, lowRegionsCount: null }));
    }
  }, [dispatch, hasTopXorLowRegionsCount]);

  const regionsComparisonType =
    // either topRegionsCounts or lowRegionsCount needs to be defined (xor), not both defined or undefined
    hasTopXorLowRegionsCount ? (topRegionsCount ? "top" : "low") : undefined;

  const handleTopLowChange = (value: RegionsComparisonType) => {
    if (value === "top") {
      dispatch(setTopLowRegionsCount({ topRegionsCount: 10, lowRegionsCount: null }));
    } else {
      dispatch(setTopLowRegionsCount({ topRegionsCount: null, lowRegionsCount: 10 }));
    }
  };

  return (
    <div className={classNames(ns("top-low-regions-radio-group"), className)}>
      <div className={ns("top-low-regions-radio-group__container")}>
        <RadioGroup
          items={topLowOptions}
          value={regionsComparisonType}
          onChange={handleTopLowChange}
          itemClassName={ns("top-low-regions-radio-group__item")}
        />
      </div>
    </div>
  );
}
