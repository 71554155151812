import { MultiPolygon } from "geojson";
import first from "lodash/first";
import { Direction, Map, geoJSON } from "leaflet";

import { IFeature } from "../ts/interfaces";

/**
 * Calculates a optimal weight from visual perspective for
 * stroke of features dependent of given zoom level.
 * @param currentZoom zoom level the weight shall be dependent of
 * @returns weight dependent of given zoom level
 */
export const getWeight = (currentZoom: number) => {
  let weight = 1;
  // The more we zoom in, the higher the zoom level
  // For higher zoom levels we like a wider/stronger border line
  if (currentZoom === 8) {
    weight = 2;
  } else if (currentZoom > 8) {
    weight = 3;
  }
  return weight;
};

/**
 * Reduces a feature in a sense that it reduces the feature's
 * geometry if it's a MultiPolygon to the first contained polygon.
 * @param feature the feature whose geometry shall be reduced
 * @returns the feature with the reduced geometry
 */
export const reduceFeature = (feature: IFeature) => {
  let result = feature;
  if (feature.geometry.type === "MultiPolygon") {
    const reducedGeometry: MultiPolygon = {
      ...feature.geometry,
      coordinates: [first((feature.geometry as MultiPolygon).coordinates) || []]
    };
    result = {
      ...feature,
      geometry: reducedGeometry
    };
  }
  return result;
};

/**
 * Get the direction in which the tooltip should open
 * We can consider top | bottom | left | right
 * However, there is no such thing like topleft to open to the topleft if the tooltip is in the bottom right corner
 * If a feature is above the middle of the map, then open to the top
 * Otherwise to the bottom
 * @param the the leaflet map
 * @param feature our geojson feature with geometry and properties
 * @returns the direction in which the tooltip should open
 */
export const getTooltipDirection = (map: Map, feature: IFeature): Direction => {
  const reducedFeature = reduceFeature(feature);
  const pos = geoJSON(reducedFeature).getBounds().getCenter();
  const tooltipPoint = map.latLngToContainerPoint(pos);

  const bounds = map.getBounds();
  const topLeft = map.latLngToContainerPoint(bounds.getNorthWest());
  const bottomRight = map.latLngToContainerPoint(bounds.getSouthEast());

  const centerPoint = map.latLngToContainerPoint(map.getCenter());

  // At least half the size of the tooltip popup
  const horzPuffer = 80;
  const vertPuffer = 60;

  const leftMargin = topLeft.x + horzPuffer;
  const rightMargin = bottomRight.x - horzPuffer;
  const topMargin = topLeft.y + vertPuffer;
  const bottomMargin = bottomRight.y - vertPuffer;

  const isTooltipPointCloseToTopOrBottom = tooltipPoint.y < topMargin || tooltipPoint.y > bottomMargin;
  const isToolTipCloseToLeft = tooltipPoint.x < leftMargin;
  const isToolTipCloseToRight = tooltipPoint.x > rightMargin;

  // Consider edge case when tooltip is very close to left or right side
  // Problem here is that there is not "open to topleft" or similar - so
  // we cannot handle the corners of the map
  if (isToolTipCloseToLeft && !isTooltipPointCloseToTopOrBottom) {
    return "right";
  }
  if (isToolTipCloseToRight && !isTooltipPointCloseToTopOrBottom) {
    return "left";
  }

  // For the corners of the map we fall back to bottom or top direction
  // let vertDirection: Direction = "top";
  if (tooltipPoint.y < centerPoint.y) {
    return "bottom";
  }

  return "top";
};
