import { PathOptions } from "leaflet";
import isNumber from "lodash/isNumber";
import isFinite from "lodash/isFinite";
import { IClassItem, IDataFeature, IMapData } from "../../../../ts/interfaces";
import { getWeight } from "../../../../utils/map";

const getFillColor = (
  chosenClassIndexes: IClassItem[] | undefined,
  feature: IDataFeature,
  dataIndex: number,
  colorsArr: Array<string>,
  noClassColor: string,
  hoverColorsArr?: Array<string>,
  isHovered?: boolean
) => {
  let fillColor = noClassColor;

  if (feature.properties.classIndices) {
    const classIndex = feature.properties.classIndices[dataIndex];

    const filteredClassIndexes = chosenClassIndexes?.filter((item) => item.selected);

    const isInFilteredClassIndexes = filteredClassIndexes?.find((item) => item.classIndex === classIndex);

    if (filteredClassIndexes && isInFilteredClassIndexes && classIndex >= 1) {
      fillColor = hoverColorsArr && isHovered ? hoverColorsArr[classIndex - 1] : colorsArr[classIndex - 1];
    }
  }

  return fillColor;
};

const getFillOpacity = (
  feature: IDataFeature,
  dataIndex: number,
  highlightedClassIndices: Array<number | null> | undefined
) => {
  if (highlightedClassIndices && feature.properties.classIndices) {
    const classIndex = feature.properties.classIndices[dataIndex];
    return highlightedClassIndices.includes(classIndex) ? 1 : 0.3;
  }
  return 1;
};

export const getRegionStyle = (
  feature: IDataFeature | undefined,
  mapData: IMapData | null,
  dataIndex: number | undefined,
  colorsArr: Array<string>,
  noClassColor: string,
  chosenClassItems: IClassItem[] | undefined,
  highlightedClassIndices: Array<number | null> | undefined,
  currentZoom?: number,
  hoverColorsArr?: Array<string>,
  isHovered?: boolean
): PathOptions => {
  if (!feature || !mapData || dataIndex === undefined) {
    return {};
  }

  const weight = isNumber(currentZoom) && isFinite(currentZoom) ? getWeight(currentZoom) : 1;
  const fillColor = getFillColor(
    chosenClassItems,
    feature,
    dataIndex,
    colorsArr,
    noClassColor,
    hoverColorsArr,
    isHovered
  );
  const fillOpacity = getFillOpacity(feature, dataIndex, highlightedClassIndices);

  const style = {
    // $color-hekla
    color: "#f8f9fa",
    weight: weight,
    fillColor: fillColor,
    fillOpacity: fillOpacity
  };

  return style;
};
