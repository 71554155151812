/**
 * Get the DOM elements to use for the React App using an html class
 * @returns {Array<Element>} - Array of DOM Element nodes
 */
export const getAppRootElems = () => {
  const elems = document.getElementsByClassName("wk-statistics-app");
  return Array.from(elems);
};

/**
 * Get the DOM elements to use for the Minimal Statistics React App (on the start-page) using an html class
 * @returns {Array<Element>} - Array of DOM Element nodes
 */
export const getMiniAppRootElems = () => {
  const elems = document.getElementsByClassName("wk-minimal-statistics-app");
  return Array.from(elems);
};
